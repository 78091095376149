import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReportStyle from '../ReportStyle';
import AmigosLogo from '../../Images/new.PNG';
import getEthiopicDate from '../../Common/modules/GregorianToEthiopian';

import TextBoldUnderlined from '../Reusables/TextBoldUnderlined';
import { numberToWords } from '../../Common/modules/EnNumToWord';
import TextBold from '../Reusables/TextBold';

function AmCarGuarantee({ loan, car }) {
  const curdate = new Date();
  const etcurrdate = getEthiopicDate(
    curdate.getDate(),
    +' ' + curdate.getMonth() + 1,
    +' ' + curdate.getFullYear()
  );

  return (
    <PDFViewer style={{ width: '100%', height: 700 }}>
      <Document title='የመኪና መያዣ'>
        <Page style={ReportStyle.body} wrap>
          <View style={ReportStyle.container}>
            <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Text style={ReportStyle.name}> የመያዣ ውል ስምምነት </Text>
                <Text style={ReportStyle.name}> የመኪና መያዣ </Text>
              </View>
            </View>
            <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
          </View>

          {Object.keys(loan).length > 0 ? (
            <>
              <View style={{ paddingBottom: 5 }}></View>
              {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ START */}
              <View>
                <Text style={ReportStyle.heading_one}>የውሉ ዓላማ </Text>
              </View>
              <View>
                <View>
                  {car?.cargp === null ? (
                    <>
                      <Text style={ReportStyle.text_normal}>
                        ይህ የመያዣ ውል ሊደረግ የቻለው በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር
                        ኃ/የተ/ህ/ስ/ማህበር እና በተበዳሪ
                        {
                          <TextBoldUnderlined
                            text={loan.customer_amDisplayName}
                          />
                        }
                        መካከል በ
                        <TextBoldUnderlined text={etcurrdate} /> ቀን በተፈጸመ የብድር
                        ውል መነሻ ሲሆን ለብደሩ መመለስ ዋስትና ይሆን ዘንድ ንብረትነቱ የ
                        {
                          <TextBoldUnderlined
                            text={loan.customer_amDisplayName}
                          />
                        }
                        የሆነውን የሰሌዳ ቁጥሩ
                        {<TextBoldUnderlined text={car.carPlate} />} ፣ የሻንሲ ቁጥሩ
                        <TextBoldUnderlined text={car.chassisNumber} /> እና የሞተር
                        ቁጥሩ <TextBoldUnderlined text={car.engineNumber} />
                        የሆነውን ተሽከርካሪ በዋስትናነት ለማስያዝ ነው፡፡
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={ReportStyle.text_normal}>
                        ይህ የመያዣ ውል ሊደረግ የቻለው በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር
                        ኃ/የተ/ህ/ስ/ማህበር እና በተበዳሪ
                        {
                          <TextBoldUnderlined
                            text={loan.customer_amDisplayName}
                          />
                        }
                        መካከል በ
                        <TextBoldUnderlined text={etcurrdate} /> ቀን በተፈጸመ የብድር
                        ውል መነሻ ሲሆን ለብደሩ መመለስ ዋስትና ይሆን ዘንድ ንብረትነቱ የ
                        <TextBoldUnderlined text={car.cargp_fullname} /> የሆነውን
                        የሰሌዳ ቁጥሩ
                        {<TextBoldUnderlined text={car.carPlate} />} ፣ የሻንሲ ቁጥሩ
                        <TextBoldUnderlined text={car.chassisNumber} /> እና የሞተር
                        ቁጥሩ <TextBoldUnderlined text={car.engineNumber} /> የሆነውን
                        ተሽከርካሪ ለማስያዝ ነው፡፡
                      </Text>
                    </>
                  )}
                </View>
              </View>
              {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ END */}

              <View>
                <Text style={ReportStyle.heading_one}> የዉሉ ዝርዝር </Text>
              </View>

              <View>
                <Text style={ReportStyle.text_normal}>
                  1. እኔ አቶ/ወ/ሮ/ሪት{' '}
                  <TextBoldUnderlined text={loan?.customer_amDisplayName} />.
                  ንብረትነቱ{' '}
                  <TextBoldUnderlined
                    text={`${car.cargp === null ? 'የእኔ' : car.cargp_fullname}`}
                  />
                  የሆነ ተሽከርካሪ የሰሌዳ ቁጥሩ{' '}
                  {<TextBoldUnderlined text={car.carPlate} />} የሆነ
                  {<TextBoldUnderlined text={car?.model?.enName} />} በመባል የሚጠራ
                  ተሸከርካሪ በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር
                  <TextBoldUnderlined text={etcurrdate} /> ቀን በተፈጸመ የብድር ውል መሰረት
                  ተበዳሪ የተበደሩት ገንዘብ ለመመለስ ዋስትና ይሆን ዘንድ በመያዣነት እንዲያዝ መፍቀዴን በተለመደው
                  ፊርማዬ አረጋግጣለሁ፡፡
                </Text>

                <Text style={ReportStyle.text_normal}>
                  2. እኔም በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር ከላይ በተራ ቁጥር 1
                  ስር ዝርዝር መግለጫው የተጠቀሰው ተሽከርካሪ ተበዳሪው ለተበደሩት ገንዘብ ዋስትና ይሆን ዘንድ
                  ንብረትነቱ የዋሱ የሆነውን ከላይ የተገለጸው ተሽከርካሪ በመያዣነት ለመያዝ ተስማምቻለሁ፡፡
                </Text>
                <Text style={ReportStyle.text_normal}>
                  3. ተጠቃሹን ተሸርካሪ በመያዣነት ከተያዘበት ቀን ጀምሮ ብድሩ ተከፍሎ እስከሚጠናቀቅበት ጊዜ ድረስ
                  እንዳይሸጥ ፣ እንዳይለወጥ በሌላ በማንኛውም ምክንያት ወደ 3ኛ ወገን እንዳይተላለፍ እና ምንም
                  ዓይነት ጉዳት ሳይደርስበት እየተንከባከብኩ የመጠበቅ ኃላፊነቱን እኔ ዋሱ የወሰድኩ መሆኑን
                  በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                </Text>

                <Text style={ReportStyle.text_normal}>
                  4. ተበዳሪው ምን አልባት የተበደርኩትን ገንዘብ በወቅቱና በጊዜው መክፈል ቢያታቸው ፣ በየወሩ
                  መክፈል የሚጠበቅብኝን የብድር ገንዘብ ብር
                  <TextBoldUnderlined
                    text={loan?.monthly_pay?.toLocaleString()}
                  />
                  <TextBold text={` (${numberToWords(loan?.monthly_pay)}) `} />
                  ለተከታታይ 2/ሁለት/ወራት ሳይከፍሉ ቢቀሩ በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር
                  ኃ/የተ/ህ/ስ/ማህበር ተሸከርካሪውን ከሚመለከታቸው የህግ አስከባሪ አካላት/ትራፊክ ፖሊሶች ጋር
                  በመሆን ከተገኘበት ቦታ በመያዝና እንዲቆም በማድግ ለሚፈልገው ወገን በመሸጥ የብድሩን ገንዘብ ወስዶ
                  ቀሪ ተመላሽ ካለ እንዲመልስልኝ የተስማማሁ መሆኔና በአጠቃላይ የብድር ገንዘቡን ለመክፈል ከተበዳሪ
                  ጋር ባልተነጣጠለ ኃላፊነት በአንድነትና በነጠላ ሙሉ ኃላፊነት የወሰድኩ መሆኑን በተለመደው ፊርማዬ
                  አረጋግጣለሁ፡፡
                </Text>



                <Text style={ReportStyle.text_normal}>
                  5. ከላይ በተራ ቁጥር 4 ስር የተገለጸው እንደተጠበቀ ሆኖ ተሸከርካሪው የተሸጠበት ዋጋ ከብድር
                  ገንዘቡ የሚያንስ ቢሆን አበዳሪው ቀሪውን ገንዘብ ለማስመለስ በተበዳሪው ላይ ክስ የመመስረትም ሆነ
                  የተለያዩ አማራጮችን በመጠቀም ገንዘቡን የማስመለስ መብቱ በሕግ የተጠበቀ ነው፡፡
                </Text>
                <Text style={ReportStyle.text_normal}>
                  6. በዚህ የመያዣ ንብረት ላይ ያገባኛል ፣ ይመለከተኛል የሚል ማንኛውም 3ተኛ ወገን የሌለ መሆኑን
                  እያረጋገጥን ወደፊት የሚመጣ ቢሆን እንኳ እኛ ዋስና ተበዳሪ በራሳችን ወጪ ተከራክረን ለመመለስ
                  ግዴታ ገብተናል፡፡
                </Text>

                <Text style={ReportStyle.text_normal}>
                  7. ተበዳሪው በመያዣነት ያቀረበው ተሽከርካሪ አበዳሪው በሚመርጠው ኢንሹራንስ ድርጅት የመድን
                  ሽፋን/ኢንሹራንስ/ መግዛትና የመድህን ሽፋኑ ጊዜ ከማብቃቱ በፊት ማደስ ይኖርበታል፡፡ የመድን ሽፋን
                  ጊዜ ካበቃ በኋላ ተበዳሪው የመድን ሽፋኑን ማደስ ካልቻለ አበዳሪ ከተበዳሪው ቁጠባ ላይ ተቀኛሽ
                  አድርጎ የመድን ዋስትናው እንዲታደስ ሊያደርግ ይችላል፡፡ በተጨማሪም ተበዳሪው በመያዣ በተያዘው
                  ተሽከርካሪ ላይ የአበዳሪ መለያ/ሎጎ/ የመለጠፍ ግዴታ አለበት፡፡
                </Text>

                <Text style={ReportStyle.text_normal}>
                  8. አበዳሪ ተበዳሪው ከላይ በተራ ቁጥር 4 ስር በተገለጸው አግባብ የተበደሩትን ገንዘብ በወቅቱ
                  መክፈል አቅቷቸው ለተከታታይ 2(ሁለት) ወራት ቢያቋርጡ ተሽከርካሪውን አበዳሪው ሽጦ ገንዘቡን
                  ለመውሰድና የቀረ ተመላሽ ገንዘብ የሚኖር ቢሆን ለተበዳሪው ለመመለስ ስምምነት አድርገናል፡፡
                </Text>

                <Text style={ReportStyle.text_normal}>
                  9. ይህ የመያዣ ውል በቀን <TextBoldUnderlined text={etcurrdate} />{' '}
                  በግራና ቀኛችን አካል የተፈጸመ የብድር ውል ስምምነት አካል እንዲሆን ስምምነት አድርገናል፡፡
                </Text>
                <Text style={ReportStyle.text_normal}>
                  10. እኔም የተበዳሪው ባል/ሚስት በብድር ውሉና በዚህ የመያዣ ውል የተስማማሁና ኃላፊነቱን
                  ለመውሰድ የፈቀድኩ መሆኑን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                </Text>
                <Text style={ReportStyle.text_normal}>
                  ይህ ውል በፍ/ብ/ሥ/ሥ/ሕ/ቁ 1723/2005 መሰረት በሕግ ፊት የጸና ነው
                </Text>
              </View>

              <View
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View>
                  <Text style={ReportStyle.text_normal}> የአበዳሪ ስምና ፊርማ </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                </View>
                <View>
                  <Text style={ReportStyle.text_normal}> የዋስ ስምና ፊርማ </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                </View>
                <View>
                  <Text style={ReportStyle.text_normal}> የተበዳሪ ስምና ፊርማ </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View>
                  <Text style={ReportStyle.text_normal}>
                    የዋስ/ሚስት/ባል ስምና ፊርማ
                  </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                </View>
                <View>
                  <Text style={ReportStyle.text_normal}> የምስክሮች ስምና ፊርማ </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                </View>
                <View>
                  <Text style={ReportStyle.text_normal}>
                    የተበዳሪ ባል/ሚስት ስምና ፊርማ
                  </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                </View>
              </View>
            </>
          ) : (
            <></>
          )}
          <View
            style={{
              textAlign: 'right',
              position: 'absolute',
              bottom: 30,
              right: 35,
              fontSize: 10,
            }}
          >
            <Text style={ReportStyle.text_normal}>
              ውሉ ያዘጋጀው፦
              <TextBoldUnderlined text='______________________________' />
            </Text>
            <Text style={ReportStyle.text_normal}>ፊርማ: ______________ </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
AmCarGuarantee.propTypes = {
  loan: PropTypes.object.isRequired,
  car: PropTypes.object.isRequired,
};

export default AmCarGuarantee;
