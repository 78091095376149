import React from 'react';
import PropTypes from 'prop-types';
import { Document, Font, Page, Text, View, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReportStyle from '../ReportStyle';
import AmigosLogo from '../../Images/new.PNG';
import TblAmCover from '../Reusables/TblAmCover';

import Tr from '../Reusables/Tr';
import TextBold from '../Reusables/TextBold';

import ebrimanormal from '../../font/ebrima.ttf';

import {
  numberToWordOnly,
  numberToWords,
} from '../../Common/modules/EnNumToWord';

Font.register({
  family: 'ebrimanormal',
  src: ebrimanormal,
});

function InFreeAmCover({ customer, loan, committee }) {
  const curdate = new Date();
  return (
    <PDFViewer style={{ width: '100%', height: 700 }}>
      <Document title='ብድር መጠየቅያ ፎርም'>
        <Page style={ReportStyle.body} wrap>
          <View style={ReportStyle.container}>
            <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Text style={ReportStyle.name}>
                  {' '}
                  አሚጎስ የገንዘብ ቁጠባና ብድር ሃ/የ/ህ/ስ/ማ{' '}
                </Text>
                <Text style={ReportStyle.name}> የብድር መጠየቅያ ቅጽ </Text>
              </View>
            </View>
            <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
          </View>
          {/* <View style={{ marginTop: 5}}></View> */}

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignContent: 'flex-end',
              marginTop: 10,
            }}
          >
            <Text> Date : {curdate.toDateString()} </Text>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginTop: 10,
            }}
          >
            <View style={{ width: '60%' }}>
              <View style={{ borderBottomWidth: 1 }} />
              <TblAmCover
                zkey='የአመልካች ስም ከነአያት'
                zvalue={customer.amDisplayName}
              />
              <TblAmCover zkey='ክ/ከተማ ' zvalue={customer.zoneId} />
              <TblAmCover zkey='ወረዳ ' zvalue={customer.woredaId} />
              <TblAmCover zkey='የቤት ቁጥር ' zvalue={customer.houseNum} />
              <TblAmCover zkey='ስልክ ቁጥር' zvalue={customer.mobileNo} />

              <TblAmCover zkey='የመታወቅያ ቁጥር' zvalue={customer.idNum} />
              <TblAmCover
                zkey='የጋብቻ ሁኔታ'
                zvalue={customer.isMarried ? 'ያገባ ' : 'ያላገባ '}
              />
              <TblAmCover
                zkey='የማህበሩ አባል የሆኑበት ጊዜ'
                zvalue={customer.memberSince}
              />
            </View>
            <View style={{ width: '40%', padding: 3, rowGap: 8 }}>
              <Text style={ReportStyle.normalText}>
                የተበዳሪ መለያ ቁጥር:{' '}
                {customer.entityAccountNo + ' / ' + customer.entityExternalId}
              </Text>
              <Text style={ReportStyle.normalText}>
                የብድር አይነት: {loan.loanType_txt}
              </Text>
            </View>
          </View>
          <View style={{ marginTop: 10 }}>
            <Text style={ReportStyle.normalText}>
              እኔ ስሜ አመልካች ተብሎ የተጠቀሰው ለመበደር ጠይቄ ከድርጅቱ ከተፈቀደልኝ ብድር ማለትም
              {(
                loan.approvedPrincipal + loan.toLoanSavingAddedAmount
              )?.toLocaleString()}
              <TextBold
                text={` (${numberToWords(loan.approvedPrincipal + loan.toLoanSavingAddedAmount)}) `}
              />{' '}
              ሲሆን በድርጅቱ ካለኝ የብድር ቁጠባ ማለትም
              {loan.Savings_for_loan_deduction?.toLocaleString()}
              <TextBold
                text={` (${numberToWords(loan.Savings_for_loan_deduction)}) `}
              />
              ወጪ ተደርጎ እንዲሰጠኝ ፣ በቀሪው ብር ማለትም
              {loan?.new_principal?.toLocaleString()}
              <TextBold text={` (${numberToWords(loan?.new_principal)}) `} />
              ብድር ሆኖ በ {loan?.numberOfRepayments}
              <TextBold
                text={` (${numberToWordOnly(loan?.numberOfRepayments)}) `}
              />
              ወራት የሚመለስ ተደርጎ እንዲሰራልኝ እየጠየቅኩኝ የአዲሱ ብድር ወርሃዊ ክፍያ{' '}
              {loan?.monthly_pay?.toLocaleString()}
              <TextBold text={` (${numberToWords(loan?.monthly_pay)}) `} />
              ለመክፈል የተስማማሁኝ መሆኔን በፊርማዮ አረጋግጣለው።
            </Text>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginTop: 10,
            }}
          >
            <View style={{ width: '60%' }}>
              <View style={{ borderBottomWidth: 1 }} />
              <TblAmCover
                zkey='ከተፈቀደው ብድር ካለኝ የብድር ቁጠባ ተቀንሶ ቀሪ ብድር'
                zvalue={loan?.new_principal?.toLocaleString()}
              />
              <TblAmCover
                zkey='ብድሩ ተከፍሎ የሚያልቅበት ወር'
                zvalue={loan?.numberOfRepayments}
              />

              <TblAmCover
                zkey='የብድር የአገልግሎት ክፍያ መጠን '
                zvalue={loan?.annualInterestRate}
              />
              <TblAmCover
                zkey='አጠቃላይ የብድር መጠን የብድር የአገልግሎት ክፍያ መጠንን ጨምሮ'
                zvalue={(
                  loan?.numberOfRepayments * loan?.monthly_pay
                )?.toLocaleString()}
              />
              <TblAmCover
                zkey='ወርሃዊ የብድር ክፍያ መጠን '
                zvalue={loan?.monthly_pay?.toLocaleString()}
              />

              <TblAmCover zkey='ወርሀዊ መደበኛ ቁጠባ' zvalue='1,000' />
              <TblAmCover
                zkey='ክፍያዎች ተቀንሰው በተበዳሪ እጅ የሚደርሰው ገንዘብ'
                zvalue={loan?.new_principal?.toLocaleString()}
              />
            </View>
            <View style={{ width: '40%', padding: 3, rowGap: 5 }}>
              <Text style={ReportStyle.normalText}>
                ተበዳሪው፤ የሚጠበቅባቸውን በአግባቡ ስላሙዋሉ ብድሩ እንዲፈቀድላቸው ስል በፊርማዬ አረጋግጣለሁ ፡፡
              </Text>
              <Text
                style={{
                  color: 'black',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                  fontSize: 10,
                  fontFamily: 'ebrimanormal',
                  marginTop: 3,
                }}
              >
                ስም: {loan.submitedBy_name + ' ' + loan.submitedBy_fathername}
              </Text>
              <Text style={ReportStyle.normalText}>
                ፊርማ: _______________________________
              </Text>
              <Text style={ReportStyle.normalText}>ውል ሰጪ ፡የብድር ክፍል ሃላፊ</Text>
              <Text style={ReportStyle.normalText}>
                ስም: _______________________________
              </Text>
              <Text style={ReportStyle.normalText}>
                ፊርማ: _______________________________
              </Text>
            </View>
          </View>

          <View
            style={{ display: 'flex', flexDirection: 'row', paddingTop: 5 }}
          >
            <View style={{ width: '50%', rowGap: 5 }}>
              <Text style={ReportStyle.normalText}>አመልካች / ተበዳሪ</Text>
              <Text
                style={{
                  color: 'black',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                  fontSize: 10,
                  fontFamily: 'ebrimanormal',
                  marginTop: 3,
                }}
              >
                ስም: {customer.amDisplayName}
              </Text>
              <Text style={ReportStyle.normalText}>
                ፊርማ: _______________________________
              </Text>
            </View>
            {customer.isMarried && (
              <View style={{ width: '50%', rowGap: 5 }}>
                <Text style={ReportStyle.normalText}>የተበዳሪ ባለቤት</Text>
                <Text
                  style={{
                    color: 'black',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    fontSize: 10,
                    fontFamily: 'ebrimanormal',
                    marginTop: 3,
                  }}
                >
                  ስም: _______________________________
                </Text>
                <Text style={ReportStyle.normalText}>
                  ፊርማ: _______________________________
                </Text>
              </View>
            )}
          </View>

          <View
            style={{
              display: 'flex',
              textAlign: 'center',
              padding: 2,
              backgroundColor: 'gray',
              marginTop: 10,
            }}
          >
            <Text style={ReportStyle.normalText}> የብድር ኮሚቴ ቃለ ጉባኤ እና ውሳኔ </Text>
          </View>

          {/* የብድር ኮሚቴዎች Start */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: 'gray',
            }}
          >
            <Tr
              style={ReportStyle.h5b}
              thekey='የብድር ኮሚቴ'
              width='50%'
              borders='t1r1b1l1'
            />
            <Tr
              style={ReportStyle.h5b}
              thekey='የስራ ድርሻ'
              width='30%'
              borders='t1r1b1l0'
            />
            <Tr
              style={ReportStyle.h5b}
              thekey='ፊርማ'
              width='20%'
              borders='t1r1b1l0'
            />
          </View>

          {committee?.length > 0 &&
            committee.map((cmt) => {
              return (
                <View
                  key={cmt.id}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <Tr thekey={cmt.amFullName} width='50%' borders='t0r1b1l1' />
                  <Tr
                    thekey={cmt.jobposition?.amName}
                    width='30%'
                    borders='t0r1b1l0'
                  />
                  <Tr thekey=' ' width='20%' borders='t0r1b1l0' />
                </View>
              );
            })}
          {/* የብድር ኮሚቴዎች End */}

          <View
            style={{
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'row',
              marginTop: 10,
            }}
          >
            <Text style={ReportStyle.normalText}>
              {' '}
              የውስጥ ኦዲተር (ስም):
              ____________________________________________________{' '}
            </Text>
            <Text style={ReportStyle.normalText}>
              {' '}
              ፊርማ: _____________________{' '}
            </Text>
          </View>

          <View
            style={{
              display: 'flex',
              textAlign: 'center',
              padding: 2,
              backgroundColor: 'gray',
              marginTop: 10,
            }}
          >
            <Text style={ReportStyle.normalText}> እማኞች </Text>
          </View>

          <View style={{ borderBottomWidth: 1 }} />
          <TblAmCover zkey=' ' zvalue=' ' />
          <TblAmCover zkey=' ' zvalue=' ' />
          <TblAmCover zkey=' ' zvalue=' ' />

          <View style={{ marginTop: 10 }}>
            <Text style={ReportStyle.normalText}>
              ተበዳሪ አስፈላጊ የብድር ሰነዶችን ማሟላት የሚጀምረው የወረፋ ጊዜ ከተጠናቀቀ በኋላ ይሆናል፡፡ ማንኛውም
              ተበዳሪ የንብረት የግዥ ውል የሚዋዋለው በአሚጎስ ከተገመተ በኋላ ይሆናል፡፡ የብድር አስፈላጊ ሰነዶች
              ካሟሉ በኋላ ለብድር ውል ዝግጅት /LOAN PROCESS/ ይደረጋል፡፡ ከብድር ውል የመጨረሻ ፊርማ በኋላ
              15 የስራ ቀናት ለብድር ኮሚቴ ፊርማ እንዲሁም ለቼክ ዝግጅት /LOAN DISBURSEMENT/ ተበዳሪው
              ካላቸው የቁጠባ ተቀማጭ ባልተከፈለ (ዋናው) ብድር በየአመቱ 2% የብድር ኢንሹራንስ ለመክፈል ይገደዳሉ፡፡
              ይህም የብድር መመለሻ አመቱ ሳይደርስ ብድር የሚመልስ ከሆነ የብድር ኢንሹራንስ የማይቆረጥበት ይሆናል፡፡
              አመታዊ ኢንሹራንስ ክፍያው 0.5% ከአባሉ አጠቃላይ ተቀማጭ ሂሳብ (አክሲዎን ፣ መደበኛ ቁጠባ ፣
              ፍቃደኝነት ቁጠባ) በአመት አንዴ አንድ አባል ብድር ጠይቆ የብድር ቀጠሮ ቀኑ ደርሶ የብድር ሂደት
              ሳይጀምር 6 ወር ካለፈው ብድሩ ይቋረጣል፡፡
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
InFreeAmCover.propTypes = {
  customer: PropTypes.shape({
    amDisplayName: PropTypes.string.isRequired,
    zoneId: PropTypes.string.isRequired,
    woredaId: PropTypes.string.isRequired,
    mobileNo: PropTypes.string.isRequired,
    idNum: PropTypes.string.isRequired,
    isMarried: PropTypes.bool.isRequired,
    memberSince: PropTypes.string.isRequired,
    entityAccountNo: PropTypes.string.isRequired,
    entityExternalId: PropTypes.string.isRequired,
    houseNum: PropTypes.string.isRequired,
    spausedetail: PropTypes.arrayOf(
      PropTypes.shape({
        amFullName: PropTypes.string,
      })
    ),
  }).isRequired,
  loan: PropTypes.shape({
    houseNum: PropTypes.string.isRequired,
    loanType: PropTypes.shape({
      amName: PropTypes.string.isRequired,
    }),
    approvedPrincipal: PropTypes.number.isRequired,
    toLoanSavingAddedAmount: PropTypes.number.isRequired,
    Savings_for_loan_deduction: PropTypes.number.isRequired,
    new_principal: PropTypes.number.isRequired,
    numberOfRepayments: PropTypes.number.isRequired,
    newTotalDueForPeriod: PropTypes.number.isRequired,
    annualInterestRate: PropTypes.number.isRequired,
    monthly_pay: PropTypes.number.isRequired,
    flat_interest_or_sch: PropTypes.number.isRequired,
    flat_interest_to_client: PropTypes.number.isRequired,
    customer_amDisplayName: PropTypes.string.isRequired,
    submitedBy_name: PropTypes.string,
    submitedBy_fathername: PropTypes.string,
    loanType_txt: PropTypes.string.isRequired,
  }).isRequired,
  committee: PropTypes.arrayOf(
    PropTypes.shape({
      amFullName: PropTypes.string.isRequired,
      jobposition: PropTypes.shape({
        amName: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
};

export default InFreeAmCover;
