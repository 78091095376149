import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uuid: '550e8400-e29b-41d4-a716-446655440000',
  name: 'Sample Recipe',
  description: 'This is a sample recipe execution flow with multiple steps.',
  notes: 'Test notes for the recipe.',
  tags: ['automation', 'workflow'],
  version: 1,
  created: '2025-02-16T12:00:00Z',
  updated: '2025-02-16T12:30:00Z',
  tested: '2025-02-16T13:00:00Z',
  published: null,
  deprecated: null,
  deleted: null,
  steps: [
    {
      uuid: '123e4567-e89b-12d3-a456-426614174000',
      workflowName: 'First Step',
      executionDelay: 5,
      parameters: [
        {
          type: 'UserProvidedConstantRecipeParameter',
          uuid: 'f47ac10b-58cc-4372-a567-0e02b2c3d479',
          destination: 'input_value',
          notes: 'User input for step 1',
          defaultValue: 100,
          modifiers: [
            {
              type: 'OffsetNumberValueModifier',
              uuid: 'a73c10b5-48dd-4372-a567-1e92b2c3d999',
              offset: 2.5,
            },
            {
              type: 'FormatNumberValueModifier',
              uuid: 'b73c10b5-48dd-4372-a567-1e92b2c3d888',
              format: '%.2f',
            },
          ],
        },
      ],
    },
    {
      uuid: '223e4567-e89b-12d3-a456-426614174001',
      workflowName: 'Second Step',
      executionDelay: 10,
      parameters: [
        {
          type: 'PreviousStepOutputRecipeParameter',
          uuid: 'f47ac10b-58cc-4372-a567-0e02b2c3d480',
          destination: 'processed_value',
          notes: 'Output from previous step',
          defaultValue: null,
          modifiers: [
            {
              type: 'OffsetNumberValueModifier',
              uuid: 'c73c10b5-48dd-4372-a567-1e92b2c3d777',
              offset: -10,
            },
          ],
        },
        {
          type: 'AutoGeneratedDateTimeRecipeParameter',
          uuid: 'c47ac10b-58cc-4372-a567-0e02b2c3d481',
          destination: 'timestamp',
          notes: 'Current timestamp',
          defaultValue: '2025-02-16T14:00:00Z',
          modifiers: [
            {
              type: 'FormatDatetimeValueModifier',
              uuid: 'd73c10b5-48dd-4372-a567-1e92b2c3d666',
              format: 'YYYY-MM-DD HH:mm:ss',
            },
          ],
        },
      ],
    },
    {
      uuid: '323e4567-e89b-12d3-a456-426614174002',
      workflowName: 'Third Step',
      executionDelay: 15,
      parameters: [
        {
          type: 'PreviousStepInputRecipeParameter',
          uuid: 'd47ac10b-58cc-4372-a567-0e02b2c3d482',
          destination: 'reused_value',
          notes: 'Reusing input from step 2',
          defaultValue: null,
          modifiers: [
            {
              type: 'OffsetNumberValueModifier',
              uuid: 'e73c10b5-48dd-4372-a567-1e92b2c3d555',
              offset: 5,
            },
          ],
        },
        {
          type: 'FormatDatetimeValueModifier',
          uuid: 'e47ac10b-58cc-4372-a567-0e02b2c3d483',
          destination: 'formatted_timestamp',
          notes: 'Formatted date',
          format: 'YYYY-MM-DD HH:mm:ss',
          modifiers: [
            {
              type: 'OffsetDatetimeValueModifier',
              uuid: 'f73c10b5-48dd-4372-a567-1e92b2c3d444',
              offset: 3600,
            },
          ],
        },
      ],
    },
    {
      uuid: '423e4567-e89b-12d3-a456-426614174003',
      workflowName: 'Fourth Step',
      executionDelay: 20,
      parameters: [
        {
          type: 'AutoGeneratedRandomNumberRecipeParameter',
          uuid: 'f47ac10b-58cc-4372-a567-0e02b2c3d484',
          destination: 'random_number',
          notes: 'Random number for this step',
          defaultValue: 42,
          modifiers: [
            {
              type: 'OffsetNumberValueModifier',
              uuid: 'g73c10b5-48dd-4372-a567-1e92b2c3d333',
              offset: 10,
            },
          ],
        },
        {
          type: 'OffsetDatetimeValueModifier',
          uuid: 'g47ac10b-58cc-4372-a567-0e02b2c3d485',
          destination: 'future_date',
          notes: 'Offsetting timestamp',
          offset: 86400,
          modifiers: [
            {
              type: 'FormatDatetimeValueModifier',
              uuid: 'h73c10b5-48dd-4372-a567-1e92b2c3d222',
              format: 'YYYY-MM-DD',
            },
          ],
        },
      ],
    },
  ],
};

const modifierSlice = createSlice({
  name: 'setting',
  initialState: initialState,
  reducers: {
    updateModifier: (state) => {
      return {
        ...state,
        serverIP: 'https://back.amigoslps.com',
      };
    },

    addModifier: (state) => {
      return {
        ...state,
        serverIP: 'https://back.amigoslps.com',
      };
    },
  },
});

export const { updateModifier, addModifier } = modifierSlice.actions;
export default modifierSlice.reducer;
