import React from 'react';
import ChatBox from '../Common/ChatBox';
import SalesTabOrganizer from './SalesTabOrganizer';

function SalesInactiveTab() {
  return (
    <div>
      <SalesTabOrganizer />
      <ChatBox />
    </div>
  );
}

export default SalesInactiveTab;
