import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import axiosInstance from '../axiosInstance';
import { useSelector } from 'react-redux';

function AlpaUserDetail() {
  const { userId } = useParams();
  const { serverIP } = useSelector((store) => store.allsettings);

  const [userData, setUserData] = useState({});

  useEffect(() => {
    getAllUserRelatedInformation();
  }, []);

  const getAllUserRelatedInformation = async () => {
    try {
      const user_res = await axiosInstance.get(
        `${serverIP}/lpsauth/users/${Number(userId)}`
      );
      setUserData(user_res.data);
    } catch (error) {
      console.log(error);
    } finally {
      console.log('finanlly');
    }
  };

  return (
    <>
      <div className='flex bg-gray-200 p-2 rounded-md'>
        {userData?.username}
      </div>
      <div className='flex flex-wrap'>
        <div className='w-full md:w-1/2 lg:w-1/3'>
          <Card className='mr-1 mt-1'>
            <Card.Header> Group Checked </Card.Header>
            <Card.Body></Card.Body>
          </Card>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/3'>
          <Card className='mr-1 mt-1'>
            <Card.Header> Payment Checking </Card.Header>
            <Card.Body></Card.Body>
          </Card>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/3'>
          <Card className='mr-1 mt-1'>
            <Card.Header> Member Connected To Core Bank </Card.Header>
            <Card.Body></Card.Body>
          </Card>
        </div>
        {/* <div className="w-full md:w-1/2 lg:w-1/3">
            <Card className='mr-1 mt-1'>
                <Card.Header> Group Checked </Card.Header>
                <Card.Body>

                </Card.Body>
            </Card>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/3">
            <Card className='mr-1 mt-1'>
                <Card.Header> Group Checked </Card.Header>
                <Card.Body>

                </Card.Body>
            </Card>
        </div> */}
      </div>
      <div className='pt-3'>
        <ProgressBar now={60} />
      </div>
    </>
  );
}

export default AlpaUserDetail;
