import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axiosInstance from '../axiosInstance';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function AlmedaUsers() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  //   const [emps, setEmps] = useState([]);

  useEffect(() => {
    getAllEmployees();
  }, []);

  const getAllEmployees = () => {
    axiosInstance
      .get(`${serverIP}/lpsauth/almeda`)
      .then((res) => {
        console.log(res.data);
        // setEmps(res.data);
        setFilteredEmployees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      name: 'የመጠቀምያ ስም',
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: 'ሙሉ ስም',
      selector: (row) => row.first_name + ' ' + row.last_name,
      sortable: true,
    },
    {
      name: 'ኢሜይል',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => (row.is_active ? 'Active' : 'Inactive'),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <Link
          className='font-medium text-blue-600 dark:text-blue-500 underline hover:underline'
          to={`/almeda/${row.id}`}
        >
          Detail
        </Link>
      ),
    },
  ];

  return (
    <div>
      <Alert>ALPA Members</Alert>
      <DataTable columns={columns} data={filteredEmployees} pagination>
        {' '}
      </DataTable>
    </div>
  );
}

export default AlmedaUsers;
