import * as Yup from 'yup';

const CarSchema = Yup.object().shape({
  manufacturedYear: Yup.number().required('የተመረተበት ዓ.ም ያስገቡ'),
  chassisNumber: Yup.string().required('ቻሲስ ቁጥር ያስገቡ'),
  engineNumber: Yup.string().required('የኢንጅን ቁጥር ያስገቡ'),
  librebookid: Yup.string().required('የሊብሬ ቁጥር ያስገቡ'),

  carPlate: Yup.string().required('የመኪና ታርጋ ያስገቡ'),
  carCC: Yup.number().default(0).required('የመኪና ሲሲ ያስገቡ'),
  loan: Yup.string().required('loan is required'),
  model: Yup.number().required('የመኪና ሞዴል ያስገቡ'),

  insuranceValue: Yup.string().required('የኢንሹራንስ ግምት ያስገቡ'),
  garageValue: Yup.string().required('የጋራጅ ግምት ያስገቡ'),
  serialnum: Yup.string().required('ሴርያል ቁጥር ያስገቡ'),
  vehiclePerformance: Yup.number().default(0).required('የተሽከርካሪ ብቃት ያስገቡ '),

  manufacturedCountry: Yup.number().required('የተመረተበት አገር ይምረጡ'),
  sanctionAddress: Yup.string().required('የእግድ አድራሻ ያስገቡ '),
  sanctionAddresstwo: Yup.string().required('የእግድ አድራሻ ያስገቡ '),
  sanctionAddressthree: Yup.string().required('የእግድ አድራሻ ያስገቡ '),
  carcollateraltype: Yup.number().required('የመኪና carcollateraltype ያስገቡ'),
});

export default CarSchema;
