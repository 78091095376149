import React, { useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, Table, Modal } from 'react-bootstrap';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TextFormikInput from '../../ReusableFormiks/TextFormikInput';
import LoantypesSchema from '../Schema/LoantypesSchema';
import axiosInstance from '../../axiosInstance';

function LoanType() {
  const [t] = useTranslation('global');
  const { serverIP } = useSelector((store) => store.allsettings);
  const [loanType, setLoanType] = useState([]);
  const [name, setName] = useState('');
  const [amName, setAmName] = useState('');

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [targetObj, setTargetObj] = useState({});

  useEffect(() => {
    getLoanTypes();
  }, []);

  const getLoanTypes = () => {
    axiosInstance
      .get(`${serverIP}/loantype/loantypes`)
      .then(function (response) {
        setLoanType(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveLoanType = (values) => {
    console.log(values);
    axiosInstance
      .post(`${serverIP}/loantype/loantypes`, {
        enName: values.enName,
        amName: values.amName,
      })
      .then((res) => {
        console.log(res.data);
        setModalAdd(false);
        getLoanTypes();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editLoanType = () => {
    console.log(`enName: ${name} and amName: ${amName}`);
    axiosInstance
      .patch(`${serverIP}/loantype/loantypes/${targetObj.id}`, {
        enName: name,
        amName,
      })
      .then((res) => {
        console.log(res.data);
        getLoanTypes();
        setModalEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteLoanType = () => {
    axiosInstance
      .delete(`${serverIP}/loantype/loantypes/${targetObj.id}`)
      .then((res) => {
        console.log(res.data);
        getLoanTypes();
        setModalDelete(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modals Start  */}
      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>የብድር አይነት</Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{
            enName: '',
            amName: '',
          }}
          validationSchema={LoantypesSchema}
          onSubmit={saveLoanType}
        >
          {() => (
            <>
              <Form>
                <Modal.Body>
                  <TextFormikInput name='amName' placeholder='የብድር አይነት ' />
                  <TextFormikInput name='enName' placeholder='LoanType Name' />
                </Modal.Body>
                <Modal.Footer>
                  <Button type='submit' variant='primary btn-sm'>
                    አስቀምጥ
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'orange' }}> አስተካክል </Modal.Title>
        </Modal.Header>

        <Modal.Body className='flex flex-col gap-2'>
          <input
            type='text'
            placeholder='የብድር አይነት.'
            className='form-control'
            onChange={(e) => setAmName(e.target.value)}
            value={amName}
          />
          <input
            type='text'
            placeholder='Loan Type'
            className='form-control'
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='warning' onClick={() => editLoanType()}>
            {t('officer.edit')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Delete start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'red' }}> አጥፋ </Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => deleteLoanType()}>
            {' '}
            አጥፋ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}

      {/* Modals End  */}

      <Alert variant='info'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 0,
            margin: 0,
          }}
        >
          <div> የብድር አይነቶች ዝርዝር</div>
          <div>
            <Button
              variant='primary'
              className='btn-sm'
              onClick={() => setModalAdd(true)}
            >
              {t('officer.add')}
            </Button>
          </div>
        </div>
      </Alert>
      {loanType.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th> ስም </th>
              <th> Name </th>
              <th>{t('auditor.action')}</th>
            </tr>
          </thead>
          <tbody>
            {loanType.map((chk) => {
              return (
                <tr key={chk.id}>
                  <td>{chk.id}</td>
                  <td>{chk.amName}</td>
                  <td>{chk.enName}</td>
                  <td>
                    <ButtonGroup size='sm'>
                      <FaTrashAlt
                        color='red'
                        onClick={() => {
                          setModalDelete(true);
                          setTargetObj(chk);
                        }}
                      />
                      &nbsp;
                      <FaPencilAlt
                        color='orange'
                        onClick={() => {
                          setModalEdit(true);
                          setTargetObj(chk);
                          setName(chk.enName);
                          setAmName(chk.amName);
                        }}
                      />
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default LoanType;
