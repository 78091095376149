export function calculateFinancialMetrics(
  data,
  monthlyLoanRepayment,
  totalCollateral
) {
  const categories = {
    costOfGoods: ['Raw materials'],
    operatingExpenses: [
      'Labor Related to wages',
      'Overhead related to expence',
      'Rent or lease payments for office space or facilities',
      'Utilities such as electricity, water, etc',
      'Salaries  for employees',
      'Employee benefits such as health insurance ……',
      'Marketing and advertising expenses',
      'Office supplies and equipment',
      'Professional services such as legal, accounting, if any',
      'Travel and entertainment expenses',
    ],
    interestExpenses: ['interest payments on loans'],
    taxExpenses: ['income taxes paid to govermental body'],
    personalExpenses: [
      'for personal house rent',
      'for food and beverage',
      'for schooling/education',
    ],
    incomes: [
      "From product/service sale's",
      'Commissions',
      'Professional Fees or salary (Own Fee)',
      'Professional Fees or salary (Spons Fee)',
      'Profit form Interest on Investments',
      'Rent from housing or commercial locale',
      'Grants and Funding',
      'Interest Income',
    ],
    assets: [
      'Cash and Cash Equivalents (in bank or related institution)',
      'Accounts Receivable',
      'Inventory',
      'Property, Plant, and Equipment:(fixed and mobile/ movable)',
    ],
    liabilities: ['Accounts Payable', 'Short-Term Loans', 'Long-Term Loans'],
    capitals: ['shares or other related bounds', 'retailed earnings'],
    maintenanceExpenses: ['Maintenance & Related Expenses'],
    financialExpenses: ['Credit Union Banks', 'Credit Cards'],
    otherExpenses: ['Other Expenses'],
    payments: [
      'Payments received',
      'Loan repayments',
      'Vendor payments',
      'Customer payments',
    ],
  };

  // capitals: [
  //   'shares or other related bounds',
  //   'retailed earnings',
  //   'Initial Investment',
  // ],

  const sumCategory = (category) =>
    data
      .filter((item) => category.includes(item.amDesc))
      .reduce((acc, item) => acc + item.markValue, 0);

  const Sum_Cost_of_goods = sumCategory(categories.costOfGoods);
  const Sum_Operating_Expenses = sumCategory(categories.operatingExpenses);
  const Sum_Interest_Expenses = sumCategory(categories.interestExpenses);
  const Sum_Tax_Expenses = sumCategory(categories.taxExpenses);
  const Sum_Personal_Expenses = sumCategory(categories.personalExpenses);
  const Sum_Incomes = sumCategory(categories.incomes);
  const Sum_Assets = sumCategory(categories.assets);
  const Sum_Liabilities = sumCategory(categories.liabilities);
  const Sum_Capitals = sumCategory(categories.capitals);
  const Sum_MaintenanceRelatedExpenses = sumCategory(
    categories.maintenanceExpenses
  );
  const Sum_FinancialExpenses = sumCategory(categories.financialExpenses);
  const Sum_OtherExpenses = sumCategory(categories.otherExpenses);
  const Sum_Payments = sumCategory(categories.payments);

  const Monthly_Basic_Expenses = Sum_Operating_Expenses + Sum_Personal_Expenses;
  const NetWorth = Sum_Assets - Sum_Liabilities;

  // Updated metrics with collateral and loan repayment
  const Debt_To_Asset_Ratio =
    Sum_Assets > 0 ? (Sum_Liabilities / Sum_Assets) * 100 : 0;

  const Loan_To_Value_Ratio =
    totalCollateral > 0 ? (Sum_Liabilities / totalCollateral) * 100 : 0;

  const Debt_To_Income_Ratio =
    Sum_Incomes > 0 ? (Sum_Liabilities / Sum_Incomes) * 100 : 0;

  const Net_Payments = Sum_Payments - Sum_Liabilities;

  const Net_Operating_Income = Sum_Incomes - Sum_Operating_Expenses;
  const Total_Debt_Service = monthlyLoanRepayment + Sum_Interest_Expenses;

  const Debt_To_Service_Coverage_Ratio =
    Total_Debt_Service > 0 ? Net_Operating_Income / Total_Debt_Service : 0;

  const Liquidity_Ratio =
    Sum_Liabilities > 0
      ? ((sumCategory([
          'Cash and Cash Equivalents (in bank or related institution)',
        ]) +
          sumCategory(['Accounts Receivable']) +
          sumCategory(['Inventory'])) /
          Sum_Liabilities) *
        100
      : 0;

  let Ability_To_Pay =
    ((Net_Payments +
      sumCategory([
        'Cash and Cash Equivalents (in bank or related institution)',
      ])) /
      (Sum_Liabilities + Monthly_Basic_Expenses + monthlyLoanRepayment)) *
    100;

  Ability_To_Pay =
    isFinite(Ability_To_Pay) && !isNaN(Ability_To_Pay)
      ? Math.max(0, Math.min(100, Ability_To_Pay))
      : 0;

  function calculateIRR(
    cashFlows,
    guess = 0.1,
    maxIterations = 100,
    tolerance = 1e-6
  ) {
    let rate = guess;
    for (let i = 0; i < maxIterations; i++) {
      let npv = 0,
        derivative = 0;
      for (let j = 0; j < cashFlows.length; j++) {
        npv += cashFlows[j] / Math.pow(1 + rate, j);
        derivative += (-j * cashFlows[j]) / Math.pow(1 + rate, j + 1);
      }
      const newRate = rate - npv / derivative;
      if (Math.abs(newRate - rate) < tolerance) return newRate;
      rate = newRate;
    }
    return rate;
  }

  const IRR = calculateIRR([-Sum_Capitals, Sum_Incomes, Sum_Payments]) * 100;
  const Return_On_Capital =
    Sum_Capitals > 0 ? (Sum_Incomes / Sum_Capitals) * 100 : 0;

  const Capital_Turnover_Ratio =
    Sum_Capitals > 0
      ? (Sum_Incomes / Sum_Capitals) * 100 > 100
        ? 10
        : ((Sum_Incomes / Sum_Capitals) * 100) / 10
      : 0;

  const Equity_Ratio =
    Sum_Assets + Sum_Liabilities > 0
      ? (Sum_Capitals / (Sum_Assets + Sum_Liabilities)) * 100
      : 0;

  return {
    Sum_Cost_of_goods,
    Sum_Operating_Expenses,
    Sum_Interest_Expenses,
    Sum_Tax_Expenses,
    Sum_Personal_Expenses,
    Sum_Incomes,
    Sum_Assets,
    Sum_Liabilities,
    Sum_Capitals,
    Monthly_Basic_Expenses,
    Sum_MaintenanceRelatedExpenses,
    Sum_FinancialExpenses,
    Sum_OtherExpenses,
    Sum_Payments,
    Net_Payments,
    NetWorth,
    Debt_To_Asset_Ratio,
    Loan_To_Value_Ratio,
    Debt_To_Income_Ratio,
    Liquidity_Ratio,
    IRR,
    Ability_To_Pay,
    Return_On_Capital,
    Capital_Turnover_Ratio,
    Equity_Ratio,
    Debt_To_Service_Coverage_Ratio,
  };
}
