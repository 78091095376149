import React from 'react';
import PropTypes from 'prop-types';

function EvaluationKeyValue({ thekey, value, isodd }) {
  return (
    <div
      className={`px-2 m-1 flex justify-between rounded-sm ${isodd ? 'bg-orange-100' : 'bg-gray-100'}`}
    >
      <div>{thekey}</div>
      <div>{value}</div>
    </div>
  );
}
EvaluationKeyValue.propTypes = {
  thekey: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isodd: PropTypes.bool.isRequired,
};

export default EvaluationKeyValue;
