import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInstance';
import { useSelector } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import DataTable from 'react-data-table-component';

function AddMember() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const [allMembers, setAllMembers] = useState([]);

  useEffect(() => {
    getAllMembers();
  }, []);

  const getAllMembers = () => {
    axiosInstance
      .get(`${serverIP}/memberregistration/memberregistration`)
      .then(function (response) {
        console.log(response.data);
        setAllMembers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveMember = () => {
    axiosInstance
      .post(`${serverIP}/memberregistration/memberregistration`, {
        amDisplayName: 'Solomon Gebremichael',
        createdBy: 1,
        deposit: 1000,
        displayName: 'Solomon Gebremichael',
        entityAccountNo: '123456',
        entityExternalId: '123456',

        gender: '123456',
        interestedLoanAmount: 1000000,
        loanType: 1,

        mobileNo: '0935811576',
        pgrade: 2,
        psavingtype: 2,
        psourceofinfo: 2,
      })
      .then((res) => {
        console.log(res.data);
        getAllMembers();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      name: 'Date',
      selector: (row) => row.createdAt,
      sortable: true,
    },
    {
      name: 'ሙሉ ስም',
      selector: (row) => row.amDisplayName,
      sortable: true,
    },
    {
      name: 'Phone Number',
      selector: (row) => row.mobileNo,
      sortable: true,
    },
    {
      name: 'Source',
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: 'Requested Amount',
      selector: (row) => row.interestedLoanAmount,
      sortable: true,
    },
    {
      name: 'Loan Type',
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: 'Saving Type',
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: 'Grade',
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <ButtonGroup size='sm'>
          <Button variant='danger'>
            <FaTrashAlt
              onClick={() => {
                console.log(row);
              }}
              //   onClick={() => {
              //     setModalDelete(true);
              //     setTargetObj(row);
              //     console.log(row);
              //   }}
            />
          </Button>
          <Button variant='warning'>
            <FaPencilAlt
            //   onClick={() => {
            //     setModalEdit(true);
            //     setdescription(row.description);
            //     setamDescription(row.amDescription);
            //     setTargetObj(row);
            //   }}
            />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <div>
      <div>
        <div className='flex gap-1'>
          <input
            type='text'
            placeholder='Input 1'
            className='border p-2 rounded-md'
          />
          <input
            type='text'
            placeholder='Input 2'
            className='border p-2 rounded-md'
          />
          <input
            type='text'
            placeholder='Input 3'
            className='border p-2 rounded-md'
          />
          <input
            type='text'
            placeholder='Input 4'
            className='border p-2 rounded-md'
          />
        </div>
        <button
          onClick={() => {
            saveMember();
          }}
        >
          Save
        </button>
      </div>
      <div className='mt-2 p-1 bg-slate-200'>
        <DataTable columns={columns} data={allMembers} pagination></DataTable>
      </div>
    </div>
  );
}

export default AddMember;
