import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from '@react-pdf/renderer';
import { Text, View, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

import GuaranteeStyles from '../ReportStyle';
import AmigosLogo from '../../Images/Head.PNG';

import { numberToWords } from '../../Common/modules/EnNumToWord';
import HomeDateAndAddress from '../Reusables/HomeDateAndAddress';
import TextBoldUnderlined from '../../Contracts/Reusables/TextBoldUnderlined';
import { singleHomePrice } from '../../Common/modules/NewFiveCSums';

function AmHomeProhibition({ loan, home }) {
  return (
    <PDFViewer style={{ width: '100%', height: 700 }}>
      <Document title='የቤት እግድ'>
        <Page style={GuaranteeStyles.prohibitionbody} wrap>
          <Image
            style={GuaranteeStyles.CarProhibition_ReporIimage}
            src={AmigosLogo}
          />
          <HomeDateAndAddress region={home.regionId} objec={home} />

          <View style={{ paddingTop: 10 }}>
            <Text style={GuaranteeStyles.subjectCenter}>
              ጉዳዩ ፡- እገዳ እንዲደረግልን ስለመጠየቅ ይመለከታል፡፡
            </Text>
          </View>

          <View style={{ paddingTop: 10 }}>
            <Text style={GuaranteeStyles.h1}>
              የድርጅታችን አባል የሆኑት አቶ/ወ/ሮ(ወ/ሪት)
              {/* {loan.customer?.gender === 'MALE' ? ' አቶ ' : ' ወ/ሮ(ወ/ሪት) '} */}
              <TextBoldUnderlined text={loan.customer_amDisplayName} />
              ከማህበራችን ብር
              <Text style={GuaranteeStyles.h1b}>
                {` ${(loan.monthly_pay * loan.numberOfRepayments)?.toLocaleString()} ( ${numberToWords(loan.monthly_pay * loan.numberOfRepayments)} ) `}
              </Text>
              በብድር የወሰዱ ሲሆን ለብድሩ መያዣነት ያቀረቡትን ማለትም፡፡
            </Text>
          </View>

          {/* Table Start */}

          <View
            style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
          >
            <View style={{ width: '10%' }}></View>
            <View style={{ width: '80%' }}>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  paddingTop: 15,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray',
                    }}
                  >
                    የዋስትናው አይነት
                  </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray',
                    }}
                  >
                    ቤት
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text style={GuaranteeStyles.border_rbl}> የዋስትና ሰጪ ስም </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={GuaranteeStyles.border_rb}>
                    {home.homegp ? home.homegp : loan.customer_amDisplayName}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray',
                    }}
                  >
                    የካርታ ቁጥር
                  </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray',
                    }}
                  >
                    {home.uniquenum}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text style={GuaranteeStyles.border_rbl}>የቤቱ ግምት </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={GuaranteeStyles.border_rb}>
                    {singleHomePrice(home)?.toLocaleString()}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray',
                    }}
                  >
                    ሴሪ ቁጥር
                  </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray',
                    }}
                  >
                    {home?.serialnum ? home?.serialnum : ' '}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ width: '10%' }}></View>
          </View>
          {/* Table End */}

          <View style={{ paddingTop: 10 }}>
            <Text style={GuaranteeStyles.h1}>
              እንዲታገድ ፣ እንዳይሸጥ እንዳይለወጥ እንዲሁመ ለሌላ ሶስተኛ ወገን እንዳይተላለፍ በአሚጎስ
              የገ/ቁ/በ/ህ/ስ/ማ ስም ተመዝገቦ እንዲቆይ እየጠየቅን ፣ የዋስትናው ደኩመንት በድርጅታችን ሰራተኛ በኩል
              እንድትልኩልን የዘወትር የስራ ትብብራችሁን እንጠይቃለን፡፡
            </Text>
          </View>

          {/* ከሰላምታ ጋር  */}
          <View style={{ paddingTop: 10 }}>
            <Text style={GuaranteeStyles.subjectRight}>ከሰላምታ ጋር </Text>
          </View>

          <View style={{ marginTop: 180, rowGap: 5 }}>
            <View
              style={{
                width: '100%',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>
                  አዘጋጅ ፡ ____________________________
                </Text>
              </View>
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>
                  ያረጋገጠው ፡ _________________________
                </Text>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>
                  የስራ ድርሻ ፡ _________________________
                </Text>
              </View>
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>
                  የስራ ድርሻ ፡ _________________________
                </Text>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>
                  ፊርማ ፡ _________________________
                </Text>
              </View>
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>
                  ፊርማ ፡ _________________________
                </Text>
              </View>
            </View>
            <View style={{ marginTop: 60 }}></View>

            <Image
              style={GuaranteeStyles.CarProhibition_ReporIimage}
              src={AmigosLogo}
            />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

AmHomeProhibition.propTypes = {
  loan: PropTypes.shape({
    customer: PropTypes.shape({
      gender: PropTypes.string,
      amDisplayName: PropTypes.string,
    }),
    monthly_pay: PropTypes.number,
    numberOfRepayments: PropTypes.number,
    customer_amDisplayName: PropTypes.string,
  }).isRequired,
  home: PropTypes.shape({
    regionId: PropTypes.string,
    homegp: PropTypes.string,
    uniquenum: PropTypes.string,
    serialnum: PropTypes.string,
  }).isRequired,
};

export default AmHomeProhibition;
