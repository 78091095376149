import { Font, StyleSheet } from '@react-pdf/renderer';
import ebrimanormal from '../font/ebrima.ttf';
import ebrimabold from '../font/ebrimabd.ttf';

Font.register({
  family: 'ebrimanormal',
  src: ebrimanormal,
});

Font.register({
  family: 'ebrimabold',
  src: ebrimabold,
});

const ReportStyle = StyleSheet.create({
  prohibitionbody: {
    flexDirection: 'column',
    padding: 20,
    paddingLeft: 40,
    paddingRight: 40,
    fontSize: 12,
  },

  CarProhibition_ReporIimage: {
    height: 45,
    width: '100%',
  },

  h1: {
    fontFamily: 'ebrimanormal',
    fontSize: 12,
  },
  h1b: {
    fontFamily: 'ebrimabold',
    fontSize: 12,
  },

  border_rbl: {
    fontFamily: 'AmharicFont',
    borderTopWidth: 0,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    paddingLeft: 10,
  },
  border_rb: {
    fontFamily: 'AmharicFont',
    borderTopWidth: 0,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 0,
    paddingLeft: 10,
  },
  subject: {
    fontSize: 13,
    textAlign: 'left',
    fontFamily: 'ebrimabold',
  },
  subjectCenter: {
    fontSize: 13,
    textAlign: 'center',
    fontFamily: 'ebrimabold',
  },
  subjectRight: {
    fontSize: 13,
    textAlign: 'right',
    fontFamily: 'ebrimabold',
  },

  textRight: {
    marginTop: 1,
    fontSize: 12,
    textAlign: 'right',
    fontFamily: 'AmharicFont',
    fontWeight: 'bold',
  },
});

export default ReportStyle;
