import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet } from '@react-pdf/renderer';

function TextBoldUnderlined(props) {
  const Styles = StyleSheet.create({
    dynamicText: {
      fontFamily: 'ebrimabold',
      color: 'black',
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  });

  return (
    <Text>
      <Text style={Styles.dynamicText}> {props.text} </Text>
    </Text>
  );
}
TextBoldUnderlined.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextBoldUnderlined;
