import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FaCarAlt, FaEye, FaFileAlt, FaRing } from 'react-icons/fa';
import {
  Accordion,
  Alert,
  Button,
  InputGroup,
  Modal,
  Table,
} from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';
import BailSchema from '../Schema/BailSchema';
import InputField from '../ReusableFormiks/InputField';
import { setTargetLoan } from '../Common/redux/systemLookups';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';

function Bail({ successnotify, errornotify }) {
  const { data } = useSelector((store) => store.customer);
  const { serverIP } = useSelector((store) => store.allsettings);
  const { targetLoan } = useSelector((store) => store.systemLookups);
  const dispatch = useDispatch();
  const { loanId } = useParams();

  const [regions, setRegions] = useState([]);
  const [zones, setZones] = useState([]);
  const [woredas, setWoredas] = useState([]);
  const [editingRecord, setEditingRecord] = useState(null);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalDeleteGp, setModalDeleteGp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [targetRecord, setTargetRecord] = useState({});
  const [file, setFile] = useState(null);

  const [show, setShow] = useState(false);
  const baseStyle =
    'form-control block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-1 px-1 text-base';

  useEffect(() => {
    getregions();
    getzones();
    getworedas();
  }, []);

  const deleteBailSchema = () => {
    console.log(setTargetRecord);
    axiosInstance
      .delete(
        `${serverIP}/loan_guarantee_person/loan-guarantee-persons/${targetRecord.id}`
      )
      .then((res) => {
        console.log(res.data);
        setModalDeleteGp(false);
        getLoan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getregions = () => {
    axiosInstance
      .get(`${serverIP}/regions/regions/activeregions`)
      .then((res) => {
        setRegions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getzones = () => {
    axiosInstance
      .get(`${serverIP}/zones/zone`)
      .then((res) => {
        setZones(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getworedas = () => {
    axiosInstance
      .get(`${serverIP}/woredas/woreda`)
      .then((res) => {
        setWoredas(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initialValues = {
    fullname: '',
    regionId: '',
    zoneId: '',
    woredaId: '',
    loan: loanId,

    housenum: '',
    idnum: '',
    phonenum: '',
    isMarried: false,
    isCollateralgp: false,
    spauseFullName: '',
    spauseMobileNo: '',
  };

  const handleSubmit = (values, { resetForm }) => {
    console.log('handle submit');
    console.log(values);
    if (editingRecord) {
      // Update existing user
      console.log('editingRecord');
      console.log(editingRecord);
      axiosInstance
        .patch(
          `${serverIP}/loan_guarantee_person/loan-guarantee-persons/${editingRecord.id}`,
          {
            fullname: values.fullname,
            regionId: values.regionId,
            zoneId: values.zoneId,
            woredaId: values.woredaId,

            housenum: values.housenum,
            idnum: values.idnum,
            phonenum: values.phonenum,

            isMarried: values.isMarried,
            isCollateralgp: values.isCollateralgp,
            spauseFullName: values.spauseFullName,
            spauseMobileNo: values.spauseMobileNo,
          }
        )
        .then((res) => {
          successnotify();
          setShowAddModal(false);
          getLoan();
          console.log(res.data);
        })
        .catch((err) => {
          errornotify();
          console.log(err);
        });
      getLoan();

      setEditingRecord(false);
      setShowAddModal(false);
    } else {
      axiosInstance
        .post(`${serverIP}/loan_guarantee_person/loan-guarantee-persons`, {
          fullname: values.fullname,
          regionId: values.regionId,
          zoneId: values.zoneId,
          woredaId: values.woredaId,

          housenum: values.housenum,
          idnum: values.idnum,
          phonenum: values.phonenum,

          isMarried: values.isMarried,
          isCollateralgp: values.isCollateralgp,
          loan: loanId,
          spauseFullName: values.spauseFullName,
          spauseMobileNo: values.spauseMobileNo,
        })
        .then((res) => {
          successnotify();
          setShowAddModal(false);
          // setIsBtnSaveClicked(false)
          getLoan();
          console.log(res.data);
        })
        .catch((err) => {
          errornotify();
          console.log(err);
        });
      getLoan();
      setShowAddModal(false);
    }
    setShowAddModal(false);
    resetForm();
  };

  const getLoan = () => {
    axiosInstance
      .get(`${serverIP}/loan/loans/${targetLoan.id}`)
      .then((res) => {
        dispatch(
          setTargetLoan({
            targetLoan: res.data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteRecord = () => {
    axiosInstance
      .delete(
        `${serverIP}/loan_guarantee_person/loan-guarantee-persons/${targetRecord.id}`
      )
      .then(() => {
        setShowDeleteModal(false);
        getLoan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reLoadData = async () => {
    axiosInstance
      .delete(
        `${serverIP}/loan_guarantee_person_files/loan-guarantee-person-files/deletebyparent/${targetRecord.id}`
      )
      .then((res) => {
        console.log('successfull');
        console.log(res.data);
        getLoan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadDataSingleGp = async (gpId) => {
    setIsLoading(true);
    try {
      const ddlSinglegp = await axiosInstance.get(
        `${serverIP}/ddlsinglegp/ddlsinglegps`
      );

      const transformedArray = ddlSinglegp?.data.map((item) => ({
        parent: gpId,
        user: data.id,
        amDesc: item.amName,
        checkListId: item.id,
        isMandatory: item.isMandatory,
        markValue: item.markValue,
      }));

      await axiosInstance.post(
        `${serverIP}/loan_guarantee_person_files/loan-guarantee-person-files/bulkinsert`,
        transformedArray
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      getLoan();
    }
  };

  const loadDataMarriedGp = async (gpId) => {
    setIsLoading(true);
    try {
      const ddlMarriedgp = await axiosInstance.get(
        `${serverIP}/ddlmarriedsurety/ddlmarriedsurety`
      );

      const transformedArray = ddlMarriedgp?.data.map((item) => ({
        parent: gpId,
        user: data.id,
        amDesc: item.amName,
        checkListId: item.id,
        isMandatory: item.isMandatory,
        markValue: item.markValue,
      }));

      await axiosInstance.post(
        `${serverIP}/loan_guarantee_person_files/loan-guarantee-person-files/bulkinsert`,
        transformedArray
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      getLoan();
    }
  };

  function handleUpload(fileid) {
    if (!file) {
      errornotify();
      return;
    }

    const fd = new FormData();
    fd.append('file', file);
    fd.append('fileType', file.type);
    fd.append('fileUrl', file);
    fd.append('isUploaded', true);

    axiosInstance
      .patch(
        `${serverIP}/loan_guarantee_person_files/loan-guarantee-person-files/${fileid}`,
        fd,
        {
          headers: {
            'Custom-Header': 'value',
          },
        }
      )
      .then(() => {
        successnotify();
        getLoan();
      })
      .catch(() => {
        errornotify();
      });
  }

  const handleDeleteFile = (fileid) => {
    axiosInstance
      .patch(
        `${serverIP}/loan_guarantee_person_files/loan-guarantee-person-files/${fileid}`,
        {
          isUploaded: false,
        }
      )
      .then(() => {
        successnotify();
        getLoan();
      })
      .catch((err) => {
        errornotify();
        console.log(err);
      });
  };

  if (isLoading) {
    return (
      <div className='h-28 flex items-center justify-center bg-gray-100'>
        <div className='flex flex-col gap-3 items-center justify-center'>
          <PulseLoader size={30} color='orange' loading='true' />
          <div style={{ color: '#FFA500' }}>Loading ...</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Alert className='px-3 flex flex-row justify-between py-1 items-center'>
        <div style={{ fontSize: 20, fontWeight: 'bold', color: 'GrayText' }}>
          የመታወቅያ/የንብረት መያዣ
        </div>
        {data.groups[0] === 'ROLE_OFFICER' && (
          <Button
            onClick={() => setShowAddModal(true)}
            className='flex flex-row items-center gap-2'
            disabled={targetLoan?.loanstatus_id !== 1}
          >
            <FaCarAlt /> ጨምር
          </Button>
        )}
      </Alert>
      {/* modal start  */}

      {/* Modal Show Start  */}
      <Modal size='lg' show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {targetRecord.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={serverIP + targetRecord.fileUrl}
            style={{ width: '100%', height: '100%' }}
          />
        </Modal.Body>
      </Modal>
      {/* Modal Show End  */}

      {/* Modal Delete Guarantee Person Start  */}
      <Modal show={modalDeleteGp} onHide={() => setModalDeleteGp(false)}>
        <Modal.Header style={{ color: 'red' }} closeButton>
          <Modal.Title color='red'> Delete </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wnt to delete
          <strong> {targetRecord.fullname}? </strong>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteBailSchema} variant='danger'>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete Guarantee Person End  */}

      {/* add modal Start  */}
      <Modal
        size='lg'
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title> የዋስ ገለሰብ መረጃ </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={editingRecord || initialValues}
          validationSchema={BailSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <Modal.Body>
                <InputField
                  label='ሙሉ ስም '
                  name='fullname'
                  placeholder='ሙሉ ስም'
                  touched={touched.fullname}
                  errors={errors.fullname}
                />
                {/* address  */}
                <div className='flex w-full gap-2'>
                  <div className='mb-1 w-1/3'>
                    <label className='block text-sm font-medium text-gray-700 mb-1'>
                      Region
                    </label>
                    <Field
                      as='select'
                      name='regionId'
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('zoneId', '');
                        setFieldValue('woredaId', '');
                      }}
                      className={baseStyle}
                    >
                      <option value=''>Select Region</option>
                      {regions.map((region) => (
                        <option key={region.id} value={region.id}>
                          {region.amDescription}
                        </option>
                      ))}
                    </Field>
                  </div>

                  <div className='mb-1 w-1/3'>
                    <label className='block text-sm font-medium text-gray-700 mb-1'>
                      Zone
                    </label>
                    <Field
                      as='select'
                      name='zoneId'
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('woredaId', '');
                      }}
                      disabled={!values.regionId}
                      className={` ${baseStyle} ${!values.regionId ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                    >
                      <option value=''>Select Zone</option>
                      {zones
                        .filter((zone) => zone.parent == values.regionId)
                        .map((zone) => (
                          <option key={zone.id} value={zone.id}>
                            {zone.amDescription}
                          </option>
                        ))}
                    </Field>
                  </div>

                  <div className='mb-1 w-1/3'>
                    <label className='block text-sm font-medium text-gray-700 mb-1'>
                      Woreda
                    </label>
                    <Field
                      as='select'
                      name='woredaId'
                      disabled={!values.zoneId}
                      className={`${baseStyle} ${!values.zone ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                    >
                      <option value=''>Select Woreda</option>
                      {woredas
                        .filter((woreda) => woreda.parent == values.zoneId)
                        .map((woreda) => (
                          <option key={woreda.id} value={woreda.id}>
                            {woreda.amDescription}
                          </option>
                        ))}
                    </Field>
                  </div>
                </div>
                <InputField
                  label='የቤት ቁጥር '
                  name='housenum'
                  placeholder='የቤት ቁጥር'
                  touched={touched.housenum}
                  errors={errors.housenum}
                />
                <InputField
                  label='የመታወቅያ ቁጥር'
                  name='idnum'
                  placeholder='የመታወቅያ ቁጥር'
                  touched={touched.idnum}
                  errors={errors.idnum}
                />
                <InputField
                  label='ስልክ ቁጥር'
                  name='phonenum'
                  placeholder='ስልክ ቁጥር'
                  touched={touched.phonenum}
                  errors={errors.phonenum}
                />
                <div className='flex justify-between p-2'>
                  <div>
                    {' '}
                    <Field type='checkbox' name='isCollateralgp' /> የንብረት
                    መያዣ{' '}
                  </div>
                  <div>
                    <Field type='checkbox' name='isMarried' /> ያገባ
                  </div>
                </div>
                {values.isMarried && (
                  <div className='pt-3 flex w-full gap-2'>
                    <InputField
                      className='w-1/2'
                      label='የባለቤት ሙሉ ስም  '
                      name='spauseFullName'
                      placeholder='የባለቤት ሙሉ ስም '
                      touched={touched.spauseFullName}
                      errors={errors.spauseFullName}
                    />
                    <InputField
                      className='w-1/2'
                      label='የባለቤት ስልክ ቁጥር '
                      name='spauseMobileNo'
                      placeholder='የባለቤት ስልክ ቁጥር'
                      touched={touched.spauseMobileNo}
                      errors={errors.spauseMobileNo}
                    />
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer className='flex justify-end'>
                <Button
                  type='submit'
                  variant={editingRecord ? 'warning' : 'primary'}
                >
                  {editingRecord ? 'Update' : 'Save'}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      {/* add modal end  */}

      {/* delete modal Start  */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> በመያዣነት የቀረበው መኪና አጥፋ </Modal.Title>
        </Modal.Header>
        <Modal.Body>!</Modal.Body>
        <Modal.Footer>
          <Button variant='danger btn-sm' onClick={() => deleteRecord()}>
            አጥፋ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete modal End  */}

      {/* modal end  */}

      {/* accordion end  */}

      {targetLoan.gp?.length > 0 && (
        <Accordion defaultActiveKey='1'>
          {targetLoan.gp.map((guaranteep) => {
            return (
              <Accordion.Item key={guaranteep.id} eventKey={guaranteep.id}>
                <Accordion.Header className='bg-emerald-500 '>
                  <div className='font-bold'>{guaranteep.fullname}</div>
                  <div className='ml-7'>
                    {!guaranteep.isMarried ? 'ያላገባ' : <FaRing color='orange' />}
                  </div>

                  {guaranteep.isCollateralgp && (
                    <div className='ml-7'>
                      {guaranteep.cargp?.length +
                        guaranteep.homegp?.length +
                        guaranteep.stockgp?.length}
                      - መያዛዎች (ንብረቶች)
                    </div>
                  )}
                  <div className='ml-7'>
                    {guaranteep.isCollateralgp
                      ? '   የንብረት መያዣ ዋስትና'
                      : '   የመታወቅያ ዋስትና'}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className='flex flex-row p-2 justify-between bg-slate-500 mb-1 text-white'>
                    <div> የመታወቅያ / የንብረት ተያዥ </div>
                    <div
                      className='btn-group'
                      role='group'
                      aria-label='Basic example'
                    >
                      <Button
                        onClick={() => {
                          setEditingRecord({
                            fullname: guaranteep.fullname,
                            regionId: guaranteep.regionId,
                            zoneId: guaranteep.zoneId,

                            woredaId: guaranteep.woredaId,
                            loan: loanId,
                            id: guaranteep.id,

                            housenum: guaranteep.housenum,
                            idnum: guaranteep.idnum,
                            phonenum: guaranteep.phonenum,

                            isMarried: guaranteep.isMarried,
                            isCollateralgp: guaranteep.isCollateralgp,
                            spauseFullName: guaranteep.spauseFullName,
                            spauseMobileNo: guaranteep.spauseMobileNo,
                          });
                          setShowAddModal(true);
                          console.log('selected car');
                          console.log(guaranteep);
                        }}
                        className='btn btn-sm btn-warning'
                        disabled={targetLoan?.loanstatus_id !== 1}
                      >
                        አስተካክል
                      </Button>
                      <button
                        className='btn btn-sm btn-danger'
                        disabled={targetLoan?.loanstatus_id !== 1}
                        onClick={() => {
                          setModalDeleteGp(true);
                          setTargetRecord(guaranteep);
                        }}
                      >
                        አጥፋ
                      </button>
                    </div>
                  </div>

                  {guaranteep.gp_files?.length === 0 ? (
                    <Alert className='px-3 flex flex-row justify-between py-1 items-center'>
                      <div> የተያዥ ሰው መረጃዎች ሳብ </div>

                      {guaranteep.isMarried ? (
                        <Button
                          className='flex gap-2 btn-sm items-center'
                          onClick={() => loadDataMarriedGp(guaranteep.id)}
                        >
                          <FaRing color='orange' /> ሳብ
                        </Button>
                      ) : (
                        <Button
                          className='btn-sm'
                          disabled={targetLoan?.loanstatus_id !== 1}
                          onClick={() => loadDataSingleGp(guaranteep.id)}
                        >
                          ሳብ
                        </Button>
                      )}
                    </Alert>
                  ) : (
                    <>
                      {guaranteep.gp_files?.length > 0 ? (
                        <>
                          <Alert className='px-3 flex flex-row justify-between py-1 items-center'>
                            <div> የተያዥ ሰው መረጃዎች ሳብ</div>
                            <Button
                              disabled={targetLoan?.loanstatus_id !== 1}
                              className='btn btn-warning btn-sm'
                              onClick={() => {
                                setTargetRecord(guaranteep);
                                reLoadData();
                              }}
                            >
                              የፋይል ዝርዝር አጥፋ
                            </Button>
                          </Alert>
                          <Table striped bordered hover style={{ margin: 1 }}>
                            <thead>
                              <tr>
                                <th> የፋይል ስም </th>
                                <th> እይ </th>
                                <th> ድርጊት </th>
                                <th> አጥፋ </th>
                              </tr>
                            </thead>
                            <tbody>
                              {guaranteep.gp_files.map((file) => {
                                return (
                                  <tr
                                    key={file.id}
                                    className={
                                      file.isUploaded ? `` : `table-warning`
                                    }
                                  >
                                    <td>{file.amDesc}</td>
                                    <td>
                                      {file.isUploaded ? (
                                        <>
                                          {file.fileType ===
                                          'application/pdf' ? (
                                            <a
                                              target='_blank'
                                              href={serverIP + file.fileUrl}
                                              rel='noopener noreferrer'
                                            >
                                              <FaFileAlt />
                                            </a>
                                          ) : (
                                            <FaEye
                                              onClick={() => {
                                                setTargetRecord(file);
                                                setShow(true);
                                                console.log('set show clicked');
                                              }}
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td style={{ width: '30%', padding: 0 }}>
                                      {data.groups[0] === 'ROLE_OFFICER' ? (
                                        <>
                                          <InputGroup style={{ paddingTop: 5 }}>
                                            <input
                                              onChange={(e) => {
                                                setFile(e.target.files[0]);
                                                console.log(e.target.files[0]);
                                              }}
                                              type='file'
                                              className='form-control'
                                            />
                                            <Button
                                              disabled={
                                                targetLoan?.loanstatus_id !== 1
                                              }
                                              variant='outline-primary'
                                              onClick={() =>
                                                handleUpload(file.id)
                                              }
                                            >
                                              {!file.isUploaded ? (
                                                <> ጫን </>
                                              ) : (
                                                <>አዘምን</>
                                              )}
                                            </Button>
                                          </InputGroup>
                                        </>
                                      ) : (
                                        <>{file.isUploaded ? 'ተሰቅሏል' : ' '}</>
                                      )}
                                    </td>
                                    <td style={{ width: '10%', padding: 0 }}>
                                      {file.isUploaded && (
                                        <Button
                                          disabled={
                                            targetLoan?.loanstatus_id !== 1
                                          }
                                          variant='outline-danger'
                                          onClick={() =>
                                            handleDeleteFile(file.id)
                                          }
                                        >
                                          አጥፋ
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : (
                        <> </>
                      )}
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      )}
    </div>
  );
}

Bail.propTypes = {
  successnotify: PropTypes.func.isRequired,
  errornotify: PropTypes.func.isRequired,
};

export default Bail;
