import React from 'react';
import { SlMenu } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { setSideBar } from './redux/systemLookups';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { custLogout } from './redux/customerAuthSlice';
import Logo from '../bgno200.png';
import {
  FaDollarSign,
  FaFolderMinus,
  FaHome,
  FaQuestionCircle,
  FaUserAlt,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { NavDropdown } from 'react-bootstrap';
import { LuLogOut } from 'react-icons/lu';
import { changeLang } from './redux/settingSlice';
import { LiaFlagUsaSolid } from 'react-icons/lia';
import { IoReloadCircleSharp } from 'react-icons/io5';
import { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaChartBar } from 'react-icons/fa';
// import { isTokenExpired } from './isTokenExpired';

function AuthenticatedNav() {
  const [t, i18n] = useTranslation('global');
  const { langName } = useSelector((store) => store.allsettings);
  const { sideBar } = useSelector((store) => store.systemLookups);
  const { data } = useSelector((store) => store.customer);

  const link =
    'flex h-[48px] grow items-center justify-center gap-2 rounded-md p-3 hover:bg-sky-100 hover:text-blue-600 md:flex-none md:justify-start md:p-2 md:px-3 text-md';
  const activeLink =
    'flex h-[48px] grow items-center justify-center gap-2 rounded-md p-3 hover:bg-sky-100 hover:text-blue-600 md:flex-none md:justify-start md:p-2 md:px-3 text-lg text-white border-2';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changeLanguageBtn = () => {
    i18n.changeLanguage(langName === 'am' ? 'am' : 'en');
    dispatch(
      changeLang({
        NewLangName: langName === 'en' ? 'am' : 'en',
      })
    );
  };

  const patchApplication = () => {
    // changeIpAddress();
    console.log('object.patchApplication');
  };

  const [isReportOpenLoan, setIsReportOpenLoan] = useState(false);
  const [isSelfService, setIsSelfService] = useState(false);
  const [bankTransfer, setBankTransfer] = useState(false);

  // const [isReportOpenDisburse, setIsReportOpenDisburse] = useState(false);
  // const [isReportOpenWithdraw, setIsReportOpenWithdraw] = useState(false);
  // const [isAddress, setIsAddress] = useState(false);

  const [isProductOpen, setIsProductOpen] = useState(false);
  const [memberRegistration, setMemberRegistration] = useState(false);

  // useEffect(() => {
  //   if (isTokenExpired(token)) {
  //     // Handle token expiration, e.g., redirect to login
  //     console.log('Token is expired');
  //     dispatch(custLogout());
  //     navigate('/login');
  //   } else {
  //     console.log('Token is still valid');
  //   }
  // }, []);

  return (
    <div className='flex'>
      {sideBar && (
        <div className='flex justify-between flex-col w-2/12 min-h-screen bg-gradient-to-br from-orange-300 lg:p-4'>
          <div>
            <div className='flex flex-col items-center p-6'>
              <img src={Logo} width='150vh' height='150vh' alt='logo' />
            </div>

            <NavLink
              to='/'
              key='home'
              className={({ isActive }) => {
                return isActive ? activeLink : link;
              }}
            >
              <FaHome />
              <p className='hidden md:block'>{t('home.home')}</p>
            </NavLink>

            {data.groups[0] === 'ROLE_SALES' && (
              <>
                <NavLink
                  to='/salesmyloans'
                  key='salesmyloans'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaQuestionCircle />
                  <p className='hidden md:block'>
                    {t('drawer.loanapplications')}
                  </p>
                </NavLink>
                <NavLink
                  to='/helpsales'
                  key='helpsales'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaQuestionCircle />
                  <p className='hidden md:block'>{t('drawer.help')}</p>
                </NavLink>
              </>
            )}

            {data.groups[0] === 'ROLE_ADMIN' && (
              <>
                <NavLink
                  to='/employees'
                  key='employees'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaUserAlt />
                  <p className='hidden md:block'>ተቀጣሪዎች</p>
                </NavLink>
                <NavLink
                  to='/loan'
                  key='loan'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaFolderMinus />
                  <p className='hidden md:block'> ብድሮች </p>
                </NavLink>

                {/* Collapsible Bank Transfer Start */}
                <div className='mt-1'>
                  <button
                    onClick={() => setBankTransfer(!bankTransfer)}
                    className={`${link} w-full justify-between`}
                  >
                    <div className='flex items-center'>
                      <FaDollarSign className='mr-2' />
                      <span> የቴሌብር ክፍያዎች </span>
                    </div>
                    {bankTransfer ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                  {bankTransfer && (
                    <div className='ml-4'>
                      <NavLink
                        to='/newtransfers'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'> አዲስ ክፍያዎች </p>
                      </NavLink>
                      <NavLink
                        to='/syncdata'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'> ወደስይስተም የተገለበጡ </p>
                      </NavLink>
                    </div>
                  )}
                </div>
                {/* Collapsible Bank Transfer End */}

                {/* Collapsible Report Navigation Loan Start */}
                <div className='mt-1'>
                  <button
                    onClick={() => setIsReportOpenLoan(!isReportOpenLoan)}
                    className={`${link} w-full justify-between`}
                  >
                    <div className='flex items-center'>
                      <FaChartBar className='mr-2' />
                      <span>Loan Reports</span>
                    </div>
                    {isReportOpenLoan ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                  {isReportOpenLoan && (
                    <div className='ml-4'>
                      <NavLink
                        to='/rptplanloan'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'>Loan Plans</p>
                      </NavLink>
                      <NavLink
                        to='/rpthistoryloan'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'>Loan History </p>
                      </NavLink>
                    </div>
                  )}
                </div>
                {/* Collapsible Report Navigation Loan End */}

                {/* Collapsible Report Navigation Withdraw Start */}
                <div className='mt-1'>
                  <button
                    onClick={() => setIsProductOpen(!isProductOpen)}
                    className={`${link} w-full justify-between`}
                  >
                    <div className='flex items-center'>
                      <FaChartBar className='mr-2' />
                      <span>Product + Saving</span>
                    </div>
                    {isProductOpen ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                  {isProductOpen && (
                    <div className='ml-4'>
                      <NavLink
                        to='/advancedsaving'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'>Advanced Saving </p>
                      </NavLink>
                      <NavLink
                        to='/compulsarysaving'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'>Compulsary Saving</p>
                      </NavLink>
                      <NavLink
                        to='/contractualsaving'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'>Contractual Saving</p>
                      </NavLink>
                      <NavLink
                        to='/auditlog'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'>Audit Log</p>
                      </NavLink>
                      <NavLink
                        to='/ssreport'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'>Report</p>
                      </NavLink>
                      <NavLink
                        to='/productsavinglookups'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'>Lookups</p>
                      </NavLink>
                    </div>
                  )}
                </div>
                {/* Collapsible Report Navigation Withdraw End */}

                {/* Collapsible Member Registration Start */}
                <div className='mt-1'>
                  <button
                    onClick={() => setMemberRegistration(!memberRegistration)}
                    className={`${link} w-full justify-between`}
                  >
                    <div className='flex items-center'>
                      <FaChartBar className='mr-2' />
                      <span>Member Registration</span>
                    </div>
                    {memberRegistration ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                  {memberRegistration && (
                    <div className='ml-4'>
                      <NavLink
                        to='/addmember'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'>Add Member </p>
                      </NavLink>
                    </div>
                  )}
                </div>
                {/* Collapsible Member Registration End */}

                {/* Collapsible Report Navigation Withdraw Start */}
                {/* <div className="mt-1">
                  <button
                    onClick={() => setIsReportOpenWithdraw(!isReportOpenWithdraw)}
                    className={`${link} w-full justify-between`}
                  >
                    <div className="flex items-center">
                      <FaChartBar className="mr-2" />
                      <span>Withdraw Reports</span>
                    </div>
                    {isReportOpenWithdraw ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                  {isReportOpenWithdraw && (
                    <div className="ml-4">
                      <NavLink to="/rptplanwithdraw" className={({ isActive }) => (isActive ? activeLink : link)}>
                        <p className="hidden md:block">Withdraw Plan </p>
                      </NavLink>
                      <NavLink to="/rpthistorywithdraw" className={({ isActive }) => (isActive ? activeLink : link)}>
                        <p className="hidden md:block">Withdraw History</p>
                      </NavLink>
                    </div>
                  )}
                </div> */}
                {/* Collapsible Report Navigation Withdraw End */}

                {/* Collapsible Report Navigation Disburse Start */}
                {/* <div className="mt-1">
                  <button
                    onClick={() => setIsReportOpenDisburse(!isReportOpenDisburse)}
                    className={`${link} w-full justify-between`}
                  >
                    <div className="flex items-center">
                      <FaChartBar className="mr-2" />
                      <span>Disburse Reports</span>
                    </div>
                    {isReportOpenDisburse ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                  {isReportOpenDisburse && (
                    <div className="ml-4">
                      <NavLink to="/rptplandisburse" className={({ isActive }) => (isActive ? activeLink : link)}>
                        <p className="hidden md:block">Disburse Plan </p>
                      </NavLink>
                      <NavLink to="/rpthistorydisburse" className={({ isActive }) => (isActive ? activeLink : link)}>
                        <p className="hidden md:block">Disburse History</p>
                      </NavLink>
                    </div>
                  )}
                </div> */}
                {/* Collapsible Report Navigation Disburse End */}

                {/* Collapsible Self-Service Start */}
                <div className='mt-1'>
                  <button
                    onClick={() => setIsSelfService(!isSelfService)}
                    className={`${link} w-full justify-between`}
                  >
                    <div className='flex items-center'>
                      <FaChartBar className='mr-2' />
                      <span>Self Service</span>
                    </div>
                    {isSelfService ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                  {isSelfService && (
                    <div className='ml-4'>
                      <NavLink
                        to='/alpausers'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'>ALPA Users </p>
                      </NavLink>
                      <NavLink
                        to='/almedausers'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'>ALMEDA Users </p>
                      </NavLink>  
                      <NavLink
                        to='/regularusers'
                        className={({ isActive }) =>
                          isActive ? activeLink : link
                        }
                      >
                        <p className='hidden md:block'>REGULAR Users </p>
                      </NavLink>
                    </div>
                  )}
                </div>
                {/* Collapsible Self-Service End */}
              </>
            )}

            {data.groups[0] === 'ROLE_AUDITOR' && (
              <>
                <NavLink
                  to='/helpauditor'
                  key='helpauditor'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaQuestionCircle />
                  <p className='hidden md:block'>{t('drawer.help')}</p>
                </NavLink>
                <NavLink
                  to='/afinishedloans'
                  key='afinishedloans'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaFolderMinus />
                  <p className='hidden md:block'>{t('drawer.finishedloans')}</p>
                </NavLink>
                {/* <NavLink
                  to="/auditorwithdrawhome"
                  key="auditorwithdrawhome"
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaFolderMinus />
                  <p className="hidden md:block"> ወጪ (ከተቀማጭ) </p>
                </NavLink> */}
              </>
            )}

            {data.groups[0] === 'ROLE_OFFICER' && (
              <>
                <NavLink
                  to='/myloans'
                  key='myloans'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaQuestionCircle />
                  <p className='hidden md:block'>
                    {t('drawer.loanapplications')}
                  </p>
                </NavLink>
                <NavLink
                  to='/helpofficer'
                  key='helpofficer'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaQuestionCircle />
                  <p className='hidden md:block'>{t('drawer.help')}</p>
                </NavLink>
                <NavLink
                  to='/myfinishedloans'
                  key='myfinishedloans'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaFolderMinus />
                  <p className='hidden md:block'>{t('drawer.finishedloans')}</p>
                </NavLink>
              </>
            )}

            {data.groups[0] === 'ROLE_TEAM_LEADER' && (
              <>
                <NavLink
                  to='/helpteamleader'
                  key='helpteamleader'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaQuestionCircle />
                  <p className='hidden md:block'>{t('drawer.help')}</p>
                </NavLink>
                <NavLink
                  to='/finishedloans'
                  key='finishedloans'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaFolderMinus />
                  <p className='hidden md:block'>{t('drawer.finishedloans')}</p>
                </NavLink>
              </>
            )}

            {data.groups[0] === 'ROLE_PLANNER' && (
              <>
                <NavLink
                  to='/helpplanner'
                  key='helpplanner'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaQuestionCircle />
                  <p className='hidden md:block'>{t('drawer.help')}</p>
                </NavLink>
                <NavLink
                  to='/withdrawrow'
                  key='withdrawrow'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaFolderMinus />
                  <p className='hidden md:block'> ወጪ (ከተቀማጭ) </p>
                </NavLink>
                <NavLink
                  to='/disbursenew'
                  key='disbursenew'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaFolderMinus />
                  <p className='hidden md:block'> ወጪ (ከተፈቀደው ብድር) </p>
                </NavLink>
              </>
            )}

            {/* Disbursement and Withdraw  */}
            {data.groups[0] === 'ROLE_CLERK' && (
              <>
                <NavLink
                  to='/helpclerk'
                  key='helpclerk'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaQuestionCircle />
                  <p className='hidden md:block'>{t('drawer.help')}</p>
                </NavLink>
              </>
            )}

            {data.groups[0] === 'ROLE_AUTHENTICATOR' && (
              <>
                <NavLink
                  to='/helpauthenticator'
                  key='helpauthenticator'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaQuestionCircle />
                  <p className='hidden md:block'>{t('drawer.help')}</p>
                </NavLink>
              </>
            )}

            {data.groups[0] === 'ROLE_FINANCE' && (
              <>
                <NavLink
                  to='/nearw'
                  key='nearw'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaQuestionCircle />
                  <p className='hidden md:block'> ወጪ (ከተቀማጭ) </p>
                </NavLink>
                <NavLink
                  to='/neard'
                  key='neard'
                  className={({ isActive }) => {
                    return isActive ? activeLink : link;
                  }}
                >
                  <FaQuestionCircle />
                  <p className='hidden md:block'> ወጪ (ከተፈቀደው ብድር) </p>
                </NavLink>
              </>
            )}

            {/* Disbursement and Withdraw  */}
          </div>
          <div className='bg-white w-full p-2'>
            <div className='flex justify-center text-orange-400 font-extrabold'>
              {data.groups[0]}
            </div>
          </div>
        </div>
      )}

      <div
        className={`flex-1 ${sideBar ? 'w-10/12  h-full' : 'w-full  h-full'}`}
      >
        <nav className='bg-gray-100 text-black py-3 px-4 flex justify-between items-center  border-b-2'>
          <div className='flex flex-row items-center gap-2'>
            <button
              onClick={() => {
                dispatch(setSideBar());
              }}
              className='text-black p-2'
            >
              <SlMenu />
            </button>

            <button
              onClick={changeLanguageBtn}
              type='button'
              style={{ fontWeight: 'bold', padding: 1 }}
            >
              {t('auth.language') === 'EN' ? (
                <LiaFlagUsaSolid size={25} color='orange' />
              ) : (
                t('auth.language')
              )}
            </button>
            <IoReloadCircleSharp onClick={patchApplication} size={25} />
          </div>
          <ul className='flex justify-center text-xl gap-3'>
            <FaUserAlt size={24} />
            {data.solomon}
            <NavDropdown
              title={<span>{data.first_name + ' ' + data.last_name}</span>}
              id='navbarScrollingDropdown'
              style={{ fontWeight: 'bold' }}
            >
              <NavDropdown.Item href='/profile'>
                {t('profile.personalinfo')}
              </NavDropdown.Item>
              <NavDropdown.Item href='/changepass'>
                {t('auth.changepassword')}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                style={{ display: 'flex', justifyContent: 'space-between' }}
                onClick={() => {
                  dispatch(custLogout());
                  navigate('/login');
                }}
              >
                {t('auth.logout')} <LuLogOut />
              </NavDropdown.Item>
            </NavDropdown>
          </ul>
        </nav>

        <div className='p-3'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AuthenticatedNav;
