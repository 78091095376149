import React, { useEffect, useState } from 'react';
import { Alert, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { setTargetClient, setTargetLoan } from '../Common/redux/systemLookups';
// import LoanStatus from '../Common/LoanStatus';
import axiosInstance from '../axiosInstance';
import DataTable from 'react-data-table-component';
import { PulseLoader } from 'react-spinners';

function TeamLeaderHome() {
  const [t] = useTranslation('global');
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);
  const [loans, setLoans] = useState([]);
  const [filteredLoans, setFilteredLoans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      name: 'ExternalID',
      selector: (row) => row.customer_entityExternalId,
      sortable: true,
    },
    {
      name: 'ሙሉ ስም',
      selector: (row) => row.customer_amDisplayName,
      sortable: true,
    },
    {
      name: 'የተፈቀደው የብድር መጠን',
      selector: (row) => row.r_approvedPrincipal?.toLocaleString(),
      sortable: true,
    },
    {
      name: 'የያዘው አካል',
      cell: (row) => (
        <>
          <Badge>
            {row?.loanstatus_id === 1 && 'ኦፊሰር'}
            {row?.loanstatus_id === 2 && 'ቡድን መሪ'}
            {row?.loanstatus_id === 3 && 'ኦዲተር'}
            {row?.loanstatus_id === 4 && 'አልቋል'}
          </Badge>
        </>
      ),
    },
    {
      name: 'ኦፊሰር',
      cell: (row) => (
        <div className='flex gap-2'>
          <div> {row.submitedBy_name + ' ' + row.submitedBy_fathername} </div>
        </div>
      ),
    },
    {
      name: '5 C',
      cell: (row) =>
        isValidReport(row.loan_fonance_items) ? (
          <Link
            className='font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl'
            disabled={true}
            onClick={() => redirectEvalPage(row?.customer, row?.id)}
          >
            ግምገማ
          </Link>
        ) : (
          <div className='text-red-500 font-bold'>የተሟላ አይደለም</div>
        ),
    },
    {
      name: 'ውሳኔ',
      cell: (row) => (
        <Link
          className='font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl'
          disabled={true}
          onClick={() => redirectPage(row?.customer, row?.id)}
        >
          ውሳኔ
        </Link>
      ),
    },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setAllLoans();
  }, []);

  const setAllLoans = async () => {
    try {
      setIsLoading(true);
      let loans = await axiosInstance.get(`${serverIP}/loan/loanonteamleader`);
      setLoans(loans.data);
      setFilteredLoans(loans.data);
      console.log(loans.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const redirectPage = async (custId, loanId) => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));

      const cusres = await axiosInstance.get(
        `${serverIP}/customer/customers/${custId}`
      );
      dispatch(setTargetClient({ client: cusres.data }));

      navigate(`/loandecide/${custId}/${loanId}`);
    } catch (error) {
      console.error('Error in redirecting:', error);
    }
  };

  const redirectEvalPage = async (custId, loanId) => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));

      const cusres = await axiosInstance.get(
        `${serverIP}/customer/customers/${custId}`
      );
      dispatch(setTargetClient({ client: cusres.data }));

      navigate(`/loanevaluation/${custId}/${loanId}`);
      // navigate(`/creditanalysis/${custId}/${loanId}`);
    } catch (error) {
      console.error('Error in redirecting:', error);
    }
  };

  const isValidReport = (FinanceItems) => {
    let res = false;
    if (FinanceItems.length == 0) {
      res = false;
    } else if (FinanceItems[35]?.markValue >= 0) {
      res = false;
    } else if (FinanceItems[36]?.markValue == 0) {
      res = false;
    } else if (
      FinanceItems.some((x) => x.category_id === 6 && x.markValue !== 0)
    ) {
      // Expected income
      res = true;
    } else if (
      FinanceItems.some((x) => x.category_id === 9 && x.markValue !== 0)
    ) {
      // Capital
      res = true;
    } else {
      res = true;
    }
    return res;
  };

  if (isLoading) {
    return (
      <div className='h-screen flex items-center justify-center bg-gray-100'>
        <div className='flex flex-col gap-3 items-center justify-center'>
          <PulseLoader size={30} color='orange' loading='true' />
          <div style={{ color: '#FFA500' }}>Loading ...</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Alert>
        {t('applicant.loansAssignedTo')} <strong> {data.first_name} </strong>
      </Alert>

      <div className='flex justify-between p-1 bg-slate-300 rounded-md'>
        <input
          type='text'
          className='form-control'
          placeholder='search'
          onChange={(e) =>
            setFilteredLoans(
              loans.filter(
                (item) =>
                  item?.customer?.amDisplayName
                    .toLocaleLowerCase()
                    .includes(e.target.value.toLocaleLowerCase()) ||
                  item?.customer?.entityExternalId
                    .toString()
                    .includes(e.target.value)
              )
            )
          }
        />
      </div>
      <div className='mt-2 p-1 bg-slate-200'>
        <DataTable columns={columns} data={filteredLoans} pagination>
          {' '}
        </DataTable>
      </div>
    </div>
  );
}

export default TeamLeaderHome;
