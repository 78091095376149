import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance';
import { useSelector } from 'react-redux';
import { FaCarAlt, FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { Alert, Button, ButtonGroup, Modal } from 'react-bootstrap';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import WoredaSchema from '../Schema/WoredaSchema';
import InputField from '../../ReusableFormiks/InputField';
import DataTable from 'react-data-table-component';

function Woredas() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const [regions, setRegions] = useState([]);
  const [zones, setZones] = useState([]);
  const [woredas, setWoredas] = useState([]);
  const [filteredWoredas, setFilteredWoredas] = useState([]);

  const [editingRecord, setEditingRecord] = useState(null);
  const [targetRecord, setTargetRecord] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [modalDelete, setDeleteModal] = useState(false);

  const [show, setShow] = useState(false);
  const baseStyle =
    'form-control block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-1 px-1 text-base';

  useEffect(() => {
    getAllLookupFiles();
    getWoredas();
  }, []);

  const columns = [
    {
      name: 'ስም',
      selector: (row) => row.amDescription,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) =>
        row.isActive ? (
          <Button
            className='btn btn-sm btn-danger'
            onClick={() => {
              // deactivateRecord(row.id);
            }}
          >
            Deactivate
          </Button>
        ) : (
          <Button
            className='btn btn-sm btn-primary'
            onClick={() => {
              // activateRecord(row.id);
            }}
          >
            Activate
          </Button>
        ),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <ButtonGroup size='sm'>
          <Button variant='danger'>
            <FaTrashAlt
              onClick={() => {
                setDeleteModal(true);
                setTargetRecord(row);
                console.log(row);
              }}
            />
          </Button>
          <Button variant='warning'>
            <FaPencilAlt
              onClick={() => {
                setShowAddModal(true);
                setTargetRecord(row);
                setEditingRecord({
                  regionId: row.regionId,
                  parent: row.parent,
                  description: row.description,
                  amDescription: row.amDescription,
                });
              }}
            />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  const getWoredas = () => {
    axiosInstance
      .get(`${serverIP}/woredas/woreda`)
      .then(function (response) {
        console.log(response.data);
        setWoredas(response.data);
        setFilteredWoredas(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getAllLookupFiles = async () => {
    // 'regions',
    let res_regions = await axiosInstance.get(`${serverIP}/regions/regions`);
    setRegions(res_regions.data);
    // 'regions',
    let res_zones = await axiosInstance.get(`${serverIP}/zones/zone`);
    setZones(res_zones.data);
  };

  const initialValues = {
    regionId: '',
    parent: '',
    description: '',
    amDescription: '',
  };

  const handleSubmit = (values, { resetForm }) => {
    console.log('handle submit');
    console.log(values);

    if (editingRecord) {
      console.log(values);
      axiosInstance
        .patch(`${serverIP}/woredas/woreda/${targetRecord.id}`, {
          regionId: values.regionId,
          parent: values.parent,
          description: values.description,
          amDescription: values.amDescription,
        })
        .then((res) => {
          setShowAddModal(false);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      getWoredas();
      setEditingRecord(false);
      setShowAddModal(false);
    } else {
      axiosInstance
        .post(`${serverIP}/woredas/woreda`, {
          regionId: values.regionId,
          parent: values.parent,
          description: values.description,
          amDescription: values.amDescription,
        })
        .then((res) => {
          setShowAddModal(false);
          getWoredas();

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      getWoredas();
      setShowAddModal(false);
    }
    setShowAddModal(false);
    setEditingRecord(false);
    resetForm();
  };

  const deleteRecord = () => {
    axiosInstance
      .delete(`${serverIP}/woredas/woreda/${targetRecord.id}`)
      .then(() => {
        setDeleteModal(false);
        getWoredas();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Alert className='px-3 flex flex-row justify-between py-1 items-center'>
        <div style={{ fontSize: 20, fontWeight: 'bold', color: 'GrayText' }}>
          የወረዳዎች መረጃ{' '}
        </div>
        <Button
          onClick={() => {
            setShowAddModal(true);
            setEditingRecord(false);
          }}
          className='flex flex-row items-center gap-2'
        >
          <FaCarAlt /> ጨምር
        </Button>
      </Alert>

      {/* modal start  */}
      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setDeleteModal(false)}>
        <Modal.Header style={{ color: 'red' }} closeButton>
          <Modal.Title color='red'> አጥፋ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wnt to delete{' '}
          <strong> {targetRecord.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} variant='danger'>
            አጥፋ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}

      {/* Modal Show Start  */}
      <Modal size='lg' show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {targetRecord.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={serverIP + targetRecord.fileUrl}
            style={{ width: '100%', height: '100%' }}
          />
        </Modal.Body>
      </Modal>
      {/* Modal Show End  */}

      {/* add modal Start  */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> የወረዳዎች መረጃ </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={editingRecord || initialValues}
          validationSchema={WoredaSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <Modal.Body>
                {/* address start  */}
                <div className='mb-1'>
                  <label className='block text-sm font-medium text-gray-700 mb-1'>
                    Region
                  </label>
                  <Field
                    as='select'
                    name='regionId'
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue('parent', '');
                    }}
                    className={baseStyle}
                  >
                    <option value=''>Select Region</option>
                    {regions.map((region) => (
                      <option key={region.id} value={region.id}>
                        {region.amDescription}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    style={{ color: 'red' }}
                    name='regionId'
                    component='div'
                  />
                </div>

                <div className='mb-1'>
                  <label className='block text-sm font-medium text-gray-700 mb-1'>
                    Zone
                  </label>
                  <Field
                    as='select'
                    name='parent'
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    disabled={!values.regionId}
                    className={` ${baseStyle} ${!values.regionId ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                  >
                    <option value=''>Select Zone</option>
                    {zones
                      .filter((zone) => zone.parent == values.regionId)
                      .map((zone) => (
                        <option key={zone.id} value={zone.id}>
                          {zone.amDescription}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    style={{ color: 'red' }}
                    name='parent'
                    component='div'
                  />
                </div>
                {/* address end   */}
                <InputField
                  label='ስም '
                  name='amDescription'
                  placeholder='ስም '
                  touched={touched.amDescription}
                  errors={errors.amDescription}
                />
                <InputField
                  label='Name'
                  name='description'
                  placeholder='Name'
                  touched={touched.description}
                  errors={errors.description}
                />
              </Modal.Body>
              <Modal.Footer className='flex justify-end'>
                <Button
                  type='submit'
                  variant={editingRecord ? 'warning' : 'primary'}
                >
                  {editingRecord ? 'Update' : 'Save'}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      {/* add modal end  */}
      {/* modal end  */}
      <div>
        <div className='flex justify-between p-1 bg-slate-300 rounded-md'>
          <input
            type='text'
            className='form-control'
            placeholder='search'
            onChange={(e) =>
              setFilteredWoredas(
                woredas.filter(
                  (item) =>
                    item.description
                      .toLocaleLowerCase()
                      .includes(e.target.value.toLocaleLowerCase()) ||
                    item.amDescription.includes(e.target.value)
                )
              )
            }
          />
        </div>
        <div className='mt-2 p-1 bg-slate-200'>
          <DataTable columns={columns} data={filteredWoredas} pagination>
            {' '}
          </DataTable>
        </div>
      </div>
    </div>
  );
}

export default Woredas;
