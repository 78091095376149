import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

const InputField = ({
  label,
  name,
  type = 'text',
  placeholder = '',
  touched,
  errors,
  disabled = false,
  className = '',
}) => {
  return (
    <div className='mb-1'>
      <label
        htmlFor={name}
        className='block text-sm font-medium text-gray-700 mb-1'
      >
        {label}
      </label>
      <Field
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        className={`
          block w-full rounded-md border-gray-300 shadow-sm 
          focus:border-indigo-500 focus:ring-indigo-500 
          py-2 px-3 text-base
          ${touched && errors ? 'border-red-500' : ''}
          ${disabled ? 'bg-gray-100 cursor-not-allowed' : ''}
          ${className}
        `}
      />
      {touched && errors && (
        <p className='mt-1 text-sm text-red-600'>{errors}</p>
      )}
    </div>
  );
};
InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  errors: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default InputField;
