import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTargetClient, setTargetLoan } from '../Common/redux/systemLookups';
import axiosInstance from '../axiosInstance';

import { Link, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { PulseLoader } from 'react-spinners';
import { Badge } from 'react-bootstrap';

/**
 * This function is the main component of the Loan Processor System. It is responsible for managing the entire system, including the processing of loans, the tracking of customer information, and the monitoring of audit activities.
 *
 * @param {object} props - This is an object that contains the properties of the component.
 * @param {object} props.allsettings - This is an object that contains all of the settings for the application.
 * @param {string} props.allsettings.serverIP - This is a string that contains the IP address of the server.
 *
 * @returns {JSX.Element} - This function returns the JSX code for the Loan Processor System.
 */
function AuditorHome() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const [loans, setLoans] = useState([]);
  const [filteredLoans, setFilteredLoans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const redirectEvalPage = async (custId, loanId) => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));

      const cusres = await axiosInstance.get(
        `${serverIP}/customer/customers/${custId}`
      );
      dispatch(setTargetClient({ client: cusres.data }));

      navigate(`/loanevaluation/${custId}/${loanId}`);
      // navigate(`/creditanalysis/${custId}/${loanId}`);
    } catch (error) {
      console.error('Error in redirecting:', error);
    }
  };

  const columns = [
    {
      name: 'ExternalID',
      selector: (row) => row.customer_entityExternalId,
      sortable: true,
    },
    {
      name: 'ሙሉ ስም',
      selector: (row) => row.customer_amDisplayName,
      sortable: true,
    },
    {
      name: 'የተፈቀደው የብድር መጠን',
      selector: (row) => row.r_approvedPrincipal?.toLocaleString(),
      sortable: true,
    },

    {
      name: 'የያዘው አካል',
      cell: (row) => (
        <>
          <Badge>
            {row?.loanstatus_id === 1 && 'ኦፊሰር'}
            {row?.loanstatus_id === 2 && 'ቡድን መሪ'}
            {row?.loanstatus_id === 3 && 'ኦዲተር'}
            {row?.loanstatus_id === 4 && 'አልቋል'}
          </Badge>
        </>
      ),
    },

    {
      name: 'ኦፊሰር',
      cell: (row) => row.submitedBy_name + ' ' + row.submitedBy_fathername,
    },

    {
      name: '5 C',
      cell: (row) =>
        isValidReport(row.loan_fonance_items) ? (
          <Link
            className='font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl'
            disabled={true}
            onClick={() => redirectEvalPage(row?.customer, row?.id)}
          >
            ግምገማ
          </Link>
        ) : (
          <div className='text-red-500 font-bold'>የተሟላ አይደለም</div>
        ),
    },
    {
      name: 'ውሳኔ',
      cell: (row) => (
        <Link
          className='font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl'
          disabled={true}
          onClick={() => redirectPage(row?.customer, row?.id)}
        >
          ውሳኔ
        </Link>
      ),
    },
    {
      name: 'ውሎች',
      cell: (row) => (
        <Link
          to={`/amcontracts/${row?.customer}/${row?.id}`}
          className='font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl'
        >
          ውሎች
        </Link>
      ),
    },
    {
      name: 'እግዶች',
      cell: (row) => (
        <Link
          to={`/amprohibitions/${row?.customer}/${row?.id}`}
          className='font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl'
        >
          እግዶች
        </Link>
      ),
    },
    {
      name: 'የአዲተር ግምገማ ',
      cell: (row) => (
        <Link
          to={`/auditorfinalcomments/${row?.customer}/${row?.id}`}
          className='font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl'
        >
          የአዲተር ግምገማ
        </Link>
      ),
    },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getlpscustomers();
  }, []);

  const getlpscustomers = async () => {
    try {
      setIsLoading(true);

      let loans = await axiosInstance.get(`${serverIP}/loan/loanonauditor`);
      setLoans(loans.data);
      setFilteredLoans(loans.data);
      console.log(loans.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const redirectPage = async (custId, loanId) => {
    try {
      await getLoan(loanId);
      await getCustomer(custId);
      navigate(`/loandecide/${custId}/${loanId}`);
    } catch (error) {
      console.error('Error in redirecting:', error);
    }
  };

  const getLoan = async (loanId) => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error('Error fetching loan:', err);
    }
  };

  const getCustomer = async (custId) => {
    try {
      const res = await axiosInstance.get(
        `${serverIP}/customer/customers/${custId}`
      );
      dispatch(setTargetClient({ client: res.data }));
    } catch (err) {
      console.error('Error fetching customer:', err);
    }
  };

  const isValidReport = (FinanceItems) => {
    let res = false;
    if (FinanceItems.length == 0) {
      res = false;
    } else if (FinanceItems[35]?.markValue >= 0) {
      res = false;
    } else if (FinanceItems[36]?.markValue == 0) {
      res = false;
    } else if (
      FinanceItems.some((x) => x.category_id === 6 && x.markValue !== 0)
    ) {
      // Expected income
      res = true;
    } else if (
      FinanceItems.some((x) => x.category_id === 9 && x.markValue !== 0)
    ) {
      // Capital
      res = true;
    } else {
      res = true;
    }
    return res;
  };

  if (isLoading) {
    return (
      <div className='h-screen flex items-center justify-center bg-gray-100'>
        <div className='flex flex-col gap-3 items-center justify-center'>
          <PulseLoader size={30} color='orange' loading='true' />
          <div style={{ color: '#FFA500' }}>Loading ...</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='flex justify-between p-1 bg-slate-300 rounded-md'>
        <input
          type='text'
          className='form-control'
          placeholder='search'
          onChange={(e) =>
            setFilteredLoans(
              loans.filter(
                (item) =>
                  item?.customer?.amDisplayName
                    .toLocaleLowerCase()
                    .includes(e.target.value.toLocaleLowerCase()) ||
                  item?.customer?.entityExternalId
                    .toString()
                    .includes(e.target.value)
              )
            )
          }
        />
      </div>
      <div className='mt-2 p-1 bg-slate-200'>
        <DataTable columns={columns} data={filteredLoans} pagination>
          {' '}
        </DataTable>
      </div>
    </div>
  );
}

export default AuditorHome;
