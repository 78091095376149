import React, { useEffect, useRef, useState } from 'react';
import axiosInstance from '../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, Image } from 'react-bootstrap';
import { setTargetLoan } from '../Common/redux/systemLookups';
import PropTypes from 'prop-types';
import ChatBox from '../Common/ChatBox';
import { FaPrint } from 'react-icons/fa';

import AmigosLogo from '../Images/new.PNG';

function AuditorFinalComments() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { targetLoan, targetClient } = useSelector(
    (store) => store.systemLookups
  );
  const [thedatas, setthedatas] = useState([]);
  const [audititemcategory, setaudititemcategory] = useState([]);
  const dispatch = useDispatch();
  const printRef = useRef();

  useEffect(() => {
    getAuditItemCategories();
  }, []);

  const getAuditItemCategories = async () => {
    try {
      const fndatas = await axiosInstance.get(
        `${serverIP}/audititemcategory/audititemcategory`
      );
      setaudititemcategory(fndatas.data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadData = async () => {
    try {
      const fndatas = await axiosInstance.get(
        `${serverIP}/audititems/audititems`
      );
      const transformedArray = fndatas?.data.map((item) => ({
        amDesc: item.amName,
        category: item.category,
        loan: targetLoan.id,
        dataid: item.id,
      }));
      await axiosInstance.post(
        `${serverIP}/audititemsperloan/audititemsperloan/bulkinsert`,
        transformedArray
      );
    } catch (error) {
      console.log(error);
    } finally {
      console.log('pull data');
      getLoan();
    }
  };

  const getLoan = async () => {
    try {
      const ln_res = await axiosInstance.get(
        `${serverIP}/loan/loans/${targetLoan.id}`
      );
      dispatch(
        setTargetLoan({
          targetLoan: ln_res.data,
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      console.log('finally');
    }
  };

  useEffect(() => {
    const categoryMap = new Map();
    targetLoan?.loan_audit_items?.forEach((item) => {
      if (!categoryMap.has(item.category)) {
        categoryMap.set(item.category, []);
      }
      categoryMap.get(item.category).push(item);
    });

    const groupedData = Array.from(categoryMap, ([category, items]) => ({
      category,
      items,
    }));
    setthedatas(groupedData);
  }, [targetLoan]);

  const saveRadioButtonChange = async ({ id, itemStatus }) => {
    try {
      await axiosInstance.patch(
        `${serverIP}/audititemsperloan/audititemsperloan/${id}`,
        {
          itemStatus,
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      getLoan();
    }
  };

  const handlePrint = () => {
    const printContents = printRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  return (
    <div>
      <div className='flex justify-between bg-gray-500 p-2 rounded-md mb-1'>
        <div>መረጃ ተስቧል </div>
        <div className='flex justify-end'>
          {thedatas.length == 0 ? (
            <Button className='btn-sm' onClick={() => loadData()}>
              ሳብ
            </Button>
          ) : (
            <button
              onClick={handlePrint}
              className='flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400'
            >
              <FaPrint className='mr-2' /> Print
            </button>
          )}
        </div>
      </div>
      {/* {JSON.stringify(thedatas)} */}
      <div ref={printRef}>
        <div className='flex w-full p-2'>
          <Image className='h-24 w-3/12' src={AmigosLogo} />
          <div className='flex flex-col w-8/12 justify-center items-center'>
            <div className='text-xl font-bold text-orange-500'>
              አሚጎስ የገንዘብ ቁጠባና ብድር ተቋም
            </div>
            <div className='text-xl font-bold text-orange-500'>
              የብድር መጠየቅያ ቅጽ
            </div>
          </div>
          <Image className='h-24 w-3/12' src={AmigosLogo} />
        </div>

        <div className='m-2'>
          <ul>
            <li> የተበዳሪ ሙሉ ስም፡ {targetClient.amDisplayName} </li>
            <li> ስልክ ቁጥር፡ {targetClient.mobileNo} </li>
            <li> የተበዳሪ መለያ ቁጥር፡ {targetClient.entityExternalId} </li>
          </ul>
        </div>

        {thedatas.length > 0 &&
          thedatas.map((item, index) => {
            return (
              <div key={index}>
                <div className='flex text-center p-2 bg-green-300 rounded-md'>
                  {' '}
                  {audititemcategory.find((x) => x.id === item.category)
                    ?.amName || item.category}{' '}
                </div>
                {item.items.length > 0 && (
                  <Table striped bordered hover style={{ margin: 1 }}>
                    <thead>
                      <tr>
                        <th> የመስፈርት ስም </th>
                        <th> ተሟልቷል </th>
                        <th> አልተሟላም </th>
                        <th> አይመለከተውም/ታትም </th>
                      </tr>
                    </thead>
                    {/* //  # 0 - not decided, 1 = completed, 2 = notComplete, 3 = donotapply */}
                    <tbody>
                      {' '}
                      {item.items.map((item, index) => (
                        <tr key={index}>
                          <td> {item.amDesc} </td>
                          <td>
                            <input
                              type='radio'
                              disabled={targetLoan?.auditorapproved}
                              checked={item.itemStatus == 1}
                              name={item.id}
                              onChange={() => {
                                saveRadioButtonChange({
                                  id: item.id,
                                  itemStatus: 1,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type='radio'
                              disabled={targetLoan?.auditorapproved}
                              checked={item.itemStatus == 2}
                              name={item.id}
                              onChange={() => {
                                saveRadioButtonChange({
                                  id: item.id,
                                  itemStatus: 2,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type='radio'
                              disabled={targetLoan?.auditorapproved}
                              checked={item.itemStatus == 3}
                              name={item.id}
                              onChange={() => {
                                saveRadioButtonChange({
                                  id: item.id,
                                  itemStatus: 3,
                                });
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            );
          })}
      </div>
      <ChatBox />
    </div>
  );
}

AuditorFinalComments.propTypes = {
  targetLoan: PropTypes.func,
};

export default AuditorFinalComments;
