import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

function FiveColumns(props) {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        fontSize: 9,
        fontFamily: 'AmharicFont',
        backgroundColor: props.bgcolor,
      }}
    >
      <View
        style={{
          borderWidth: 1,
          borderTopWidth: 0,
          borderRightWidth: 0,
          width: '5%',
          paddingLeft: 2,
          paddingBottom: 1,
        }}
      >
        <Text style={{ textAlign: props.textalign }}>{props.id}</Text>
      </View>

      <View
        style={{
          borderWidth: 1,
          borderTopWidth: 0,
          borderRightWidth: 0,
          width: '20%',
          paddingLeft: 2,
          paddingBottom: 1,
        }}
      >
        <Text style={{ textAlign: props.textalign }}>{props.gsname}</Text>
      </View>
      <View
        style={{
          borderWidth: 1,
          borderTopWidth: 0,
          borderRightWidth: 0,
          width: '20%',
          paddingLeft: 2,
          paddingBottom: 1,
        }}
      >
        <Text style={{ textAlign: props.textalign }}>{props.gname}</Text>
      </View>
      <View
        style={{
          borderWidth: 1,
          borderTopWidth: 0,
          borderRightWidth: 0,
          width: '20%',
          paddingLeft: 2,
          paddingBottom: 1,
        }}
      >
        <Text style={{ textAlign: props.textalign }}>{props.guarantee}</Text>
      </View>
      <View
        style={{
          borderWidth: 1,
          borderTopWidth: 0,
          borderRightWidth: 0,
          width: '20%',
          paddingLeft: 2,
          paddingBottom: 1,
        }}
      >
        <Text style={{ textAlign: props.textalign }}>{props.gsign}</Text>
      </View>
      <View
        style={{
          borderWidth: 1,
          borderTopWidth: 0,
          width: '15%',
          paddingLeft: 2,
          paddingBottom: 1,
        }}
      >
        <Text>{props.gssign}</Text>
      </View>
    </View>
  );
}
FiveColumns.propTypes = {
  bgcolor: PropTypes.string,
  textalign: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gname: PropTypes.string,
  gsname: PropTypes.string,
  guarantee: PropTypes.string,
  gsign: PropTypes.string,
  gssign: PropTypes.string,
};

export default FiveColumns;
