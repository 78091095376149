// src/axiosInstance.js

import axios from 'axios';
import store from './Common/redux/Store';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: 'https://your-api-url.com', // Replace with your API base URL
});

// Add a request interceptor to include the Bearer token in all requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().customer.token; // Get token from Redux store

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
