import React from 'react';

function ContractualSaving() {
  return (
    <div>
      <div className='text-xl font-bold bg-orange-200 p-2 rounded-md'>
        CONTRACTUAL SAVING
      </div>
    </div>
  );
}

export default ContractualSaving;
