import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '20vh',
        rowGap: '2vh',
        color: '#f2a654',
      }}
    >
      <h1 style={{ fontSize: '15vh', fontWeight: 'bolder' }}>404</h1>
      <h2 style={{ fontSize: '5vh', fontWeight: 'bolder' }}> SORRY!</h2>
      <h2>The page you’re looking for was not found.</h2>
      <Link
        to='/'
        className='font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl'
      >
        Back to home
      </Link>
    </div>
  );
}

export default NotFound;
