import React, { useState, useEffect } from 'react';
import { Alert, Button, ButtonGroup } from 'react-bootstrap';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../axiosInstance';
import DataTable from 'react-data-table-component';

function Zones() {
  const [t] = useTranslation('global');
  const { serverIP } = useSelector((store) => store.allsettings);

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [targetObj, setTargetObj] = useState({});

  const [allRegions, setallRegions] = useState([]);
  const [filteredZones, setFilteredZones] = useState([]);

  const [description, setdescription] = useState('');
  const [amDescription, setamDescription] = useState('');
  const [parent, setParent] = useState(0);

  //   description, amDescription

  useEffect(() => {
    getallRegions();
    getallZOnes();
  }, []);

  const columns = [
    {
      name: 'ስም',
      selector: (row) => row.amDescription,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) =>
        row.isActive ? (
          <Button
            className='btn btn-sm btn-danger'
            onClick={() => {
              deactivateRecord(row.id);
            }}
          >
            Deactivate
          </Button>
        ) : (
          <Button
            className='btn btn-sm btn-primary'
            onClick={() => {
              activateRecord(row.id);
            }}
          >
            Activate
          </Button>
        ),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <ButtonGroup size='sm'>
          <Button variant='danger'>
            <FaTrashAlt
              onClick={() => {
                setModalDelete(true);
                setTargetObj(row);
                console.log(row);
              }}
            />
          </Button>
          <Button variant='warning'>
            <FaPencilAlt
              onClick={() => {
                setModalEdit(true);
                setdescription(row.description);
                setamDescription(row.amDescription);
                setTargetObj(row);
              }}
            />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  const getallRegions = () => {
    axiosInstance
      .get(`${serverIP}/regions/regions`)
      .then(function (response) {
        console.log(response.data);
        setallRegions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallZOnes = () => {
    axiosInstance
      .get(`${serverIP}/zones/zone`)
      .then(function (response) {
        console.log(response.data);
        setFilteredZones(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveallZOnes = () => {
    console.log('save');
    setModalAdd(false);
    axiosInstance
      .post(`${serverIP}/zones/zone`, {
        description: description,
        amDescription: amDescription,
        parent: parent,
      })
      .then(function (response) {
        console.log(response);
        getallZOnes();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editallZOnes = () => {
    axiosInstance
      .patch(`${serverIP}/zones/zone/${targetObj.id}`, {
        description: description,
        amDescription: amDescription,
      })
      .then(function (response) {
        console.log(response);
        getallZOnes();
        setModalEdit(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteallZOnes = () => {
    axiosInstance
      .delete(`${serverIP}/zones/zone/${targetObj.id}`)
      .then((res) => {
        console.log(res.data);
        setModalDelete(false);
        getallZOnes();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const activateRecord = (regid) => {
    axiosInstance
      .patch(`${serverIP}/zones/zone/${regid}`, {
        isActive: true,
      })
      .then(function (response) {
        console.log(response);
        getallZOnes();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deactivateRecord = (regid) => {
    axiosInstance
      .patch(`${serverIP}/zones/zone/${regid}`, {
        isActive: false,
      })
      .then(function (response) {
        console.log(response);
        getallZOnes();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: 'red' }} closeButton>
          <Modal.Title color='red'> {t('officer.delete')} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('admin.confirmDelete')} <strong> {targetObj.description} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => deleteallZOnes()}>
            {t('officer.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}
      {/* Modals Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('officer.add')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='basic-addon1'>ስም</InputGroup.Text>
            <Form.Control
              placeholder='ስም'
              onChange={(e) => setamDescription(e.target.value)}
            />
          </InputGroup>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='basic-addon1'>Name</InputGroup.Text>
            <Form.Control
              placeholder='Name'
              onChange={(e) => setdescription(e.target.value)}
            />
          </InputGroup>
          <select
            onChange={(e) => setParent(e.target.value)}
            className='form-control'
            value={parent}
          >
            {/* <option value="0"> ክልል ይምረጡ </option> */}
            {allRegions.map((region) => {
              return (
                <option key={region.id} value={region.id}>
                  {region.amDescription}
                </option>
              );
            })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => saveallZOnes()}>
            {t('officer.save')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'orange' }}> አስተካክል </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='basic-addon1'>ስም</InputGroup.Text>
            <Form.Control
              value={amDescription}
              onChange={(e) => setamDescription(e.target.value)}
            />
          </InputGroup>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='basic-addon1'>Name</InputGroup.Text>
            <Form.Control
              value={description}
              onChange={(e) => setdescription(e.target.value)}
            />
          </InputGroup>
          <select
            onChange={(e) => setParent(e.target.value)}
            className='form-control'
            value={parent}
          >
            {/* <option value="0"> ክልል ይምረጡ </option> */}
            {allRegions.map((region) => {
              return (
                <option
                  key={region.id}
                  selected={region.id == targetObj?.parent}
                  value={region.id}
                >
                  {region.amDescription}
                </option>
              );
            })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='warning' onClick={() => editallZOnes()}>
            {t('officer.edit')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modals End  */}

      <Alert className='px-3 flex flex-row justify-between py-1 items-center'>
        <div> ዞኖች </div>
        <Button
          variant='primary'
          style={{ margin: 0 }}
          className='btn btn-sm'
          onClick={() => setModalAdd(true)}
        >
          {t('officer.Add')}
        </Button>
      </Alert>

      <div className='flex justify-between p-1 bg-slate-300 rounded-md'>
        {/* <input type='text' className='form-control' placeholder='search' onChange={e => setFilteredEmployees(emps.filter(item => (item?.first_name.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) || item?.username.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))))} /> */}
      </div>
      <div className='mt-2 p-1 bg-slate-200'>
        <DataTable columns={columns} data={filteredZones} pagination>
          {' '}
        </DataTable>
      </div>
    </div>
  );
}

export default Zones;
