import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaMoon } from 'react-icons/fa';
import { PulseLoader } from 'react-spinners';
import axiosInstance from '../axiosInstance';
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';

function MyFinishedLoans() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);
  const [loans, setLoans] = useState([]);
  const [filteredLoans, setFilteredLoans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showTip, setShowTip] = useState(false);

  const columns = [
    {
      name: 'ExternalID',
      selector: (row) => row.customer_entityExternalId,
      sortable: true,
    },
    {
      name: 'ሙሉ ስም',
      selector: (row) => row.customer_amDisplayName,
      sortable: true,
    },
    {
      name: 'የተፈቀደው የብድር መጠን',
      selector: (row) => row.r_approvedPrincipal?.toLocaleString(),
      sortable: true,
    },
    {
      name: 'ውሎች',
      cell: (row) =>
        row.isInterestFree ? (
          <Link
            to={`/infreeamcontracts/${row?.customer}/${row?.id}`}
            className=' flex items-center gap-1 font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl'
          >
            <p>ውሎች</p> <FaMoon />
          </Link>
        ) : (
          <Link
            to={`/amcontracts/${row?.customer}/${row?.id}`}
            className='font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl'
          >
            ውሎች
          </Link>
        ),
    },
    {
      name: 'እግዶች',
      cell: (row) => (
        <Link
          to={`/amprohibitions/${row?.customer}/${row?.id}`}
          className='font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl'
        >
          እግዶች
        </Link>
      ),
    },
  ];

  useEffect(() => {
    getlpscustomers();
  }, []);

  const getlpscustomers = async () => {
    try {
      setIsLoading(true);
      const res_ci = await axiosInstance.get(
        `${serverIP}/loan/myfinishedloans/${data.id}`
      );
      console.log(res_ci.data);
      setLoans(res_ci.data);
      setFilteredLoans(res_ci.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className='h-28 flex items-center justify-center bg-gray-100'>
        <div className='flex flex-col gap-3 items-center justify-center'>
          <PulseLoader size={30} color='orange' loading='true' />
          <div style={{ color: '#FFA500' }}>Loading ...</div>
        </div>
      </div>
    );
  }

  // const redirectPage = async (custId, loanId) => {
  //   try {
  //     const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
  //     dispatch(setTargetLoan({ targetLoan: res.data }));

  //     const cusres = await axiosInstance.get(
  //       `${serverIP}/customer/customers/${custId}`
  //     );
  //     dispatch(setTargetClient({ client: cusres.data }));

  //     navigate(`/loanonofficer/${custId}/${loanId}`);
  //   } catch (error) {
  //     console.error('Error in redirecting:', error);
  //   }
  // };

  // const isValidReport = (FinanceItems) => {
  //   let res = false;
  //   if (FinanceItems.length == 0) {
  //     res = false;
  //   } else if (FinanceItems[35]?.markValue >= 0) {
  //     res = false;
  //   } else if (FinanceItems[36]?.markValue == 0) {
  //     res = false;
  //   } else if (
  //     FinanceItems.some((x) => x.category_id === 6 && x.markValue !== 0)
  //   ) {
  //     // Expected income
  //     res = true;
  //   } else if (
  //     FinanceItems.some((x) => x.category_id === 9 && x.markValue !== 0)
  //   ) {
  //     // Capital
  //     res = true;
  //   } else {
  //     res = true;
  //   }
  //   return res;
  // };

  return (
    <div>
      <div className='row'>
        <Modal
          show={showTip}
          onHide={() => setShowTip(false)}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>ግምገማ </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ግምገማው እንዲሰራ እንደ ቅድመ ሁኔታ መግባት ያለባቸው መረጃዎች
            <ul>
              <li>
                1. Expected Income From ከሚለው ምድብ አንድ መረጃ
                <ul className='px-4'>
                  <li>a. ለምሳሌ From product or service sale&apos;s = 240,000</li>
                </ul>
              </li>
              <li>
                2. Capital ከሚለው ምድብ አንድ መረጃ
                <ul className='px-4'>
                  <li>a. ለምሳሌ shares or other related bounds = 250,000</li>
                </ul>
              </li>
              <li>
                3. Cash ከሚለው ምድብ አንድ መረጃ
                <ul className='px-4'>
                  <li>
                    a. Initial Investment = must be negative value = -3,000,000
                  </li>
                  <li>
                    b. Sum of cashinflow = must be positive value = 6,920,006
                  </li>
                </ul>
              </li>
            </ul>
          </Modal.Body>
        </Modal>

        <div className='col-sm-12'>
          <div className='flex justify-between p-1 bg-slate-300 rounded-md'>
            <input
              type='text'
              className='form-control'
              placeholder='search'
              onChange={(e) =>
                setFilteredLoans(
                  loans.filter(
                    (item) =>
                      item?.customer?.amDisplayName
                        .toLocaleLowerCase()
                        .includes(e.target.value.toLocaleLowerCase()) ||
                      item?.customer?.entityExternalId
                        .toString()
                        .includes(e.target.value)
                  )
                )
              }
            />
          </div>
          <div className='mt-2 p-1 bg-slate-200'>
            <DataTable columns={columns} data={filteredLoans} pagination>
              {' '}
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyFinishedLoans;
