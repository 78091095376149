import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

function TextFormikInput(props) {
  return (
    <div style={{ width: '100%', paddingLeft: 5 }}>
      <label>{props.placeholder}</label>
      <Field
        className='form-control'
        placeholder={props.placeholder}
        type={props.type === null ? 'text' : props.type}
        name={props.name}
        value={props.value}
      />
      <ErrorMessage
        style={{ color: 'red' }}
        name={props.name}
        component='div'
      />
    </div>
  );
}
TextFormikInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default TextFormikInput;
