import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ProductAndSavingLookups() {
  return (
    <div className='flex gap-1'>
      <ListGroup as='ol' numbered className='px-3 py-1 w-1/2'>
        <ListGroup.Item className='d-flex justify-content-between items-center'>
          <Link to='/pgrade' className='m-1 me-auto fw-bold'>
            Product Grade
          </Link>
        </ListGroup.Item>

        <ListGroup.Item className='d-flex justify-content-between items-center'>
          <Link to='/psavingtype' className='m-1 me-auto fw-bold'>
            Saving Type
          </Link>
        </ListGroup.Item>

        <ListGroup.Item className='d-flex justify-content-between items-center'>
          <Link to='/psourceofinfo' className='m-1 me-auto fw-bold'>
            Source Of Information
          </Link>
        </ListGroup.Item>
      </ListGroup>

      <ListGroup as='ol' numbered className='px-3 py-1 w-1/2'></ListGroup>
    </div>
  );
}

export default ProductAndSavingLookups;
