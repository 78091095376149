import React, { useState } from 'react';

// New Way Practice
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from 'react-bootstrap';
import {
  FaChartLine,
  FaFilePdf,
  FaMoneyBillWave,
  FaRing,
  FaUserAlt,
} from 'react-icons/fa';
import { FaCarAlt, FaFemale, FaHome, FaMale } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';

import Customer from './Customer';
import Loan from './Loan';
import MarriedFiles from './MarriedFiles';
import Spause from './Spause';
import Car from './Car';
import House from './House';
import Salary from './Salary';
import Share from './Share';
import Bail from './Bail';
import Additional from './Additional';
import SingleFiles from './SingleFiles';
import RequestForm from './RequestForm';
// import CarNew from './CarNew';
// import CalculateFinancialMetrics from './CalculateFinancialMetrics';

function TabOrganizer() {
  const [t] = useTranslation('global');
  const { targetClient, targetLoan } = useSelector(
    (store) => store.systemLookups
  );
  const [key, setKey] = useState('Customer');

  const successnotify = (message) => {
    toast.success(message === '' ? 'ስራውን በተሳካ ሁኔታ ተከናዉኗል!' : message);
  };

  const errornotify = (message) => {
    toast.error(message === '' ? 'ስህተት አጋጥሟል!' : message);
  };

  return (
    <>
      <ToastContainer />
      <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
        <Tab
          eventKey='Customer'
          title={<span>{t('applicant.customerInfo')}</span>}
        >
          <Customer
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
            targetClient={targetClient}
          />
        </Tab>

        <Tab eventKey='RequestForm' title='መጠየቅያ ፎርም'>
          <RequestForm
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
            targetClient={targetClient}
          />
        </Tab>

        <Tab
          eventKey='Loan'
          title={<span>{t('applicant.loanInformation')}</span>}
        >
          <Loan
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab>

        {targetClient?.isMarried && (
          <Tab
            eventKey='MarriedFiles'
            title={
              <span className='flex flex-row gap-1 items-center'>
                <FaRing /> {t('applicant.loanerfiles')}
              </span>
            }
          >
            <MarriedFiles
              errornotify={errornotify}
              successnotify={successnotify}
              targetClient={targetClient}
              targetLoan={targetLoan}
            />
          </Tab>
        )}
        {!targetClient?.isMarried && (
          <Tab
            eventKey='SingleFiles'
            title={<span>{t('applicant.loanerfiles')} </span>}
          >
            <SingleFiles
              errornotify={errornotify}
              successnotify={successnotify}
              targetClient={targetClient}
            />
          </Tab>
        )}
        {targetClient?.isMarried && (
          <Tab
            eventKey='Spause'
            title={
              <span className='flex flex-row gap-1 items-center'>
                <FaFemale />/<FaMale />
                <div>{t('applicant.spouseInformation')}</div>
              </span>
            }
          >
            <Spause
              errornotify={errornotify}
              successnotify={successnotify}
              targetClient={targetClient}
            />
          </Tab>
        )}

        <Tab
          eventKey='car'
          title={
            <span className='flex flex-row gap-1 items-center'>
              <FaCarAlt /> <div> {t('applicant.carCollateral')} </div>
            </span>
          }
        >
          <Car
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab>

        {/* <Tab
          eventKey='carnew'
          title={
            <span className='flex flex-row gap-1 items-center'>
              <FaCarAlt /> <div> {t('applicant.carCollateral')} </div>
            </span>
          }
        >
          <CarNew
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab> */}

        <Tab
          eventKey='House'
          title={
            <span className='flex flex-row gap-1 items-center'>
              <FaHome /> {t('applicant.houseCollateral')}
            </span>
          }
        >
          <House
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab>

        <Tab
          eventKey='Salary'
          title={
            <span className='flex flex-row gap-1 items-center'>
              <FaMoneyBillWave /> {t('applicant.salaryCollateral')}
            </span>
          }
        >
          <Salary
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab>
        <Tab
          eventKey='Share'
          title={
            <span className='flex flex-row gap-1 items-center'>
              <FaChartLine /> {t('applicant.stockCollateral')}
            </span>
          }
        >
          <Share
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab>

        <Tab
          eventKey='Bail'
          title={
            <span className='flex flex-row gap-1 items-center'>
              <FaUserAlt /> {t('applicant.humanCollateral')}
            </span>
          }
        >
          <Bail
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab>
        <Tab
          eventKey='Additional'
          title={
            <span className='flex flex-row gap-1 items-center'>
              <FaFilePdf /> <div>ተጨማሪ ዶክመንቶች</div>
            </span>
          }
        >
          <Additional
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
          />
        </Tab>

        {/* 
        <Tab
          eventKey="CalculateFinancialMetrics"
          title={
            <span className="flex flex-row gap-1 items-center">
              <FaFilePdf /> <div>CalculateFinancialMetrics</div>
            </span>
          }
        >
          <CalculateFinancialMetrics  errornotify={errornotify} successnotify={successnotify} targetLoan={targetLoan} />
        </Tab>
      */}
      </Tabs>
      <div className='mt-20'></div>
    </>
  );
}

export default TabOrganizer;
