import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
// import ReportStyle from '../ReportStyle';
import ReportStyle from './ReportStyle';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TblAuditorEvaluation from '../Contracts/Reusables/TblAuditorEvaluation';
import axiosInstance from '../axiosInstance';

function AuditorEvaluation({ targetLoan }) {
  const { serverIP } = useSelector((store) => store.allsettings);

  const [audititemcategory, setaudititemcategory] = useState([]);
  const [thedatas, setthedatas] = useState([]);

  useEffect(() => {
    getAuditItemCategories();
  }, []);

  useEffect(() => {
    const categoryMap = new Map();
    targetLoan?.loan_audit_items?.forEach((item) => {
      if (!categoryMap.has(item.category)) {
        categoryMap.set(item.category, []);
      }
      categoryMap.get(item.category).push(item);
    });

    const groupedData = Array.from(categoryMap, ([category, items]) => ({
      category,
      items,
    }));
    setthedatas(groupedData);
  }, [targetLoan]);

  const getAuditItemCategories = async () => {
    try {
      const fndatas = await axiosInstance.get(
        `${serverIP}/audititemcategory/audititemcategory`
      );
      setaudititemcategory(fndatas.data);
      console.log('setaudititemcategory');
      console.log(fndatas.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PDFViewer style={{ width: '100%', height: 700 }}>
      <Document title='የኦዲተር ግምገማ '>
        <Page style={ReportStyle.body} wrap>
          {thedatas.length > 0 &&
            thedatas.map((item, index) => {
              return (
                <View
                  style={item.itemStatus == 0 ? ReportStyle.redtext : ''}
                  key={index}
                >
                  <Text style={ReportStyle.heading_one}>
                    {' '}
                    {audititemcategory.find((x) => x.id === item.category)
                      ?.amName || item.category}{' '}
                  </Text>
                  <Text style={ReportStyle.boldline}></Text>
                  {item.items.map((item, index) => {
                    return (
                      <TblAuditorEvaluation
                        key={index}
                        zkey={item.amDesc}
                        zvalue={
                          (item.itemStatus == 1 && 'ተሟልቷል ') ||
                          (item.itemStatus == 2 && 'አልተሟላም ') ||
                          (item.itemStatus == 3 && 'አይመለከተውም/ታትም')
                        }
                      />
                    );
                  })}
                </View>
              );
            })}
          <View
            style={{
              marginTop: 10,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <View>
              <Text style={ReportStyle.text_normal}>
                {' '}
                CheckedBy:{' '}
                {targetLoan.auditor_name +
                  ' ' +
                  targetLoan.auditor_fathername}{' '}
              </Text>
              <Text style={ReportStyle.text_normal}>
                {' '}
                Date: ____________________________
              </Text>
              <Text style={ReportStyle.text_normal}>
                {' '}
                Signature: ____________________________
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

AuditorEvaluation.propTypes = {
  targetLoan: PropTypes.func,
};

export default AuditorEvaluation;
