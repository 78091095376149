import React, { useState, useEffect } from 'react';
import { Alert, Button, ButtonGroup, Table, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import axiosInstance from '../axiosInstance';

function JobPosition() {
  const { serverIP } = useSelector((store) => store.allsettings);

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [jobpositions, setjobpositions] = useState([]);

  const [enName, setEnName] = useState('');
  const [amName, setAmName] = useState('');

  const [targetObj, setTargetObj] = useState({});

  useEffect(() => {
    getJobPositions();
  }, []);

  const getJobPositions = () => {
    axiosInstance
      .get(`${serverIP}/jobposition/jobposition`)
      .then(function (res) {
        console.log(res);
        setjobpositions(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveJobPosition = () => {
    axiosInstance
      .post(`${serverIP}/jobposition/jobposition`, {
        amName,
        enName,
      })
      .then(function (res) {
        setModalAdd(false);
        console.log(res.data);

        toast.success('Saved successfuly');
        getJobPositions();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const updateJobPosition = () => {
    axiosInstance
      .patch(`${serverIP}/jobposition/jobposition/${targetObj.id}`, {
        amName,
        enName,
      })
      .then(function (res) {
        console.log(res.data);

        setModalEdit(false);
        toast.success('Saved Updated');
        getJobPositions();
      })
      .catch(function (error) {
        console.log(error);
        setModalEdit(false);
        toast.error('No Update applied');
      });
  };
  const deleteJobPosition = () => {};

  return (
    <div>
      <ToastContainer autoClose={1000} />
      {/* Modals Start  */}
      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>
              <div style={{ fontSize: 'bold' }}> የስራ ድርሻ </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='basic-addon1'>ስም </InputGroup.Text>
            <Form.Control
              placeholder='ስም'
              aria-describedby='basic-addon1'
              onChange={(e) => setAmName(e.target.value)}
            />
          </InputGroup>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='basic-addon1'>Name </InputGroup.Text>
            <Form.Control
              placeholder='name'
              aria-describedby='basic-addon1'
              onChange={(e) => setEnName(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => saveJobPosition()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'orange' }}> Edit </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='basic-addon1'>ስም </InputGroup.Text>
            <Form.Control
              placeholder='ስም'
              aria-describedby='basic-addon1'
              value={amName}
              onChange={(e) => setAmName(e.target.value)}
            />
          </InputGroup>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='basic-addon1'>Name </InputGroup.Text>
            <Form.Control
              placeholder='name'
              aria-describedby='basic-addon1'
              value={enName}
              onChange={(e) => setEnName(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='warning' onClick={() => updateJobPosition()}>
            Edit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: 'red' }} closeButton>
          <Modal.Title color='red'> Delete </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wnt to delete <strong> {targetObj.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => deleteJobPosition()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}
      {/* Modals End  */}

      <Alert className='px-3 flex flex-row justify-between py-1 items-center'>
        <div> Job Positions </div>
        <Button
          variant='primary'
          className='btn-sm'
          onClick={() => setModalAdd(true)}
        >
          Add
        </Button>
      </Alert>

      {jobpositions.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th> ስም </th>
              <th> Name </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {jobpositions.map((jps) => {
              return (
                <tr key={jps.id}>
                  <td>{jps.id}</td>
                  <td>{jps.amName}</td>
                  <td>{jps.enName}</td>
                  <td>
                    <ButtonGroup size='sm'>
                      <FaTrashAlt
                        color='red'
                        onClick={() => {
                          setModalDelete(true);
                          setTargetObj(jps);
                        }}
                      />
                      &nbsp;
                      <FaPencilAlt
                        color='orange'
                        onClick={() => {
                          setModalEdit(true);
                          setTargetObj(jps);
                          setEnName(jps.enName);
                          setAmName(jps.amName);
                        }}
                      />
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default JobPosition;
