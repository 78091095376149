import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReportStyle from '../ReportStyle';
import AmigosLogo from '../../Images/new.PNG';

import TextBoldUnderlined from '../Reusables/TextBoldUnderlined';
import { numberToWords } from '../../Common/modules/EnNumToWord';
import TextBold from '../Reusables/TextBold';
import getEthiopicDate from '../../Common/modules/GregorianToEthiopian';

import PropTypes from 'prop-types';

function AmHomeGuarantee({ loan, home }) {
  const curdate = new Date();
  const etcurrdate = getEthiopicDate(
    curdate.getDate(),
    +' ' + curdate.getMonth() + 1,
    +' ' + curdate.getFullYear()
  );

  const changeToEthiopic = (date) => {
    const dateArray = date.split('-');
    // return getEthiopicDate(5,5,2005);
    return getEthiopicDate(
      Number(dateArray[2]),
      Number(dateArray[1]),
      Number(dateArray[0])
    );
  };

  return (
    <PDFViewer style={{ width: '100%', height: 700 }}>
      <Document title='የቤት መያዣ'>
        <Page style={ReportStyle.body} wrap>
          <View style={ReportStyle.container}>
            <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Text style={ReportStyle.name}> የመያዣ ውል ስምምነት </Text>
                <Text style={ReportStyle.name}> የቤት መያዣ </Text>
              </View>
            </View>
            <Image style={ReportStyle.ReporIimage} src={AmigosLogo} />
          </View>

          {Object.keys(loan).length > 0 ? (
            <>
              {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ START */}
              <View>
                <Text style={ReportStyle.heading_one}>የውሉ ዓላማ </Text>
              </View>
              <View>
                <View>
                  {home?.homegp === null ? (
                    <>
                      <Text style={ReportStyle.text_normal}>
                        ይህ የመያዣ ውል ሊደረግ የቻለው በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር
                        ኃ/የተ/ህ/ስ/ማህበር እና በተበዳሪ
                        {
                          <TextBoldUnderlined
                            text={loan?.customer_amDisplayName}
                          />
                        }
                        መካከል በ{<TextBoldUnderlined text={etcurrdate} />} ቀን
                        በተፈጸመ የብድር ውል መነሻ ሲሆን ለብደሩ መመለስ ዋስትና ይሆን ዘንድ ንብረትነቱ የ
                        {
                          <TextBoldUnderlined
                            text={loan.customer_amDisplayName}
                          />
                        }
                        የሆነውን በካርታ ቁጥር
                        {<TextBoldUnderlined text={home.uniquenum} />} የተሰጠበት ቀን
                        {' ' + changeToEthiopic(home.givenDate)} በ
                        {
                          <TextBoldUnderlined
                            text={loan?.customer_amDisplayName}
                          />
                        }
                        ስም ተመዝግቦ የሚገኝ ቤትና ይዞታ በዋስትናነት ለማስያዝ ነው፡፡
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={ReportStyle.text_normal}>
                        ይህ የመያዣ ውል ሊደረግ የቻለው በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር
                        ኃ/የተ/ህ/ስ/ማህበር እና በተበዳሪ
                        {
                          <TextBoldUnderlined
                            text={loan?.customer_amDisplayName}
                          />
                        }
                        መካከል በ{<TextBoldUnderlined text={etcurrdate} />} ቀን
                        በተፈጸመ የብድር ውል መነሻ ሲሆን ለብደሩ መመለስ ዋስትና ይሆን ዘንድ ንብረትነቱ የ
                        <TextBoldUnderlined text={home?.homegp} /> የሆነውን ቤት በካርታ
                        ቁጥር
                        {<TextBoldUnderlined text={home.uniquenum} />} የተሰጠበት ቀን
                        {' ' + changeToEthiopic(home.givenDate)} በ
                        {<TextBoldUnderlined text={home.homegp} />} ስም ተመዝግቦ
                        የሚገኝ ቤትና ይዞታ በዋስትናነት ለማስያዝ ነው፡፡
                      </Text>
                    </>
                  )}
                </View>
              </View>

              {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ END */}

              <View>
                <Text style={ReportStyle.heading_one}> የዉሉ ዝርዝር </Text>
              </View>
              <View>
                <Text style={ReportStyle.text_normal}>
                  1. እኔ አቶ/ወ/ሮ
                  <TextBoldUnderlined text={loan?.customer_amDisplayName} />.
                  ንብረትነቱ
                  <TextBoldUnderlined
                    text={`${home.homegp === null ? 'የእኔ' : home.homegp}`}
                  />
                  የሆነ ቤት ፣ የቤት ቁጥሩ
                  {<TextBoldUnderlined text={home.houseno} />} የሆነ
                  {/* {<TextBoldUnderlined text={home.hometype.amName} />}  */}
                  በመባል የሚጠራ መኖሪያ ቤት/የንግድ ቤት በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር 
                  {<TextBoldUnderlined text={etcurrdate} />} ቀን በተፈጸመ የብድር ውል መሰረት 
                  ተበዳሪ የተበደሩት ገንዘብ ለመመለስ ዋስትና ይሆን ዘንድ በመያዣነት
                  እንዲያዝ መፍቀዴን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                </Text>

                <Text style={ReportStyle.text_normal}>
                  2. እኔም በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር ከላይ በተራ ቁጥር 1
                  ስር ዝርዝር መግለጫው የተጠቀሰው ቤት ተበዳሪው ለተበደሩት ገንዘብ ዋስትና ይሆን ዘንድ 
                  ንብረትነቱ የዋሱ የሆነውን ከላይ የተገለጸው ቤት በመያዣነት ለመያዝ ተስማምቻለሁ፡፡
                </Text>

                <Text style={ReportStyle.text_normal}>
                  3. ተጠቃሹን ቤት በመያዣነት ከተያዘበት ቀን ጀምሮ ብድሩ ተከፍሎ እስከሚጠናቀቅበት ጊዜ ድረስ
                  እንዳይሸጥ ፣ እንዳይለወጥ በሌላ በማንኛውም ምክንያት ወደ 3ኛ ወገን እንዳይተላለፍ እና ምንም
                  ዓይነት ጉዳት ሳይደርስበት እየተንከባከብኩ የመጠበቅ ኃላፊነቱን እኔ ዋሱ የወሰድኩ መሆኑን
                  በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                </Text>




                <Text style={ReportStyle.text_normal}>
                  4. ተበዳሪው ምን አልባት የተበደርኩትን ገንዘብ በወቅቱና በጊዜው መክፈል ቢያቅተኝ ፣ በየወሩ
                  መክፈል የሚጠበቅብኝን የብድር ገንዘብ ብር
                  <TextBoldUnderlined
                    text={loan?.monthly_pay?.toLocaleString()}
                  />
                  <TextBold text={` (${numberToWords(loan?.monthly_pay)}) `} />
                  ለተከታታይ 2/ሁለት/ወራት ሳይከፍሉ ቢቀሩ ፣ አበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር
                  ኃ/የተ/ህ/ስ/ማህበር ከሚመለከታቸው የህግ አስከባሪ አካላት ጋር በመሆን ቤቱን/ቦታውን ተረክበው
                  ለሚፈልገው ወገን በመሸጥ የብድሩን ገንዘብ ወስዶ ቀሪ ተመላሽ ካለ እንዲመልስልኝ የተስማማሁ መሆኔን
                  በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                </Text>

                <Text style={ReportStyle.text_normal}>
                  5. ከላይ በተራ ቁጥር 4 ስር የተገለጸው እንደተጠበቀ ሆኖ ቤት/ይዞታ የተሸጠበት ዋጋ ከብድር
                  ገንዘቡ የሚያንስ ቢሆን አበዳሪው ቀሪውን ገንዘብ ለማስመለስ በተበዳሪው ላይ ክስ የመመስረትም ሆነ
                  የተለያዩ አማራጮችን በመጠቀም ገንዘቡን የማስመለስ መብቱ በሕግ የተጠበቀ ነው፡፡
                </Text>
                <Text style={ReportStyle.text_normal}>
                  6. በዚህ የመያዣ ንብረት ላይ ያገባኛል ፣ ይመለከተኛል የሚል ማንኛውም 3ተኛ ወገን የሌለ መሆኑን
                  እያረጋገጥን ወደፊት የሚመጣ ቢሆን እንኳ እኛ ዋስና ተበዳሪ በራሳችን ወጪ ተከራክረን ለመመለስ
                  ግዴታ ገብተናል፡፡
                </Text>

                <Text style={ReportStyle.text_normal}>
                  7. ተበዳሪው በመያዣነት ያቀረበው ቤት አበዳሪው በሚመርጠው ኢንሹራንስ ድርጅት የመድን
                  ሽፋን/ኢንሹራንስ/ መግዛትና የመድህን ሽፋኑ ጊዜ ከማብቃቱ በፊት ማደስ ይኖርበታል፡፡ የመድን ሽፋን
                  ጊዜ ካበቃ በኋላ ተበዳሪው የመድን ሽፋኑን ማደስ ካልቻለ አበዳሪ ከተበዳሪው ቁጠባ ላይ ተቀኛሽ
                  አድርጎ የመድን ዋስትናው እንዲታደስ ሊያደርግ ይችላል፡፡ 
                </Text>

                <Text style={ReportStyle.text_normal}>
                  8. አበዳሪ ተበዳሪው ከላይ በተራ ቁጥር 4 ስር በተገለጸው አግባብ የተበደሩትን ገንዘብ በወቅቱ
                  መክፈል አቅቷቸው ለተከታታይ 2(ሁለት) ወራት ቢያቋርጡ ቤቱ አበዳሪው ሽጦ ገንዘቡን
                  ለመውሰድና የቀረ ተመላሽ ገንዘብ የሚኖር ቢሆን ለተበዳሪው ለመመለስ ስምምነት አድርገናል፡፡
                </Text>



                 <Text style={ReportStyle.text_normal}>
                  9. ይህ የመያዣ ውል በቀን <TextBoldUnderlined text={etcurrdate} />{' '}
                  በግራና ቀኛችን አካል የተፈጸመ የብድር ውል ስምምነት አካል እንዲሆን ስምምነት አድርገናል፡፡
                </Text>
                <Text style={ReportStyle.text_normal}>
                  10. እኔም የተበዳሪው ባል/ሚስት በብድር ውሉና በዚህ የመያዣ ውል የተስማማሁና ኃላፊነቱን
                  ለመውሰድ የፈቀድኩ መሆኑን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                </Text>
                
                <Text style={ReportStyle.text_normal}>
                  ይህ ውል በፍ/ብ/ሥ/ሥ/ሕ/ቁ 1723/2005 መሰረት በሕግ ፊት የጸና ነው
                </Text>
              </View>

              <View
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View>
                  <Text style={ReportStyle.text_normal}> የአበዳሪ ስምና ፊርማ </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                </View>
                <View>
                  <Text style={ReportStyle.text_normal}> የዋስ ስምና ፊርማ </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                </View>
                <View>
                  <Text style={ReportStyle.text_normal}> የተበዳሪ ስምና ፊርማ </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View>
                  <Text style={ReportStyle.text_normal}>
                    የዋስ/ሚስት/ባል ስምና ፊርማ
                  </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                </View>
                <View>
                  <Text style={ReportStyle.text_normal}> የምስክሮች ስምና ፊርማ </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                </View>
                <View>
                  <Text style={ReportStyle.text_normal}>
                    የተበዳሪ ባል/ሚስት ስምና ፊርማ
                  </Text>
                  <Text style={ReportStyle.text_normal}>
                    ____________________________
                  </Text>
                </View>
              </View>
            </>
          ) : (
            <></>
          )}
          <View
            style={{
              textAlign: 'right',
              position: 'absolute',
              bottom: 30,
              right: 35,
              fontSize: 10,
            }}
          >
            <Text style={ReportStyle.text_normal}>
              ውሉ ያዘጋጀው፦
              <TextBoldUnderlined text='______________________________' />
            </Text>
            <Text style={ReportStyle.text_normal}>ፊርማ: ______________ </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

AmHomeGuarantee.propTypes = {
  loan: PropTypes.shape({
    customer: PropTypes.shape({
      amDisplayName: PropTypes.string.isRequired,
    }).isRequired,
    monthly_pay: PropTypes.number.isRequired,
    customer_amDisplayName: PropTypes.string.isRequired,
  }).isRequired,
  home: PropTypes.shape({
    homegp: PropTypes.string,
    uniquenum: PropTypes.string.isRequired,
    givenDate: PropTypes.string.isRequired,
    houseno: PropTypes.string.isRequired,
  }).isRequired,
};

export default AmHomeGuarantee;
