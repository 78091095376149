import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Form, Image } from 'react-bootstrap';
import AmigosLogo from '../Images/new.PNG';
import NewFormInput from '../Role_Officer/NewFormInput';
import { useDispatch, useSelector } from 'react-redux';

import axiosInstance from '../axiosInstance';
import { setTargetLoan } from '../Common/redux/systemLookups';
import PropTypes from 'prop-types';
import { FaPrint } from 'react-icons/fa';
function RequestForm({ successnotify, errornotify, targetLoan, targetClient }) {
  const curdate = new Date();
  const { serverIP } = useSelector((store) => store.allsettings);
  const printRef = useRef();
  const dispatch = useDispatch();

  // manipulated
  const [approvedPrincipalDisbursed, setapprovedPrincipalDisbursed] =
    useState(0);
  const [annualInterestRate, setAnnualInterestRate] = useState(0);
  const [numberOfRepayments, setNumberOfRepayments] = useState(0);

  const [amount_compulsary, setAmount_compulsary] = useState(0);
  const [amount_loansaving, setAmount_loansaving] = useState(0);
  const [amount_share, setAmount_share] = useState(0);
  const [minimumSaving, setMinimumSaving] = useState(0);

  // cbo
  const [infree_sch, setinfree_sch] = useState(0);

  useEffect(() => {
    getLoan();
  }, []);

  const getLoan = () => {
    axiosInstance
      .get(`${serverIP}/loan/loans/${targetLoan.id}`)
      .then((res) => {
        setAmount_compulsary(res.data.amount_compulsary);
        setAmount_loansaving(res.data.amount_loansaving);
        setAmount_share(res.data.amount_share);
        setapprovedPrincipalDisbursed(res.data.approvedPrincipalDisbursed);
        setNumberOfRepayments(res.data.numberOfRepayments);
        setAnnualInterestRate(res.data.annualInterestRate);
        setinfree_sch(res.data?.infree_sch);
        setMinimumSaving(res.data?.minimumSaving);

        dispatch(setTargetLoan({ targetLoan: res.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveLoan = () => {
    axiosInstance
      .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
        minimumSaving: minimumSaving,
        approvedPrincipalDisbursed: approvedPrincipalDisbursed,
      })
      .then((res) => {
        successnotify('የብድር መረጃ ተስተካክሏል!');
        getLoan();
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        errornotify('የብድር መረጃ አልተስተካከለም');
      });
  };

  const handlePrint = () => {
    const printContents = printRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  return (
    <div className='p-2 bg-gray-100 min-h-screen'>
      <div className='bg-white shadow-md rounded-lg overflow-hidden p-12'>
        {/* Header */}
        {/* <div className='flex w-full p-2'>
          <Image className='h-24 w-3/12' src={AmigosLogo} />
          <div className='flex flex-col w-8/12 justify-center items-center'>
            <div className='text-xl font-bold text-orange-500'>
              አሚጎስ የገንዘብ ቁጠባና ብድር ተቋም
            </div>
            <div className='text-xl font-bold text-orange-500'>
              የብድር መጠየቅያ ቅጽ
            </div>
          </div>
          <Image className='h-24 w-3/12' src={AmigosLogo} />
        </div> */}

        {/* Data Section */}
        <div> {targetLoan.sc_of_sc} </div>
        <div className='flex w-full gap-2 p-2'>

          <div className='w-2/3' ref={printRef}>
            <div className='flex w-full p-2'>
              <Image className='h-24 w-3/12' src={AmigosLogo} />
              <div className='flex flex-col w-6/12 justify-center items-center'>
                <div className='text-xl font-bold text-orange-500'>
                  አሚጎስ የገንዘብ ቁጠባና ብድር ተቋም
                </div>
                <div className='text-xl font-bold text-orange-500'>
                  የብድር መጠየቅያ ቅጽ
                </div>
              </div>
              <Image className='h-24 w-3/12' src={AmigosLogo} />
            </div>

            <NewFormInput
              value={targetClient.amDisplayName}
              disabled={true}
              label='ሙሉስም'
            />
            <NewFormInput
              value={targetClient.mobileNo}
              disabled={true}
              label='አድራሻ /ስልክ '
            />
            <NewFormInput
              value={targetClient.memberSince}
              disabled={true}
              label='አባል የሆኑበት ቀን (DD/MM/YY)'
            />
            <NewFormInput
              placeh='ጥያቄው የቀረበበት ቀን (DD/MM/YY)'
              disabled={true}
              value={curdate.toDateString()}
              label='ጥያቄው የቀረበበት ቀን (DD/MM/YY)'
            />
            <NewFormInput
              label='መለያ ቁጥር'
              disabled={true}
              value={targetClient.entityExternalId}
            />

            <NewFormInput
              value={amount_compulsary?.toLocaleString()}
              disabled={true}
              label='ያላቸው የመደበኛ ቁጠባ መጠን '
            />
            <NewFormInput
              value={amount_loansaving?.toLocaleString()}
              disabled={true}
              label='ያላቸው የብድር ቁጠባ መጠን'
            />
            <NewFormInput
              value={amount_share?.toLocaleString()}
              disabled={true}
              label='ያላቸው የአክስዮን መጠን'
            />

            <NewFormInput
              value={approvedPrincipalDisbursed?.toLocaleString()}
              disabled={true}
              label='የብድር መጠን'
            />

            <NewFormInput
              value={numberOfRepayments}
              disabled={true}
              label='የብድር መመለሻ ግዜ (ወር)'
            />

            {!targetLoan.isInterestFree && (
              <>
                <NewFormInput
                  value={annualInterestRate}
                  disabled={true}
                  label='የብድር ወለድ በ%'
                />
              </>
            )}

            <NewFormInput
              disabled={true}
              value={targetLoan?.prcntLifeInsurance}
              label='የሂወት መድህን ክፍያ %'
            />

            {!targetLoan.isInterestFree && (
              <>
                <NewFormInput
                  disabled={true}
                  value={(
                    targetLoan.flatServiceCharge + targetLoan.flatLifeInsurance
                  )?.toLocaleString()}
                  label='የአግልግሎት እና ኢንሹራንስ ክፍያ'
                />

                <NewFormInput
                  disabled={true}
                  value={targetLoan?.new_principal?.toLocaleString()}
                  label='ዋናው ብድር(principal)'
                />
              </>
            )}

            {!targetLoan.isInterestFree && (
              <>
                <NewFormInput
                  label='ወርሃዊ ክፍያ'
                  value={targetLoan.monthly_pay?.toLocaleString()}
                  disabled={true}
                />
              </>
            )}

 {/* <NewFormInput
              disabled={true}
              value={targetLoan?.prcntLifeInsurance}
              label='የብድር ሂደት የሚጀመርበት ቀን'
            /> */}


<div className='flex w-full mt-2 p-2'>
          <div className='w-1/3'>
            <div>የውስጥ ኦዲት ክፍል </div>
            <div>__________________________</div>
          </div>
          <div className='w-1/3'>
            <div>ቅጹን ያስሞላው/ችው </div>
            <div>__________________________</div>
          </div>
          <div className='w-1/3'>
            <div>ያረጋገጠው/ችው </div>
            <div>__________________________</div>
          </div>
        </div>

        <div className='flex w-full mt-2 p-2'>
          <div className='w-1/3'>
            <div> </div>
            <div></div>
          </div>
          <div className='w-1/3'>
            <div>ያጸደቀው/ችው </div>
            <div>__________________________</div>
          </div>
          <div className='w-1/3'>
            <div>የብድር ጠያቂው ስምና ፊርማ </div>
            <div>__________________________</div>
          </div>
        </div>
        
        


          </div>
          {/* Values to update Start  */}
          <div className='w-1/3 flex flex-col p-4'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label> ለብድር መቀነሻ የሚሆን ቁጠባ </Form.Label>
              <Form.Control
                value={minimumSaving}
                onChange={(e) => setMinimumSaving(e.target.value)}
                type='number'
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label> ተበዳሪ እጅ ላይ የሚደርስ መጠን </Form.Label>
              <Form.Control
                value={approvedPrincipalDisbursed}
                onChange={(e) => setapprovedPrincipalDisbursed(e.target.value)}
                type='number'
              />
            </Form.Group>

            <Button onClick={saveLoan}> Update </Button>

            <Button
              onClick={handlePrint}
              className='mt-3 flex gap-3 justify-center items-center bg-orange-300'
            >
              <FaPrint /> <span>Print</span>
            </Button>
          </div>
          {/* Values to update End  */}

        
        </div>

        {targetLoan.isInterestFree && (
          <div>
            <Alert className='flex justify-center text-bold bg-white'>
              ከወለድ ነጻ
            </Alert>
            <NewFormInput
              disabled={true}
              value={targetLoan?.Savings_for_loan_deduction?.toLocaleString()}
              label='ለብድር መቀነሻ የሚሆን ቁጠባ'
            />
            <NewFormInput
              disabled={true}
              value={targetLoan?.new_principal?.toLocaleString()}
              label='ዋናው ብድር(principal)'
            />
            <NewFormInput
              value={infree_sch}
              onChange={(e) => setinfree_sch(e.target.value)}
              label=' ለብድር የሚከፈል የአግልግሎት ክፍያ'
            />
            <NewFormInput
              disabled={true}
              value={(
                targetLoan.flat_interest_or_sch -
                targetLoan.flat_interest_to_client
              )?.toLocaleString()}
              label='የብድር አገልግሎት ክፍያ  '
            />
            <NewFormInput
              disabled={true}
              value={(
                targetLoan.new_principal +
                targetLoan.flat_interest_or_sch -
                targetLoan.flat_interest_to_client
              )?.toLocaleString()}
              label='አጠቃላይ ብድር አገልግሎትን ጨምሮ '
            />
            <NewFormInput
              disabled={true}
              value={(
                (targetLoan.new_principal +
                  targetLoan.flat_interest_or_sch -
                  targetLoan.flat_interest_to_client) /
                targetLoan.numberOfRepayments
              )?.toLocaleString()}
              label='ወርሃዊ ክፍያ '
            />
          </div>
        )}

       
      </div>
    </div>
  );
}

RequestForm.propTypes = {
  successnotify: PropTypes.func.isRequired,
  errornotify: PropTypes.func.isRequired,
  targetLoan: PropTypes.func,
  targetClient: PropTypes.func,
};

export default RequestForm;
