import React from 'react';
import { useSelector } from 'react-redux';
import AdminHome from '../Role_Admin/AdminHome';
import OfficerHome from '../Role_Officer/OfficerHome';
import TeamLeaderHome from '../Role_TeamLeader/TeamLeaderHome';
import AuditorHome from '../Role_Auditor/AuditorHome';
import SalesHome from '../Role_Sales/SalesHome';

const Home = () => {
  const { data } = useSelector((store) => store.customer);

  return (
    <React.Fragment>
      <>
        {data.groups[0] === 'ROLE_ADMIN' && <AdminHome />}
        {data.groups[0] === 'ROLE_OFFICER' && <OfficerHome />}
        {data.groups[0] === 'ROLE_TEAM_LEADER' && <TeamLeaderHome />}
        {data.groups[0] === 'ROLE_AUDITOR' && <AuditorHome />}
        {data.groups[0] === 'ROLE_SALES' && <SalesHome />}
      </>
    </React.Fragment>
  );
};

export default Home;
