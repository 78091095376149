import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

function TrcSrting(props) {
  return (
    <View
      style={{
        backgroundColor: props.bgcolor,
        width: props.width,
        display: 'flex',
        textAlign: 'center',
      }}
    >
      <View
        style={{
          ...props.style,
          fontFamily: 'ebrimanormal',
          borderTopWidth: props.borders[1],
          borderBottomWidth: props.borders[3],
          borderRightWidth: props.borders[5],
          borderLeftWidth: props.borders[7],
          fontSize: 9,
        }}
      >
        <Text>{props.thekey}</Text>
        <Text>{props.val}</Text>
      </View>
    </View>
  );
}

TrcSrting.propTypes = {
  bgcolor: PropTypes.string,
  width: PropTypes.string,
  style: PropTypes.object,
  borders: PropTypes.arrayOf(PropTypes.number),
  thekey: PropTypes.string,
  val: PropTypes.string,
};

export default TrcSrting;
