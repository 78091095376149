import React from 'react';
import PropTypes from 'prop-types';

function NewFormInput({ label, placeh, value, onChange, disabled }) {
  return (
    <div className='flex w-full mb-1'>
      <div className='flex w-1/2 justify-end items-center pr-2'>{label}</div>
      <div className='flex w-1/2'>
        <input
          className='form-control'
          value={value}
          placeholder={placeh}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
NewFormInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeh: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default NewFormInput;
