import React, { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  ListGroup,
  Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import axiosInstance from '../axiosInstance';
import { FaUserAlt } from 'react-icons/fa';
import { setTargetClient } from '../Common/redux/systemLookups';

function OfficerHomeNew() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);
  const [t] = useTranslation('global');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [clientDetail, setClientDetail] = useState({});

  const [externalId, setExternalId] = useState(0);
  const [djangoUser, setDjangoUser] = useState([]);
  const [userLoans, setUserLoans] = useState([]);

  const [clientInitial, setClientInitial] = useState([]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [isInterestFree, setIsInterestFree] = useState(false);

  const [targetRecord, setTargetRecord] = useState({});

  const [allSavings, setAllSavings] = useState({});

  const getClientByExternalId = async (externalId) => {
    console.log(externalId);
    setIsLoading(true);
    try {
      const res_ci = await axiosInstance.get(
        `${serverIP}/fineract/clientbyexternalid?entityExternalId=${Number(externalId)}`
      );
      setClientInitial(res_ci.data);
      console.log('setClientInitial');
      console.log(res_ci.data);

      const res_lps = await axiosInstance.get(
        `${serverIP}/customer/clientbyexternalid/${externalId}`
      );
      setDjangoUser(res_lps?.data);
      setUserLoans(res_lps?.data[0]?.loans);

      const res_ud = await axiosInstance.get(
        `${serverIP}/fineract/userdetail?entityAccountNo=${res_ci.data[0]?.entityId}`
      );
      setClientDetail(res_ud.data);
      console.log('setClientDetail');

      console.log(res_ud.data);

      const res_as = await axiosInstance.get(
        `${serverIP}/fineract/allsaving?entityAccountNo=${res_ci.data[0]?.entityId}`
      );
      setAllSavings(res_as.data);
      console.log('setAllSavings');
      console.log(res_as.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  function formatDateForDjango(year, month, date) {
    const formattedDate = new Date(year, month - 1, date);
    return formattedDate.toISOString().split('T')[0];
  }

  const copyCustomer = async () => {
    console.log('copying customer');
    await axiosInstance
      .post(`${serverIP}/customer/customers`, {
        entityAccountNo: clientDetail.accountNo,
        entityExternalId: clientDetail.externalId,

        activationDate: formatDateForDjango(
          clientDetail?.activationDate[0],
          clientDetail?.activationDate[1],
          clientDetail?.activationDate[2]
        ),
        memberSince: formatDateForDjango(
          clientDetail?.activationDate[0],
          clientDetail?.activationDate[1],
          clientDetail?.activationDate[2]
        ),
        active: clientDetail.active,
        displayName: clientDetail.displayName,
        amDisplayName: clientDetail.displayName,
        firstname: clientDetail.firstname,
        amFirstname: clientDetail.firstname,
        middlename: clientDetail.middlename,
        amMiddlename: clientDetail.middlename,
        lastname: clientDetail.lastname,
        amLastname: clientDetail.lastname,
        gender: clientDetail.gender?.name,
        mobileNo: clientDetail.mobileNo,
        address: 'Addis Ababa',
        amAddress: 'Addis Ababa',
        dateOfBirth: formatDateForDjango(
          clientDetail?.dateOfBirth[0],
          clientDetail?.dateOfBirth[1],
          clientDetail?.dateOfBirth[2]
        ),
        isMarried: false,
        imageId: clientDetail.imageId,
        imagePresent: clientDetail.imagePresent,

        regionId: 1,
        zoneId: 3,
        woredaId: 291,
      })
      .then((res) => {
        // searchUserOn_Django();
        getClientByExternalId(externalId);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(' catch');
        console.log(err);
      });
  };

  const redirectPage = async (custId, loanId) => {
    try {
      await getCustomer(custId);
      navigate(`/loanonofficer/${custId}/${loanId}`);
    } catch (error) {
      console.error('Error in redirecting:', error);
    }
  };

  const getCustomer = async (custId) => {
    try {
      const res = await axiosInstance.get(
        `${serverIP}/customer/customers/${custId}`
      );
      dispatch(setTargetClient({ client: res.data }));
      console.log('Customer data');
      console.log(res.data);
      setUserLoans(res?.data?.loans);
    } catch (err) {
      console.error('Error fetching customer:', err);
    }
  };

  // "officerid": data.id,
  // "submittedBy": data.id,
  // "entityAccountNo": djangoUser[0].entityAccountNo

  const saveNewLoan = () => {
    console.log('data');
    console.log(data);

    axiosInstance
      .post(`${serverIP}/loan/loans`, {
        loanId: 1000,
        totalSaving: 1000,
        totalShares: 10000,
        customer: djangoUser[0].id,
        officerid: data.id,
        submittedBy: data.id,
        isInterestFree: isInterestFree,
      })
      .then((res) => {
        console.log(res.data);
        getCustomer(djangoUser[0].id);
        setShowAddModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteLoanPlan = () => {
    axiosInstance
      .delete(`${serverIP}/loan/loans/${targetRecord.id}`)
      .then(() => {
        getCustomer(djangoUser[0].id);
        setModalDelete(false);
        console.log('loans are fetched');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (isLoading) {
    return (
      <div className='h-screen flex items-center justify-center bg-gray-100'>
        <div className='flex flex-col gap-3 items-center justify-center'>
          <PulseLoader size={30} color='orange' loading='true' />
          <div style={{ color: '#FFA500' }}>Loading ...</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* modal start  */}

      {/* add modal Start  */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> አዲስ የብድር እቅድ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className='form-check-input'
            type='checkbox'
            onChange={(e) => {
              setIsInterestFree(e.target.checked);
            }}
          />
          <label className='form-check-label'> ከወለድ ነጻ </label>
        </Modal.Body>
        <Modal.Footer className='flex justify-end'>
          <Button
            type='submit'
            onClick={() => {
              saveNewLoan();
            }}
          >
            SAVE
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add modal end  */}

      {/* add modal Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title> ብድር ፕላን አጥፋ </Modal.Title>
        </Modal.Header>
        <Modal.Body>የብድር ፕላን አጥፋ</Modal.Body>
        <Modal.Footer className='flex justify-end'>
          <Button
            type='submit'
            onClick={() => {
              deleteLoanPlan();
            }}
          >
            SAVE
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add modal end  */}

      {/* modal end  */}

      <div className='row'>
        <div className='col-sm-12'>
          <InputGroup className='mb-3'>
            <Form.Control
              placeholder={t('applicant.clientID')}
              value={externalId}
              onChange={(ev) => {
                setExternalId(ev.target.value);
              }}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  getClientByExternalId(externalId);
                }
              }}
            />
            <Button
              onClick={() => getClientByExternalId(externalId)}
              variant='outline-secondary'
              id='button-addon2'
            >
              {t('applicant.search')}
            </Button>
          </InputGroup>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6'>
          {(clientInitial.length > 0 ||
            Object.keys(clientInitial).length > 0) &&
          (Object.keys(clientDetail).length > 0 || clientDetail.length > 0) ? (
            <>
              <Card>
                <Card.Header className='flex flex-row items-center gap-2'>
                  <FaUserAlt /> የተበዳሪ መረጃ
                </Card.Header>
                <ListGroup>
                  <ListGroup.Item>{`ሙሉ ስም: ${clientDetail.displayName}`}</ListGroup.Item>
                  <ListGroup.Item>{`${t('applicant.entityAccountNo')}: ${clientDetail.accountNo}`}</ListGroup.Item>
                  <ListGroup.Item>{`${t('applicant.account')}: ${clientDetail.externalId}`}</ListGroup.Item>
                  <ListGroup.Item>{`${t('applicant.gender')}: ${clientDetail.gender?.name}`}</ListGroup.Item>
                  <ListGroup.Item>{`${t('applicant.mobileNo')}: ${clientDetail.mobileNo}`}</ListGroup.Item>
                  <ListGroup.Item>
                    {t('applicant.maritalStatus')}:
                    {clientDetail.isMarried
                      ? ' ' + t('review.Married')
                      : ' ' + t('review.Unmarried')}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {/* activationDate: {amisisUser.activationDate} */}
                    {`${t('applicant.activationDate')}: 
                        ${formatDateForDjango(
                          clientDetail?.activationDate[0],
                          clientDetail?.activationDate[1],
                          clientDetail?.activationDate[2]
                        )}`}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {`${t('applicant.dateOfBirth')}: 
                        ${formatDateForDjango(
                          clientDetail?.dateOfBirth[0],
                          clientDetail?.dateOfBirth[1],
                          clientDetail?.dateOfBirth[2]
                        )}`}
                  </ListGroup.Item>
                </ListGroup>
                <ListGroup></ListGroup>
                <Card.Footer>
                  {djangoUser.length > 0 ? (
                    <Alert> የደንበኛ መረጃ ተገልብጧል </Alert>
                  ) : (
                    <Button onClick={copyCustomer}>የተብዳሪ መረጃ ይገልብጡ</Button>
                  )}
                </Card.Footer>
              </Card>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className='col-sm-6'>
          {djangoUser?.length > 0 && (
            <ListGroup as='ul'>
              <ListGroup.Item as='li' active>
                LPS ላይ ያሉ ብድሮች
              </ListGroup.Item>
              {userLoans?.length > 0 &&
                userLoans.map((ln) => {
                  return ln?.submittedBy?.id === data.id ||
                    ln?.officerid === data.id ? (
                    <ListGroup.Item as='li' className='flex justify-between'>
                      <div className='flex gap-2'>
                        <div>{ln.approvedPrincipal?.toLocaleString()}</div>
                        {ln.isInterestFree ? (
                          <Badge>Interest Free</Badge>
                        ) : (
                          <span>Normal</span>
                        )}
                      </div>
                      <div className='flex gap-2'>
                        <Link
                          onClick={() => {
                            redirectPage(djangoUser[0]?.id, ln.id);
                          }}
                          className='font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl'
                        >
                          ብድር
                        </Link>
                        <Button
                          className='btn btn-sm btn-danger'
                          onClick={() => {
                            setModalDelete(true);
                            setTargetRecord(ln);
                          }}
                        >
                          {' '}
                          አጥፋ{' '}
                        </Button>
                      </div>
                    </ListGroup.Item>
                  ) : (
                    <ListGroup.Item as='li' className='flex justify-between'>
                      <div className='flex gap-2'>
                        <div>{ln.approvedPrincipal?.toLocaleString()}</div>
                        {ln.isInterestFree ? (
                          <Badge>Interest Free</Badge>
                        ) : (
                          <span>Normal</span>
                        )}
                      </div>
                      <div className='flex gap-2'>በሌላ ኦፊሰር የተጀመረ</div>
                    </ListGroup.Item>
                  );
                })}
              <ListGroup.Item as='li' className='flex justify-end'>
                <Button onClick={() => setShowAddModal(true)}>
                  ADD NEW PLAN
                </Button>
              </ListGroup.Item>
            </ListGroup>
          )}

          {Object.keys(allSavings).length > 0 && (
            <ListGroup as='ul' className='mt-3'>
              <ListGroup.Item as='li'>AMISIS ላይ ያሉ ብድሮች</ListGroup.Item>
            </ListGroup>
          )}
        </div>
      </div>
    </div>
  );
}

export default OfficerHomeNew;
