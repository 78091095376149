import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FaPencilAlt, FaRing, FaTrashAlt } from 'react-icons/fa';
import { Alert, Button, Modal, Table } from 'react-bootstrap';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import SpauseSchema from '../Schema/SpauseSchema';
import InputField from '../ReusableFormiks/InputField';
import { setTargetClient } from '../Common/redux/systemLookups';

function Spause({ successnotify, errornotify }) {
  const { data } = useSelector((store) => store.customer);
  const { serverIP } = useSelector((store) => store.allsettings);
  const { targetLoan, targetClient } = useSelector(
    (store) => store.systemLookups
  );
  const dispatch = useDispatch();
  const { customerId } = useParams();

  const [regions, setRegions] = useState([]);
  const [zones, setZones] = useState([]);
  const [woredas, setWoredas] = useState([]);

  const [editingRecord, setEditingRecord] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [modalDelete, setDeleteModal] = useState(false);
  const [targetRecord, setTargetRecord] = useState({});

  const [show, setShow] = useState(false);
  const baseStyle =
    'form-control block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-1 px-1 text-base';

  useEffect(() => {
    getAllLookupFiles();
  }, []);

  const getAllLookupFiles = async () => {
    // 'regions',
    let res_regions = await axiosInstance.get(
      `${serverIP}/regions/regions/activeregions`
    );
    setRegions(res_regions.data);

    // 'regions',
    let res_zones = await axiosInstance.get(`${serverIP}/zones/zone`);
    setZones(res_zones.data);
    // 'regions',

    let res_woredas = await axiosInstance.get(`${serverIP}/woredas/woreda`);
    setWoredas(res_woredas.data);
  };

  const initialValues = {
    amFullName: '',
    regionId: '',
    zoneId: '',
    woredaId: '',
    houseNum: '',
    gender: '',
    mobileNo: '',
    idNum: '',
    monthlyIncome: '',
  };

  const handleSubmit = (values, { resetForm }) => {
    try {
      if (editingRecord) {
        axiosInstance
          .patch(`${serverIP}/spause/detail/${targetRecord.id}`, {
            amFullName: values.amFullName,
            regionId: values.regionId,
            zoneId: values.zoneId,
            woredaId: values.woredaId,
            houseNum: values.houseNum,
            gender: values.gender,
            mobileNo: values.mobileNo,
            idNum: values.idNum,
            monthlyIncome: values.monthlyIncome,
          })
          .then(() => {
            successnotify();
            setShowAddModal(false);
          })
          .catch((err) => {
            errornotify();
            console.log(err);
          });
        setEditingRecord(false);
        setShowAddModal(false);
      } else {
        axiosInstance
          .post(`${serverIP}/spause/detail`, {
            amFullName: values.amFullName,
            regionId: values.regionId,
            zoneId: values.zoneId,
            woredaId: values.woredaId,
            houseNum: values.houseNum,
            gender: values.gender,
            mobileNo: values.mobileNo,
            parent: customerId,
            idNum: values.idNum,
            monthlyIncome: values.monthlyIncome,
          })
          .then(() => {
            successnotify();
            setShowAddModal(false);
          })
          .catch((err) => {
            errornotify();
            console.log(err);
          });
        setShowAddModal(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowAddModal(false);
      setEditingRecord(false);
      resetForm();
      getCustomer();
    }
  };

  const getCustomer = async () => {
    try {
      const response = await axiosInstance.get(
        `${serverIP}/customer/customers/${customerId}`
      );
      dispatch(setTargetClient({ client: response.data }));
    } catch (err) {
      console.error('Error fetching customer:', err);
    }
  };

  const deleteRecord = () => {
    axiosInstance
      .delete(`${serverIP}/spause/detail/${targetRecord.id}`)
      .then(() => {
        setDeleteModal(false);
        getCustomer();
        successnotify('deleted successfuly');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Alert className='px-3 flex flex-row justify-between py-1 items-center'>
        <div style={{ fontSize: 20, fontWeight: 'bold', color: 'GrayText' }}>
          የትዳር አጋር መረጃ{' '}
        </div>
        {data.groups[0] === 'ROLE_OFFICER' && (
          <Button
            onClick={() => {
              setShowAddModal(true);
              setEditingRecord(false);
            }}
            className='flex flex-row items-center gap-2'
            disabled={
              targetLoan?.loanstatus_id !== 1 ||
              targetClient?.spausedetail?.length > 0
            }
          >
            <FaRing /> ጨምር
          </Button>
        )}
      </Alert>

      {/* modal start  */}

      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setDeleteModal(false)}>
        <Modal.Header style={{ color: 'red' }} closeButton>
          <Modal.Title color='red'> አጥፋ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wnt to delete{' '}
          <strong> {targetRecord.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} variant='danger'>
            አጥፋ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}

      {/* Modal Show Start  */}
      <Modal size='lg' show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {targetRecord.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={serverIP + targetRecord.fileUrl}
            style={{ width: '100%', height: '100%' }}
          />
        </Modal.Body>
      </Modal>
      {/* Modal Show End  */}

      {/* add modal Start  */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> የትዳር አጋር መረጃ </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={editingRecord || initialValues}
          validationSchema={SpauseSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <Modal.Body>
                <InputField
                  label='ሙሉ ስም '
                  name='amFullName'
                  placeholder='ሙሉ ስም'
                  touched={touched.amFullName}
                  errors={errors.amFullName}
                />
                {/* address start  */}
                <div className='mb-1'>
                  <label className='block text-sm font-medium text-gray-700 mb-1'>
                    Region
                  </label>
                  <Field
                    as='select'
                    name='regionId'
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue('zoneId', '');
                      setFieldValue('woredaId', '');
                    }}
                    className={baseStyle}
                  >
                    <option value=''>Select Region</option>
                    {regions.map((region) => (
                      <option key={region.id} value={region.id}>
                        {region.amDescription}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    style={{ color: 'red' }}
                    name='regionId'
                    component='div'
                  />
                </div>

                <div className='mb-1'>
                  <label className='block text-sm font-medium text-gray-700 mb-1'>
                    Zone
                  </label>
                  <Field
                    as='select'
                    name='zoneId'
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue('woredaId', '');
                    }}
                    disabled={!values.regionId}
                    className={` ${baseStyle} ${!values.regionId ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                  >
                    <option value=''>Select Zone</option>
                    {zones
                      .filter((zone) => zone.parent == values.regionId)
                      .map((zone) => (
                        <option key={zone.id} value={zone.id}>
                          {zone.amDescription}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    style={{ color: 'red' }}
                    name='zoneId'
                    component='div'
                  />
                </div>

                <div className='mb-1'>
                  <label className='block text-sm font-medium text-gray-700 mb-1'>
                    Woreda
                  </label>
                  <Field
                    as='select'
                    name='woredaId'
                    disabled={!values.zoneId}
                    className={`${baseStyle} ${!values.zone ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                  >
                    <option value=''>Select Woreda</option>
                    {woredas
                      .filter((woreda) => woreda.parent == values.zoneId)
                      .map((woreda) => (
                        <option key={woreda.id} value={woreda.id}>
                          {woreda.amDescription}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    style={{ color: 'red' }}
                    name='woredaId'
                    component='div'
                  />
                </div>
                {/* address end   */}

                <InputField
                  label='የቤት ቁጥር '
                  name='houseNum'
                  placeholder='የቤት ቁጥር'
                  touched={touched.houseNum}
                  errors={errors.houseNum}
                />

                <div className='flex gap-2'>
                  <InputField
                    label='ስልክ ቁጥር'
                    name='mobileNo'
                    placeholder='ስልክ ቁጥር'
                    touched={touched.mobileNo}
                    errors={errors.mobileNo}
                    className='w-1/2'
                  />
                  <InputField
                    label='ፆታ '
                    name='gender'
                    placeholder='ፆታ'
                    touched={touched.gender}
                    errors={errors.gender}
                    className='w-1/2'
                  />
                </div>

                <div className='flex gap-2'>
                  <InputField
                    label='የመታወቅያ ቁጥር'
                    name='idNum'
                    placeholder='የመታወቅያ ቁጥር'
                    touched={touched.idNum}
                    errors={errors.idNum}
                    className='w-1/2'
                  />
                  <InputField
                    label='ወርሃዊ ገቢ '
                    name='monthlyIncome'
                    placeholder='ወርሃዊ ገቢ'
                    touched={touched.monthlyIncome}
                    errors={errors.monthlyIncome}
                    className='w-1/2'
                  />
                </div>
              </Modal.Body>
              <Modal.Footer className='flex justify-end'>
                <Button
                  type='submit'
                  variant={editingRecord ? 'warning' : 'primary'}
                >
                  {editingRecord ? 'Update' : 'Save'}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      {/* add modal end  */}

      {/* modal end  */}

      {/* accordion end  */}
      <div className='row'>
        <div className='col-sm-12'>
          {targetClient.spausedetail.length > 0 && (
            <Table striped bordered hover style={{ marginTop: 10 }}>
              <thead>
                <tr>
                  <th>ሙሉስም </th>
                  <th>ሞባይል</th>
                  <th>አድራሻ</th>
                  <th>ሁኔታ</th>
                </tr>
              </thead>
              <tbody>
                {targetClient.spausedetail.map((sp) => {
                  return (
                    <tr key={sp.id}>
                      <td> {sp.amFullName} </td>
                      <td> {sp.mobileNo} </td>
                      <td>
                        {`${sp.region_txt} /  ${sp.zone_txt} /  ${sp.woreda_txt}`}
                      </td>
                      <td
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                        }}
                      >
                        <FaTrashAlt
                          onClick={() => {
                            setTargetRecord(sp);
                            setDeleteModal(true);
                          }}
                          color='red'
                        />
                        &nbsp;
                        <FaPencilAlt
                          onClick={() => {
                            setEditingRecord({
                              id: sp.id,
                              amFullName: sp.amFullName,
                              regionId: sp.regionId,
                              zoneId: sp.zoneId,
                              woredaId: sp.woredaId,
                              houseNum: sp.houseNum,
                              gender: sp.gender,
                              mobileNo: sp.mobileNo,
                              idNum: sp.idNum,
                              monthlyIncome: sp.monthlyIncome,
                            });
                            setTargetRecord(sp);
                            setShowAddModal(true);
                          }}
                          className='btn btn-sm btn-warning'
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
}

Spause.propTypes = {
  successnotify: PropTypes.func.isRequired,
  errornotify: PropTypes.func.isRequired,
};

export default Spause;
