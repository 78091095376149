import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
  ListGroup,
  Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import axiosInstance from '../axiosInstance';
import { FaUserAlt } from 'react-icons/fa';
import { setTargetClient } from '../Common/redux/systemLookups';

function LoanChecker() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);
  const [t] = useTranslation('global');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [clientDetail, setClientDetail] = useState({});

  const [externalId, setExternalId] = useState(0);
  const [djangoUser, setDjangoUser] = useState([]);
  const [userLoans, setUserLoans] = useState([]);

  const [clientInitial, setClientInitial] = useState([]);
  const [fileUrl, setFileUrl] = useState({});

  const [modalChangeOfficer, setmodalChangeOfficer] = useState(false);
  const [modalChangeSales, setmodalChangeSales] = useState(false);
  const [officers, setOfficers] = useState([]);
  const [allSales, setallSales] = useState([]);

  const [officerId, setOfficerId] = useState(0);
  const [salesId, setsalesId] = useState(0);
  const [targetLoan, setTargetLoan] = useState({});

  const [loanPlan, setLoanPlan] = useState({});

  // charges 
  const [objServiceCharge, setObjServiceCharge] = useState([]);
  const [objLifeInsurance, setObjLifeInsurance] = useState({});
  const [objTembir, setObjTembir] = useState({});


    const [loanSaving, setLoanSaving] = useState([]);
    const [voluntary, setVoluntary] = useState([]);
    const [funeral, setFuneral] = useState([]);
    const [compulsary, setCompulsary] = useState([]);
    const [timeDeposit, setTimeDeposit] = useState([]);
  
    const [loanSavingSum, setLoanSavingSum] = useState(0);
    const [voluntarySum, setVoluntarySum] = useState(0);
    const [funeralSum, setFuneralSum] = useState(0);
    const [compulsarySum, setCompulsarySum] = useState(0);
    const [timeDepositSum, setTimeDepositSum] = useState(0);

  useEffect(() => {
    getAllOfficers();
    getAllSales();
  }, []);

 useEffect(() => {
    const sum_lnsaving = loanSaving?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setLoanSavingSum(sum_lnsaving);

    const sum_voluntary = voluntary?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setVoluntarySum(sum_voluntary);

    const sum_funeral = funeral?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setFuneralSum(sum_funeral);

    const sum_compulsary = compulsary?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setCompulsarySum(sum_compulsary);

    const sum_timed = timeDeposit?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setTimeDepositSum(sum_timed);
  }, [loanSaving, voluntary, funeral, compulsary, timeDeposit]);

  const getAllOfficers = async () => {
    try {
      const res = await axiosInstance.get(`${serverIP}/lpsauth/officers`);
      setOfficers(res.data);
      console.log('officers');
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllSales = async () => {
    try {
      const res = await axiosInstance.get(`${serverIP}/lpsauth/sales`);
      setallSales(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getClientByExternalId = async (externalId) => {
    console.log(externalId);
    setIsLoading(true);
    try {
      const res_ci = await axiosInstance.get(
        `${serverIP}/fineract/clientbyexternalid?entityExternalId=${Number(externalId)}`
      );
      setClientInitial(res_ci.data);
      console.log('setClientInitial');
      console.log(res_ci.data);

      const res_lps = await axiosInstance.get(
        `${serverIP}/customer/clientbyexternalid/${externalId}`
      );
      setDjangoUser(res_lps?.data);
      setUserLoans(res_lps?.data[0]?.loans);

      const res_ud = await axiosInstance.get(
        `${serverIP}/fineract/userdetail?entityAccountNo=${res_ci.data[0]?.entityId}`
      );
      setClientDetail(res_ud.data);
      console.log('setClientDetail');
      console.log(res_ud.data);
      setFileUrl(null);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  const copyCustomer = async () => {
    console.log('copying customer');
    console.log(fileUrl);
    await axiosInstance
      .post(`${serverIP}/customer/customers`, {
        entityAccountNo: clientDetail.accountNo,
        entityExternalId: clientDetail.externalId,

        activationDate: formatDateForDjango(
          clientDetail?.activationDate[0],
          clientDetail?.activationDate[1],
          clientDetail?.activationDate[2]
        ),
        memberSince: formatDateForDjango(
          clientDetail?.activationDate[0],
          clientDetail?.activationDate[1],
          clientDetail?.activationDate[2]
        ),
        active: clientDetail.active,
        displayName: clientDetail.displayName,
        amDisplayName: clientDetail.displayName,
        firstname: clientDetail.firstname,
        amFirstname: clientDetail.firstname,
        middlename: clientDetail.middlename,
        amMiddlename: clientDetail.middlename,
        lastname: clientDetail.lastname,
        amLastname: clientDetail.lastname,
        gender: clientDetail.gender?.name,
        mobileNo: clientDetail.mobileNo,
        address: 'Addis Ababa',
        amAddress: 'Addis Ababa',
        dateOfBirth: formatDateForDjango(
          clientDetail?.dateOfBirth[0],
          clientDetail?.dateOfBirth[1],
          clientDetail?.dateOfBirth[2]
        ),
        isMarried: false,
        imageId: clientDetail.imageId,
        imagePresent: clientDetail.imagePresent,
        // fileUrl: fileUrl,
        imgBaseSixtyFour: fileUrl || null,

        regionId: 1,
        zoneId: 3,
        woredaId: 291,
      })
      .then((res) => {
        // searchUserOn_Django();
        getClientByExternalId(externalId);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(' catch');
        console.log(err);
      });
  };

  const redirectPage = async (custId, loanId) => {
    try {
      await getLoan(loanId);
      await getCustomer(custId);
      navigate(`/loanonofficer/${custId}/${loanId}`);
    } catch (error) {
      console.error('Error in redirecting:', error);
    }
  };

  const getLoan = async (loanId) => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
      console.log(res.data);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error('Error fetching loan:', err);
    }
  };

  const getCustomer = async (custId) => {
    try {
      const res = await axiosInstance.get(
        `${serverIP}/customer/customers/${custId}`
      );
      dispatch(setTargetClient({ client: res.data }));
    } catch (err) {
      console.error('Error fetching customer:', err);
    }
  };



  const changeOfficer = () => {
    axiosInstance
      .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
        officerid: officerId,
      })
      .then((res) => {
        console.log(res.data);
        setmodalChangeOfficer(false);
        getLoan(targetLoan.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeSales = () => {
    axiosInstance
      .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
        submittedBy: salesId,
      })
      .then((res) => {
        console.log(res.data);
        setmodalChangeSales(false);
        getLoan(targetLoan.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function formatDateForDjango(year, month, date) {
    const formattedDate = new Date(year, month - 1, date);
    return formattedDate.toISOString().split('T')[0];
  }

  const reInitiateLoan = async (lpsLoanId, amisisLoanId) =>{
    setIsLoading(true)

   try {
    const oldLoanPlan = await axiosInstance.get(`${serverIP}/fineract/clientloanplan?loanPlanId=${Number(amisisLoanId)}`)

    setObjServiceCharge(oldLoanPlan.data.charges?.filter((x) => x.name.toString().includes('CHA')));
    setObjLifeInsurance(oldLoanPlan.data.charges?.filter((x) => x.name.toString().includes('INSU')));
    setObjTembir(oldLoanPlan.data.charges?.filter((x) => x.name.toString().includes('TEMB')));

        console.log("lpsLoanId, amisisLoanId");
        console.log(lpsLoanId, amisisLoanId);

        console.log("oldLoanPlan")
        console.log(oldLoanPlan)

        const updated = await axiosInstance.patch(`${serverIP}/loan/loans/${lpsLoanId}`, {
          approvedPrincipal: oldLoanPlan?.data?.principal,
          // approvedPrincipalDisbursed: oldLoanPlan?.data?.principal,
          tembr: objTembir[0]?.amount,
          annualInterestRate: oldLoanPlan?.data?.annualInterestRate,
          numberOfRepayments: oldLoanPlan?.data?.numberOfRepayments,

          totalDueForPeriod: oldLoanPlan?.data?.fixedEmiAmount,

          prcntServiceCharge: objServiceCharge[0]?.percentage,
          prcntLifeInsurance: objLifeInsurance[0]?.percentage,
          flatServiceCharge: objServiceCharge[0]?.amount,
          flatLifeInsurance: objLifeInsurance[0]?.amount,
         })

        console.log("updated")
        console.log(updated)
   } catch (error) {
    console.log(error)
   }finally{
    setIsLoading(false)
    getClientByExternalId(externalId)
   }

         
          // schFromLoan:
          //   loanPlan.netDisbursalAmount !== loanPlan.approvedPrincipal
          //     ? false
          //     : true,  --- geometer
          
          // loanPurposeName: loanPlan.loanPurposeName,
          // loanProductId: loanPlan.loanProductId,
          // loanProductName: loanPlan.loanProductName,
          // totalInterestPayment: loanPlan.summary?.interestCharged,

          // amount_timedeposit: timeDepositSum,
          // amount_compulsary: compulsarySum,
          // amount_voluntary: voluntarySum,
          // amount_funeral: funeralSum,
          // amount_loansaving: loanSavingSum,

          // lastReturnDate: formatDateForDjango(
          //   loanPlan?.timeline?.expectedMaturityDate[0],
          //   loanPlan?.timeline?.expectedMaturityDate[1],
          //   loanPlan?.timeline?.expectedMaturityDate[2]
          // ),

          // expectedDisbursementDate: formatDateForDjango(
          //   loanPlan?.timeline?.expectedDisbursementDate[0],
          //   loanPlan?.timeline?.expectedDisbursementDate[1],
          //   loanPlan?.timeline?.expectedDisbursementDate[2]
          // ),
          // officerchecking: true,
    // })


  }

  const redirectToSummary = async (custId, loanId) => {
    try{
      await getLoan(loanId);
      await getCustomer(custId);
      navigate(`/loansummary/${custId}/${loanId}`);
    }catch(err){
      console.log(err)
    }
  }

//  const redirectPage = async (custId, loanId) => {
//     try {
//       await getLoan(loanId);
//       await getCustomer(custId);
//       navigate(`/loanonofficer/${custId}/${loanId}`);
//     } catch (error) {
//       console.error('Error in redirecting:', error);
//     }
//   };

  if (isLoading) {
    return (
      <div className='max-h-min flex items-center justify-center bg-gray-100'>
        <div className='flex flex-col gap-3 items-center justify-center'>
          <PulseLoader size={30} color='orange' loading='true' />
          <div style={{ color: '#FFA500' }}>Loading ...</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* modal start  */}

      {/* Change Sales Start  */}
      <Modal show={modalChangeSales} onHide={() => setmodalChangeSales(false)}>
        <Modal.Header closeButton>
          <Modal.Title> ሴልስ ይቀይሩ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            className='form-select'
            aria-label='Default select example'
            onChange={(e) => {
              console.log(e.target.value);
              setsalesId(e.target.value);
            }}
          >
            <option value=''> ሴልስ ይምረጡ </option>
            {allSales.map((sales) => {
              return (
                <option key={sales.id} value={sales.id}>
                  {sales.first_name + ' ' + sales.last_name}
                </option>
              );
            })}
          </select>
        </Modal.Body>
        <Modal.Footer className='flex justify-end'>
          <Button
            type='submit'
            onClick={() => {
              changeSales();
            }}
          >
            SAVE
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Change Sales end  */}

      {/* Change Officer Start  */}
      <Modal
        show={modalChangeOfficer}
        onHide={() => setmodalChangeOfficer(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title> ኦፊሰር ይቀይሩ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            className='form-select'
            aria-label='Default select example'
            onChange={(e) => {
              console.log(e.target.value);
              setOfficerId(e.target.value);
            }}
          >
            <option value=''> ኦፊሰር ይምረጡ </option>
            {officers.map((officer) => {
              return (
                <option key={officer.id} value={officer.id}>
                  {officer.first_name + ' ' + officer.last_name}
                </option>
              );
            })}
          </select>
        </Modal.Body>
        <Modal.Footer className='flex justify-end'>
          <Button
            type='submit'
            onClick={() => {
              changeOfficer();
            }}
          >
            SAVE
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Change Officer end  */}

    

      {/* modal end  */}

      <div className='row'>
        <div className='col-sm-12'>
          <InputGroup className='mb-3'>
            <Form.Control
              placeholder={t('applicant.clientID')}
              value={externalId}
              onChange={(ev) => {
                setExternalId(ev.target.value);
              }}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  getClientByExternalId(externalId);
                }
              }}
            />
            <Button
              onClick={() => getClientByExternalId(externalId)}
              variant='outline-secondary'
              id='button-addon2'
            >
              {t('applicant.search')}
            </Button>
          </InputGroup>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6'>
          {(clientInitial.length > 0 ||
            Object.keys(clientInitial).length > 0) &&
          (Object.keys(clientDetail).length > 0 || clientDetail.length > 0) ? (
            <>
              <Card>
                <Card.Header className='flex flex-row items-center gap-2'>
                  <FaUserAlt /> የተበዳሪ መረጃ
                </Card.Header>
                <ListGroup>
                  <ListGroup.Item>{`ሙሉ ስም: ${clientDetail.displayName}`}</ListGroup.Item>
                  <ListGroup.Item>{`${t('applicant.entityAccountNo')}: ${clientDetail.accountNo}`}</ListGroup.Item>
                  <ListGroup.Item>{`${t('applicant.account')}: ${clientDetail.externalId}`}</ListGroup.Item>
                  <ListGroup.Item>{`${t('applicant.gender')}: ${clientDetail.gender?.name}`}</ListGroup.Item>
                  <ListGroup.Item>{`${t('applicant.mobileNo')}: ${clientDetail.mobileNo}`}</ListGroup.Item>
                  <ListGroup.Item>
                    {t('applicant.maritalStatus')}:
                    {clientDetail.isMarried
                      ? ' ' + t('review.Married')
                      : ' ' + t('review.Unmarried')}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {/* activationDate: {amisisUser.activationDate} */}
                    {`${t('applicant.activationDate')}: 
                        ${formatDateForDjango(
                          clientDetail?.activationDate[0],
                          clientDetail?.activationDate[1],
                          clientDetail?.activationDate[2]
                        )}`}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {`${t('applicant.dateOfBirth')}: 
                        ${formatDateForDjango(
                          clientDetail?.dateOfBirth[0],
                          clientDetail?.dateOfBirth[1],
                          clientDetail?.dateOfBirth[2]
                        )}`}
                  </ListGroup.Item>
                </ListGroup>
                {/* <Card.Footer className='m-0'> */}
                  {djangoUser.length > 0 ? (
                    <Alert> የደንበኛ መረጃ ተገልብጧል </Alert>
                  ) : (
                    <Button onClick={copyCustomer}>የተብዳሪ መረጃ ይገልብጡ</Button>
                  )}
                {/* </Card.Footer> */}
              </Card>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className='col-sm-6'>
          {djangoUser?.length > 0 && (
            <div>
              <Alert> ብድሮች </Alert>
              {userLoans?.length > 0 &&
                userLoans.map((ln) => {
                  return (
                    <div key={ln.id} className='p-2 flex flex-col bg-slate-200 gap-2 rounded-md'>
                      <div>የብድር መጠን ፡ {ln.approvedPrincipal?.toLocaleString()} </div>
                      <div>
                      SALES:<button
                          onClick={() => {
                            setmodalChangeSales(true);
                            setTargetLoan(ln);
                          }}
                          className='font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl'
                        >
                          {ln.submitedBy_name + ' ' + ln.submitedBy_fathername}
                        </button>
                      </div>
                      <div>
                      OFFICER: <button
                          onClick={() => {
                            setmodalChangeOfficer(true);
                            setTargetLoan(ln);
                          }}
                          className='font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl'
                        >
                          {ln?.officer_name + ' ' + ln?.officer_fathername}
                        </button>
                      </div>
                      <div>submittedOnDate ፡ {ln.submittedOnDate} </div>
                      <div>expectedDisbursementDate ፡ {ln.expectedDisbursementDate} </div>
                      <div>lastReturnDate ፡ {ln.lastReturnDate} </div>
                      <div>flat_service_and_life_insurance ፡ {ln.flat_service_and_life_insurance} </div>
                      <div>monthly_pay ፡ {ln.monthly_pay} </div>
                      <div>loanId ፡ {ln.loanId} </div>
                      <div className='flex justify-between bg-slate-400 rounded-md px-3 py-1 items-center'>
                      <Link
                        onClick={() => {
                          redirectPage(djangoUser[0]?.id, ln.id);
                        }}
                        className='font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl'
                      >
                       ብድር
                      </Link>
                      
                      <Link
                        onClick={() => {
                          redirectToSummary(djangoUser[0]?.id, ln.id);
                        }}
                        className='font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl'
                      >
                       SUMMARY
                      </Link>
                      <Button onClick={() => reInitiateLoan(ln.id, ln.loanId)}>Reinitiate</Button>
                        </div>

                    </div>

                  );
                })}
              
            </div>
          )}

          {/* userLoans?.submittedBy?.id === data.id */}
        </div>
      </div>
    </div>
  );
}

export default LoanChecker;
