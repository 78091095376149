import React, { useState, useEffect } from 'react';
import { Alert, Button, ButtonGroup, Table } from 'react-bootstrap';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../axiosInstance';

function CarManufactureYear() {
  const [t] = useTranslation('global');
  const { serverIP } = useSelector((store) => store.allsettings);
  const [myear, setMyear] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [targetRecord, setTargetRecord] = useState([]);
  const [range, setRange] = useState('');
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    getManufactureYear();
  }, []);

  const getManufactureYear = () => {
    axiosInstance
      .get(`${serverIP}/car_manufacturer/carmanufacture`)
      .then(function (response) {
        console.log(response);
        setMyear(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveManufactureYear = () => {
    console.log('save');
    setModalAdd(false);
    axiosInstance
      .post(`${serverIP}/car_manufacturer/carmanufacture`, {
        yearRange: range,
        multiplierRatio: percent,
      })
      .then(function (response) {
        console.log(response);
        getManufactureYear();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editManufactureYear = () => {
    console.log('edit');
    axiosInstance
      .patch(`${serverIP}/car_manufacturer/carmanufacture/${targetRecord.id}`, {
        yearRange: range,
        multiplierRatio: percent,
      })
      .then((res) => {
        console.log(res.data);
        setModalEdit(false);
        getManufactureYear();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteManufactureYear = () => {
    axiosInstance
      .delete(`${serverIP}/car_manufacturer/carmanufacture/${targetRecord.id}`)
      .then((res) => {
        console.log(res.data);
        setModalDelete(false);
        getManufactureYear();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modals Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('officer.add')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='basic-addon1'>
              {t('officer.manufactureYear')}
            </InputGroup.Text>
            <Form.Control
              placeholder={t('officer.manufactureYear')}
              aria-describedby='basic-addon1'
              onChange={(e) => setRange(e.target.value)}
            />
          </InputGroup>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='basic-addon1'>%</InputGroup.Text>
            <Form.Control
              placeholder='percent'
              aria-describedby='basic-addon1'
              onChange={(e) => setPercent(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => saveManufactureYear()}>
            {t('officer.save')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('officer.edit')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='flex flex-col '>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='basic-addon1'>
              {t('officer.manufactureYear')}
            </InputGroup.Text>
            <Form.Control
              value={range}
              aria-describedby='basic-addon1'
              onChange={(e) => setRange(e.target.value)}
            />
          </InputGroup>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='basic-addon1'>%</InputGroup.Text>
            <Form.Control
              value={percent}
              aria-describedby='basic-addon1'
              onChange={(e) => setPercent(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='warning' onClick={() => editManufactureYear()}>
            {t('officer.edit')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('officer.delete')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('officer.delete')} <strong>{targetRecord.yearRange}</strong> with
          percent
          <strong>{targetRecord.multiplierRatio}</strong>!
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => deleteManufactureYear()}>
            <FaTrashAlt />
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modals End  */}

      <Alert variant='info'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{t('officer.manufactureYear')}</div>
          <Button
            variant='primary'
            style={{ margin: 0 }}
            className='btn btn-sm'
            onClick={() => setModalAdd(true)}
          >
            {t('officer.Add')}
          </Button>
        </div>
      </Alert>
      {myear.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th> ሞዴል </th>
              <th> መቶኛ </th>
              <th> ቻይና የተመረተየተመረተ </th>
              <th>{t('auditor.action')}</th>
            </tr>
          </thead>
          <tbody>
            {myear.map((chk) => {
              return (
                <tr key={chk.id}>
                  <td>{chk.id}</td>
                  <td>{chk.yearRange}</td>
                  <td>{chk.multiplierRatio * 100 + ' %'}</td>
                  <td>{chk.isChina ? 'ነው' : 'አይደለም'}</td>
                  <td>
                    <ButtonGroup size='sm'>
                      <Button
                        variant='danger'
                        onClick={() => {
                          setModalDelete(true);
                          setTargetRecord(chk);
                        }}
                      >
                        <FaTrashAlt />
                      </Button>
                      <Button
                        variant='warning'
                        onClick={() => {
                          setTargetRecord(chk);
                          setModalEdit(true);
                          setPercent(chk.multiplierRatio);
                          setRange(chk.yearRange);
                        }}
                      >
                        <FaPencilAlt />
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default CarManufactureYear;
