import * as Yup from 'yup';

const WoredaSchema = Yup.object().shape({
  regionId: Yup.number().required('ክልል ይምረጡ'),
  parent: Yup.number().required('ዞን ይምረጡ'),

  description: Yup.string().required('የወረዳዉን እንግሊዘኛ ስም ያስገቡ'),
  amDescription: Yup.string().required('የወረዳዉን ግእዝ ስም ያስገቡ'),
});

export default WoredaSchema;
