import React from 'react';
import PropTypes from 'prop-types';
import { Text, Font, View, StyleSheet } from '@react-pdf/renderer';
// import AmharicFont from "../../font/ebrima.ttf";
import AmharicFont from '../../font/ebrima.ttf';
// import getEthiopicDate from '../../Common/modules/GregorianToEthiopian';

const styles = StyleSheet.create({
  container: {
    marginTop: -15,
    display: 'flex',
    flexDirection: 'col',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  textRight: {
    marginTop: 1,
    fontSize: 12,
    textAlign: 'right',
    fontFamily: 'AmharicFont',
    fontWeight: 'bold',
  },
  subject: {
    fontSize: 13,
    textAlign: 'left',
    fontFamily: 'ebrimabold',
  },
});

Font.register({
  family: 'AmharicFont',
  src: AmharicFont,
});

function CarDateAndAddress({ objec }) {
  // const curdate = new Date();

  return (
    <View style={styles.container}>
      <View style={{ paddingTop: 30 }}>
        <Text style={styles.textRight}>
          ቀን ፡ &nbsp; _____/_____/_______
          {/* {getEthiopicDate(curdate.getDay(), +' ' + curdate.getMonth() + 1, +' ' + curdate.getFullYear())} */}
        </Text>
        <Text style={styles.textRight}>ቁጥር ፡ አ/ቀ/ብ/OP/___________/2017</Text>
      </View>
      <View style={{ paddingTop: 10 }}>
        <Text style={styles.subject}>{objec.sanctionAddress} </Text>
        <Text style={styles.subject}>{objec.sanctionAddresstwo} </Text>
        <Text style={styles.subject}>{objec.sanctionAddressthree} </Text>
      </View>
    </View>
  );
}
CarDateAndAddress.propTypes = {
  objec: PropTypes.shape({
    regionId: PropTypes.shape({
      id: PropTypes.number,
    }),
    woredaId: PropTypes.shape({
      amDescription: PropTypes.string,
    }),
    zoneId: PropTypes.shape({
      amDescription: PropTypes.string,
    }),
    sanctionAddress: PropTypes.string,
    sanctionAddresstwo: PropTypes.string,
    sanctionAddressthree: PropTypes.string,
  }).isRequired,
};

export default CarDateAndAddress;
