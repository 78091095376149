import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import { produce } from 'immer';

function Modifier() {
  const { steps } = useSelector((store) => store.modifierSlice);
  const [params, setParamas] = useState([]);
  const [targetMod, setTargetMod] = useState({});

  useEffect(() => {
    setParamas(steps[0].parameters);
  }, []);

  const updateModifier = () => {
    console.log(targetMod);
  };

  const addModifier = () => {
    produce(params, (draft) => {
      console.log('new modifier');
      const newModifier = {
        type: 'ScaleNumberValueModifier',
        uuid: 'd82f1b6c-37e5-4c91-b123-2a5e0d8c9abc',
        scale: 2,
      };

      draft?.modifiers?.push(newModifier);
      console.log('new draft');
      console.log(draft);
    });
  };
  return (
    <div>
      {params?.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Destinantion</th>
              <th>Notes</th>
              <th>Defaut Value</th>
              <th>Modifier Qty </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {params.map((mod) => {
              return (
                <tr key={mod.id}>
                  <td>{mod.destination}</td>
                  <td>{mod.notes}</td>
                  <td>{mod.defaultValue}</td>
                  <td>{mod.modifiers.length}</td>
                  <td>
                    <Button onClick={() => addModifier()}>ADD</Button>
                    <Button
                      className='btn btn-warning'
                      onClick={() => {
                        updateModifier();
                        setTargetMod(mod);
                      }}
                    >
                      Update
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default Modifier;
