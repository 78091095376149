import React, { useEffect, useRef, useState } from 'react';
import { FaEye, FaFileAlt, FaPhone, FaPrint, FaUser } from 'react-icons/fa';
import {
  firstC,
  fifthC,
  CharacterEvaluation,
  collateralSum,
} from '../Common/modules/NewFiveCSums';
import EvaluationKeyValue from './EvaluationKeyValue';
import { calculateFinancialMetrics } from '../Common/modules/Financialmetricss';

// import { calculateAbilityToPay } from "../Common/modules/AbilityToPay"
import { calculateAbilityToPay } from '../Common/modules/AbilityToPayAverage';
import { useSelector } from 'react-redux';
import {
  Accordion,
  Alert,
  Badge,
  Card,
  ListGroup,
  Modal,
} from 'react-bootstrap';

function LoanEvaluation() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { targetLoan, targetClient } = useSelector(
    (store) => store.systemLookups
  );
  const [summary, setSummary] = useState(null);
  const [viewObj, setViewObj] = useState({});
  const [totalMark, settotalMark] = useState(0);
  const [userMaritalStatusData, setUserMaritalStatusData] = useState([]);

  const [abilityToPayRelated, setAbilityToPayRelated] = useState(null);

  const [show, setShow] = useState(false);

  const printRef = useRef();

  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const handlePrint = () => {
    const printContents = printRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  useEffect(() => {
    setSummary(
      calculateFinancialMetrics(targetLoan.loan_fonance_items),
      targetLoan.monthly_pay,
      10000000
    );

    setAbilityToPayRelated(
      calculateAbilityToPay(targetLoan.loan_fonance_items, targetLoan)
    );
  }, []);

  useEffect(() => {
    setUserMaritalStatusData(
      targetClient.isMarried
        ? targetClient.marriedgeneralfiles
        : targetClient.singlegeneralfiles
    );

    let abilityToPay = abilityToPayRelated?.prcnt_Ability_to_Pay;
    let conditionEvaluation = firstC(targetClient, targetLoan);
    let capitalEval = Number(summary?.Capital_Turnover_Ratio);
    //   let capitalEval = 0;
    //   let CharacterEval = 0;
    let CharacterEval = CharacterEvaluation(targetLoan);
    //   let collateralEval = 0;
    let collateralEval = fifthC(targetLoan);

    console.log(`Type of Collateral Eval = ${typeof collateralEval}`);

    settotalMark(0);
    settotalMark(
      conditionEvaluation +
        capitalEval +
        Number(abilityToPay) +
        CharacterEval +
        collateralEval
    );
  }, [summary]);

  return (
    <div className='row'>
      {/* Modal Show Start  */}
      <Modal size='lg' show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={serverIP + viewObj.fileUrl}
            style={{ width: '100%', height: '100%' }}
          />
        </Modal.Body>
      </Modal>
      {/* Modal Show End  */}

      <div className='col-sm-8' ref={printRef}>
        {/* General Info Start  */}
        <div className='bg-white p-4 rounded-lg shadow-md mb-1'>
          <div className='grid grid-cols-2 gap-1'>
            <div>
              <p className='font-normal'>{`Name: ${targetClient.amDisplayName}`}</p>
              <p className='font-normal'>{`Address: ${targetClient.regionId}, ${targetClient.zoneId}, ${targetClient.woredaId}`}</p>
              <p className='font-normal'>{`Member Since: ${targetClient.memberSince}`}</p>
              <p className='font-normal'>{`Today's Date: ${formattedDate}`}</p>
              <p className='font-normal'> Employer: ----- </p>
              <p className='font-normal'> Position: ---- </p>
              <p className='font-normal'>{`Term (months): ${targetLoan.numberOfRepayments} Months`}</p>
              <p className='font-normal'>{`Interegst Rate: ${targetLoan.annualInterestRate} %`}</p>
            </div>
            <div>
              <p className='font-normal'>{`Account #: ${targetClient.entityAccountNo} `}</p>
              <p className='font-normal'>{`External Acount: ${targetClient.entityExternalId}`}</p>
              {/* <p className="font-normal"> Use:  --120-- </p> <p className="font-normal"> Type :  --0.1%--</p> */}
              <p className='font-normal'>
                Productive (G), provident(I) : $
                {(targetLoan.r_totalDueForPeriod + 5)?.toLocaleString()}
              </p>
              <p className='font-normal'> How Long : ----</p>
              <p className='font-normal'>{`Work Telephone: ${targetClient.mobileNo}`}</p>
              <p className='font-normal'>{`Monthly Payment: ${targetLoan.r_totalDueForPeriod?.toLocaleString()}`}</p>
              {/* <p className="font-normal">{`Collateral Value: ${collateralSum(targetLoan)?.toLocaleString()}`}</p> */}
            </div>
          </div>
        </div>
        {/* General Info End  */}

        <Accordion defaultActiveKey='open'>
          {/* Condition Evaluation Start  */}
          <Accordion.Item eventKey='open'>
            <Accordion.Header className='flex justify-end '>
              <Badge>C1</Badge> - Condition Evaluation
              <Badge>
                {Number((firstC(targetClient, targetLoan) * 100) / 35).toFixed(
                  2
                ) + ' %'}
              </Badge>
            </Accordion.Header>
            <Accordion.Body className='p-0'>
              {userMaritalStatusData.length > 0 &&
                userMaritalStatusData.map((data) => {
                  return (
                    <>
                      <div className='flex justify-between bg-orange-100 p-1 mb-1'>
                        <div>
                          {data.markValue} - {data.amDesc}
                        </div>
                        <div className='flex gap-2'>
                          {data.isUploaded ? (
                            <>
                              {data.fileType === 'application/pdf' ? (
                                <a
                                  target='_blank'
                                  href={serverIP + data.fileUrl}
                                  rel='noopener noreferrer'
                                >
                                  <FaFileAlt />
                                </a>
                              ) : (
                                <FaEye
                                  onClick={() => {
                                    setViewObj(data);
                                    setShow(true);
                                    console.log('set show clicked');
                                  }}
                                />
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
            </Accordion.Body>
          </Accordion.Item>
          {/* Condition Evaluation End 00733169 */}

          {/* Capital Evaluation Start  */}
          <Accordion.Item eventKey='open'>
            <Accordion.Header className='flex justify-end '>
              <Badge>C2</Badge> - Capital Evaluation
              <Badge>
                {(summary?.Return_On_Capital * 10).toLocaleString() + ' %'}
              </Badge>
            </Accordion.Header>
            <Accordion.Body className='p-0'>
              <ListGroup.Item as='li' className='d-flex p-0'>
                <div className='w-3/5 p-0'>
                  <ListGroup as='ol' className='p-0'>
                    <ListGroup.Item as='li'>
                      <EvaluationKeyValue
                        thekey='1. TOTAL ASSETS'
                        isodd='True'
                        value={summary?.Sum_Assets?.toLocaleString()}
                      />
                      <EvaluationKeyValue
                        thekey='Cash and Cash Equivalents (in bank or related institution)'
                        value={targetLoan?.loan_fonance_items[26]?.markValue?.toLocaleString()}
                      />
                      <EvaluationKeyValue
                        thekey='Account Receivable'
                        value={targetLoan?.loan_fonance_items[27]?.markValue?.toLocaleString()}
                      />
                      <EvaluationKeyValue
                        thekey='Inventory'
                        value={targetLoan?.loan_fonance_items[28]?.markValue?.toLocaleString()}
                      />
                      <EvaluationKeyValue
                        thekey='Property, Plant, and Equipment:(fixed and mobile/ movable)'
                        value={targetLoan?.loan_fonance_items[29]?.markValue?.toLocaleString()}
                      />
                    </ListGroup.Item>
                    <ListGroup.Item as='li'>
                      <EvaluationKeyValue
                        thekey='2. LIABILITIES'
                        isodd='True'
                        value={summary?.Sum_Liabilities?.toLocaleString()}
                      />
                      <EvaluationKeyValue
                        thekey='Accounts Payable'
                        value={targetLoan?.loan_fonance_items[30]?.markValue?.toLocaleString()}
                      />
                      <EvaluationKeyValue
                        thekey='Short-Term Loans'
                        value={targetLoan?.loan_fonance_items[31]?.markValue?.toLocaleString()}
                      />
                      <EvaluationKeyValue
                        thekey='Long-Term Loans'
                        value={targetLoan?.loan_fonance_items[32]?.markValue?.toLocaleString()}
                      />
                    </ListGroup.Item>
                    <ListGroup.Item as='li'>
                      <EvaluationKeyValue
                        thekey='3. CAPITAL'
                        isodd='True'
                        value={summary?.Sum_Capitals?.toLocaleString()}
                      />
                      <EvaluationKeyValue
                        thekey='Shares or other related bounds'
                        value={targetLoan?.loan_fonance_items[33]?.markValue?.toLocaleString()}
                      />
                      <EvaluationKeyValue
                        thekey='Retailed earnings'
                        value={targetLoan?.loan_fonance_items[34]?.markValue?.toLocaleString()}
                      />
                    </ListGroup.Item>
                  </ListGroup>
                </div>
                <div className='w-2/5 p-1 bg-gray-200'>
                  <EvaluationKeyValue
                    thekey='Liquidity Ratio'
                    value={summary?.Liquidity_Ratio}
                  />
                  <EvaluationKeyValue
                    thekey=''
                    value={summary?.Liquidity_Ratio > 1 ? '10 %' : '5%'}
                    isodd='True'
                  />
                  <EvaluationKeyValue
                    thekey='Net Worth'
                    value={summary?.NetWorth?.toLocaleString()}
                  />
                  <EvaluationKeyValue
                    thekey=''
                    value={summary?.NetWorth > 0 ? '10%' : '0%'}
                    isodd='True'
                  />
                  <EvaluationKeyValue
                    thekey='Debt-to-Asset Ratio'
                    value={summary?.Debt_To_Asset_Ratio}
                  />
                  <EvaluationKeyValue
                    thekey=''
                    value={summary?.Debt_To_Asset_Ratio < 0.5 ? '30%' : '10%'}
                    isodd='True'
                  />
                  <EvaluationKeyValue
                    thekey='Loan-to-value Ratio (LTV) '
                    value={summary?.Loan_To_Value_Ratio}
                  />
                  <EvaluationKeyValue
                    thekey=''
                    value={summary?.Loan_To_Value_Ratio < 0.8 ? '50%' : '20%'}
                  />
                  <Alert className='mt-4 font-bold text-center'>
                    {summary?.Capital_Turnover_Ratio} / 10
                  </Alert>
                </div>
              </ListGroup.Item>
            </Accordion.Body>
          </Accordion.Item>
          {/* Capital Evaluation End  */}

          {/* Ability To Pay (Capacity) Evaluation Start  */}
          <Accordion.Item eventKey='open'>
            <Accordion.Header className='flex justify-between '>
              <Badge>C3</Badge> - Ability To Pay (Capacity) Evaluation
              <Badge>
                {abilityToPayRelated?.prcnt_Ability_to_Pay * 4 + '%'}
              </Badge>
            </Accordion.Header>
            <Accordion.Body className='p-0'>
              <ListGroup as='ol'>
                <ListGroup.Item as='li'>
                  <EvaluationKeyValue
                    thekey='1. MONTHLY INCOME'
                    isodd='True'
                    value={summary?.Sum_Incomes?.toLocaleString()}
                  />
                  <EvaluationKeyValue
                    thekey="From product/service sale's"
                    value={targetLoan?.loan_fonance_items[18]?.markValue?.toLocaleString()}
                  />
                  <EvaluationKeyValue
                    thekey='Commissions'
                    value={targetLoan?.loan_fonance_items[19]?.markValue?.toLocaleString()}
                  />
                  <EvaluationKeyValue
                    thekey='Professiona Fees or salary'
                    value={targetLoan?.loan_fonance_items[20]?.markValue?.toLocaleString()}
                  />
                  <EvaluationKeyValue
                    thekey='Profit from interest on investment'
                    value={targetLoan?.loan_fonance_items[21]?.markValue?.toLocaleString()}
                  />
                  <EvaluationKeyValue
                    thekey='Rent from housing or commercial locale'
                    value={targetLoan?.loan_fonance_items[22]?.markValue?.toLocaleString()}
                  />
                  <EvaluationKeyValue
                    thekey='Grants and Funding'
                    value={targetLoan?.loan_fonance_items[23]?.markValue?.toLocaleString()}
                  />
                  <EvaluationKeyValue
                    thekey='Interest Income'
                    value={targetLoan?.loan_fonance_items[24]?.markValue?.toLocaleString()}
                  />
                </ListGroup.Item>

                <ListGroup.Item as='li' className='d-flex'>
                  <div className='w-1/2 p-1'>
                    <EvaluationKeyValue
                      thekey='2. MONTHLY EXPENDITURES'
                      isodd='True'
                      value=''
                    />
                    <ListGroup as='ol'>
                      <ListGroup.Item as='li'>
                        <EvaluationKeyValue
                          thekey='2.1. BASIC'
                          isodd='True'
                          value={summary?.Monthly_Basic_Expenses?.toLocaleString()}
                        />
                        <EvaluationKeyValue
                          thekey='Cost of Goods Sold (COGS)'
                          value={summary?.Sum_Cost_of_goods?.toLocaleString()}
                        />
                        <EvaluationKeyValue
                          thekey='Operating Expenses'
                          value={summary?.Sum_Operating_Expenses?.toLocaleString()}
                        />
                        <EvaluationKeyValue
                          thekey='Interest Expenses'
                          value={summary?.Sum_Interest_Expenses?.toLocaleString()}
                        />
                        <EvaluationKeyValue
                          thekey='Taxes Related Expenses'
                          value={summary?.Sum_Tax_Expenses?.toLocaleString()}
                        />
                        <EvaluationKeyValue
                          thekey='Personal expenses'
                          value={summary?.Sum_Personal_Expenses?.toLocaleString()}
                        />
                        <EvaluationKeyValue
                          thekey='Maintenance & related'
                          value={targetLoan?.loan_fonance_items[37]?.markValue?.toLocaleString()}
                        />
                      </ListGroup.Item>
                    </ListGroup>

                    <ListGroup as='ol'>
                      <ListGroup.Item as='li'>
                        <EvaluationKeyValue
                          thekey='2.2. FINANCIAL'
                          isodd='True'
                          value='0'
                        />
                        <EvaluationKeyValue
                          thekey='Credit Union-Banks'
                          value={targetLoan?.loan_fonance_items[38]?.markValue?.toLocaleString()}
                        />
                        <EvaluationKeyValue
                          thekey='Credit Cards'
                          value={targetLoan?.loan_fonance_items[39]?.markValue?.toLocaleString()}
                        />
                        <EvaluationKeyValue
                          thekey='Initial investment'
                          value={targetLoan?.loan_fonance_items[35]?.markValue?.toLocaleString()}
                        />
                        <EvaluationKeyValue
                          thekey='Sum of cash inflow'
                          value={targetLoan?.loan_fonance_items[36]?.markValue?.toLocaleString()}
                        />
                      </ListGroup.Item>
                    </ListGroup>

                    <ListGroup as='ol'>
                      <ListGroup.Item as='li'>
                        <EvaluationKeyValue
                          thekey='2.3. BASIC'
                          isodd='True'
                          value='5000'
                        />
                        <EvaluationKeyValue
                          thekey='Housing Related to cleaning'
                          value={targetLoan?.loan_fonance_items[40]?.markValue?.toLocaleString()}
                        />
                        <EvaluationKeyValue
                          thekey='Vehicle fuel'
                          value={targetLoan?.loan_fonance_items[41]?.markValue?.toLocaleString()}
                        />
                        <EvaluationKeyValue
                          thekey='Other Expenses'
                          value={targetLoan?.loan_fonance_items[42]?.markValue?.toLocaleString()}
                        />
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                  <div className='w-1/2 bg-gray-200 p-2'>
                    <div className='ms-2 me-auto'>
                      <div className='fw-bold'>
                        Business Revenue & Profit Evaluation
                      </div>
                      <EvaluationKeyValue
                        thekey='Business Gross Income'
                        value={abilityToPayRelated?.totalIncome?.toLocaleString()}
                      />
                      <EvaluationKeyValue
                        thekey='Total Expenses'
                        value={abilityToPayRelated?.totalExpenses?.toLocaleString()}
                      />

                      <EvaluationKeyValue
                        thekey='Net Profit'
                        value={
                          abilityToPayRelated?.totalIncome -
                          abilityToPayRelated?.totalExpenses
                        }
                      />

                      <div className='flex flex-col justify-center items-center text-xl font-bold m-4'>
                        <div>
                          DIR :{' '}
                          {abilityToPayRelated?.prcnt_Debt_to_Income_Ratio} % (
                          {abilityToPayRelated?.c_debtToIncomeRatio})
                        </div>

                        <div>
                          DSCR :
                          {abilityToPayRelated?.Debt_Service_Coverage_Ratio} (
                          {abilityToPayRelated?.c_debtServiceCoverageRatio})
                        </div>
                        <div>
                          IRR :
                          {abilityToPayRelated?.prcnt_Internal_Rate_of_Return +
                            ' %'}{' '}
                          ({abilityToPayRelated?.c_internalRateOfReturn})
                        </div>
                      </div>

                      <Alert className='font-bold text-center'>
                        {abilityToPayRelated?.prcnt_Ability_to_Pay} / 25
                      </Alert>
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          {/* Ability To Pay (Capacity) Evaluation End  */}

          {/* Character Evaluation Start  */}
          <Accordion.Item eventKey='open'>
            <Accordion.Header className='flex justify-end '>
              <Badge>C4</Badge> - Character Evaluation
              <Badge>{CharacterEvaluation(targetLoan) * 20 + ' %'}</Badge>
            </Accordion.Header>
            <Accordion.Body className='p-0'>
              <ListGroup as='ol'>
                <ListGroup.Item as='li'>
                  <EvaluationKeyValue
                    thekey='1. WORK / BUSINESS OWNERSHIP'
                    isodd='True'
                    value='---'
                  />
                  <EvaluationKeyValue
                    thekey='Self-Owned (S), Partnership (P), Complicated (C)'
                    value={targetLoan?.businessOwnership}
                  />
                  <EvaluationKeyValue
                    thekey='Time at previous Business (in months);with attachment'
                    value={targetLoan?.previousBusinessTenureInMonths}
                  />
                  <EvaluationKeyValue
                    thekey='Time in current residence or In AA  (in months)'
                    value={targetLoan?.tenureInMonths}
                  />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                  <EvaluationKeyValue
                    thekey='2. CREDIT AND SAVING EXPERIENCE'
                    isodd='True'
                    value='---'
                  />
                  <EvaluationKeyValue
                    thekey='Good history in last loan or present loan: yes (Y), no (N)'
                    value={targetLoan?.lastLoanGoodHistory ? 'YES' : 'NO'}
                  />
                  <EvaluationKeyValue
                    thekey='Saving despline; Platinium(P), Gold(G), Siliver(S)'
                    value={targetLoan?.savingdecipline}
                  />
                  <EvaluationKeyValue
                    thekey='Reference In Amigos: good(g), average(a), poor(p)'
                    value={targetLoan.referenceinamigos}
                  />
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          {/* Character Evaluation End  */}

          {/* Collateral Evaluation Start  */}
          <Accordion.Item eventKey='open'>
            <Accordion.Header className='flex justify-end '>
              <Badge>C5</Badge> - Collateral Evaluation
              <Badge>{(fifthC(targetLoan) * 100) / 25 + ' %'}</Badge>
            </Accordion.Header>
            <Accordion.Body className='p-0'>
              <ListGroup as='ul' style={{ padding: 0 }}>
                <ListGroup.Item
                  as='li'
                  className='d-flex justify-content-between align-items-start'
                >
                  የተያዘው ንብረት ጠቅላላ ግምት
                  <span className='font-bold'>
                    {collateralSum(targetLoan)?.toLocaleString()}
                  </span>
                </ListGroup.Item>

                <ListGroup.Item
                  as='li'
                  className='d-flex justify-content-between align-items-start'
                >
                  የተፈቀደው የብድር መጠን
                  <span className='font-bold'>
                    {targetLoan?.approvedPrincipal?.toLocaleString()}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item
                  as='li'
                  className='d-flex justify-content-between align-items-start'
                >
                  የተያዠው ንብረት ለሚሰጠው ጠቅላላ ብድር (RATIO)
                  <span className='font-bold'>
                    {Number(
                      (collateralSum(targetLoan) * 100) /
                        targetLoan.approvedPrincipal
                    ).toFixed(2)}
                    %
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          {/* Collateral Evaluation End  */}
        </Accordion>

        <div className='flex justify-around mt-2 text-lg bg-gray-400 p-2 rounded-lg'>
          <div>C1={firstC(targetClient, targetLoan)?.toLocaleString()}/35</div>
          <div>C2={summary?.Capital_Turnover_Ratio?.toLocaleString()}/10</div>
          <div>C3={Number(abilityToPayRelated?.prcnt_Ability_to_Pay)}/25</div>
          <div>C4={CharacterEvaluation(targetLoan)?.toLocaleString()}/5 </div>
          <div>C5={fifthC(targetLoan)?.toLocaleString()}/25</div>
        </div>

        {totalMark < 76 ? (
          <div className='flex justify-around mt-2 text-2xl border-3 border-red-600 p-2 rounded-lg'>
            <div className='text-red-700 font-bold'>DENIED</div>
            <div className='text-red-700 font-bold'>{`Total Score : ${totalMark.toLocaleString()}/100`}</div>
          </div>
        ) : (
          <div className='flex justify-around mt-2 text-2xl border-3 border-green-600 p-2 rounded-lg'>
            <div className='text-green-600 font-bold'>ACCEPTED</div>
            <div className='text-green-600 font-bold'>{`Total Score : ${totalMark.toLocaleString()}/100`}</div>
          </div>
        )}
      </div>

      <div className='col-sm-4'>
        <Card>
          <Card.Header>Customer Detail </Card.Header>
          <Card.Body>
            <ListGroup as='ul' style={{ padding: 0 }}>
              <ListGroup.Item
                as='li'
                className='d-flex justify-content-between'
              >
                <FaUser /> {targetClient?.amDisplayName}
              </ListGroup.Item>
              <ListGroup.Item
                as='li'
                className='d-flex justify-content-between'
              >
                <FaPhone /> {targetClient?.mobileNo}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
        <Card className='mt-2'>
          <Card.Header>Loan Detail </Card.Header>
          <Card.Body>
            <ListGroup as='ul' style={{ padding: 0 }}>
              <ListGroup.Item
                as='li'
                className='d-flex justify-content-between'
              >
                <div>የብድር አይነት:</div> {targetLoan.loanProductName}
              </ListGroup.Item>
              <ListGroup.Item
                as='li'
                className='d-flex justify-content-between'
              >
                <div> የተፈቀደው የብድር መጠን፡</div> {targetLoan.approvedPrincipal}
              </ListGroup.Item>
              <ListGroup.Item
                as='li'
                className='d-flex justify-content-between'
              >
                <div> ጠቅላላ ቁጠባ፡</div> {targetLoan.r_totalSaving}
              </ListGroup.Item>
              <ListGroup.Item
                as='li'
                className='d-flex justify-content-between'
              >
                <div> ጠቅላላ ሼር፡</div> {targetLoan.r_totalShares}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
        <Card className='mt-2'>
          <Card.Header> ያልተሟሉ ብድር የመክፈል አቅም ማሳያ </Card.Header>
          <Card.Body>
            <ListGroup as='ul' style={{ padding: 0 }}></ListGroup>
          </Card.Body>
        </Card>

        <Card className='mt-2'>
          <Card.Header> ግመገማ </Card.Header>
          <Card.Body>
            <ListGroup as='ul' style={{ padding: 0 }}>
              <ListGroup.Item
                as='li'
                className='d-flex justify-content-between align-items-start'
              >
                {totalMark > 76 && totalMark < 85 && <>ጥሩ</>}
                {totalMark > 86 && totalMark < 93 && <>በጣም ጥሩ</>}
                {totalMark > 93 && <>እጅግ በጣም ጥሩ</>}
                {totalMark < 76 && <>ደካማ</>}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>

        <div className='flex justify-between mt-2'>
          <button
            onClick={handlePrint}
            className='flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400'
          >
            <FaPrint className='mr-2' /> Print
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoanEvaluation;
