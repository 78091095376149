import * as Yup from 'yup';

const EmployeeSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, 'የተጠቃሚ ስም ክ3 ፊደል ማነስ የለበትም')
    .required('የተጠቃሚ ስም ይፈለጋል'),
  email: Yup.string().required(' ኢሜይል ረስተዋል '),
  first_name: Yup.string().required('ሰም ረስተዋል'),
  last_name: Yup.string().required('የአት ሰም ረስተዋል'),
  groups: Yup.string().required('የተቀጣሪ የስራ ድርሻ ይፈለጋል'),
});

export default EmployeeSchema;
