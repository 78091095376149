import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet } from '@react-pdf/renderer';

function NumberToWordDisplay(props) {
  const Styles = StyleSheet.create({
    dynamicText: {
      fontFamily: 'ebrimabold',
      color: 'black',
    },
  });

  return (
    <Text>
      <Text style={Styles.dynamicText}> ({props.text}) </Text> &nbsp;
    </Text>
  );
}
NumberToWordDisplay.propTypes = {
  text: PropTypes.string.isRequired,
};

export default NumberToWordDisplay;
