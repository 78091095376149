export function calculateAbilityToPay(data, loan) {
  // Helper function to safely get a value or return 0 if missing
  function getValue(desc) {
    return data.find((item) => item.amDesc === desc)?.markValue || 0;
  }

  // Extract relevant financial data
  let totalDebtPayments = loan.monthly_pay;
  //   let totalDebtPayments =
  //     getValue('interest payments on loans') +
  //     getValue('Long-Term Loans') +
  //     getValue('Short-Term Loans') +
  //     getValue('Accounts Payable');

  let totalIncome =
    getValue("From product/service sale's") +
    getValue('Commissions') +
    getValue('Professional Fees or salary (Own Fee)') +
    getValue('Professional Fees or salary (Spons Fee)') +
    getValue('Profit form Interest on Investments') +
    getValue('Rent from housing or commercial locale') +
    getValue('Grants and Funding') +
    getValue('Interest Income');

  let totalIncomeDscr =
    getValue("From product/service sale's") +
    getValue('Rent from housing or commercial locale');
  // +
  // getValue('Cash and Cash Equivalents (in bank or related institution)') +
  // getValue('Accounts Receivable');

  let totalExpensesDscr = getValue('Maintenance & Related Expenses');

  let totalExpenses =
    getValue('income taxes paid to govermental body') +
    getValue('for personal house rent') +
    getValue('for food and beverage') +
    getValue('for schooling/education') +
    getValue('Others ...') +
    getValue('Raw materials') +
    getValue('Labor Related to wages') +
    getValue('Overhead related to expence') +
    getValue('Rent or lease payments for office space or facilities') +
    getValue('Utilities such as electricity, water, etc') +
    getValue('Salaries for employees') +
    getValue('Employee benefits such as health insurance …...') +
    getValue('Marketing and advertising expenses') +
    getValue('Office supplies and equipment') +
    getValue('Professional services such as legal, accounting, if any') +
    getValue('Travel and entertainment expenses') +
    getValue('interest payments on loans') +
    getValue('lines of credit, or bonds.') +
    getValue('Maintenance & Related Expenses') +
    getValue('Housing Related to cleaning') +
    getValue('Vehicle Fuel');

  let all_lnterestOnLoan = getValue('interest payments on loans');

  let initialInvestment = getValue('Initial Investment');

  // Compute ratios safely
  let netOperatingIncome = totalIncome - totalExpenses;

  let debtToIncomeRatio =
    netOperatingIncome > 0 ? (totalDebtPayments / netOperatingIncome) * 100 : 0;

  let debtServiceCoverageRatio =
    ((totalIncomeDscr - totalExpensesDscr) /
      (loan.approvedPrincipal + all_lnterestOnLoan)) *
    loan.numberOfRepayments;
  // totalDebtPayments > 0 ? netOperatingIncome / totalDebtPayments : 0;

  let internalRateOfReturn =
    initialInvestment !== 0
      ? (netOperatingIncome / Math.abs(initialInvestment)) * 100
      : 0;

  // Compute Ability to Pay (%) as the average of the three ratios
  // let abilityToPay = (debtToIncomeRatio  + debtServiceCoverageRatio  + internalRateOfReturn ) / 3;

  const dirRange = [
    { max: 10, value: 10 },
    { max: 20, value: 8 },
    { max: 30, value: 7 },
    { max: 45, value: 6 },
    { max: Infinity, value: 0 }, // Covers values greater than 45%
  ];

  const dscrRange = [
    { max: 5, value: 5 },
    { max: 10, value: 6 },
    { max: 20, value: 8 },
    { max: 30, value: 9 },
    { max: Infinity, value: 10 }, // Covers values greater than 45%
  ];

  const irrRange = [
    { max: 20, value: 0 },
    { max: 30, value: 3 },
    { max: 40, value: 4 },
    { max: Infinity, value: 5 }, // Covers values greater than 45%
  ];

  function getDirValue(dir) {
    return dirRange.find((range) => dir < range.max).value;
  }

  function getDscrValue(dir) {
    return dscrRange.find((range) => dir < range.max).value;
  }
  function getIrrValue(dir) {
    return irrRange.find((range) => dir < range.max).value;
  }

  let c_debtToIncomeRatio = getDirValue(debtToIncomeRatio);
  let c_debtServiceCoverageRatio = getDscrValue(debtServiceCoverageRatio);
  let c_internalRateOfReturn = getIrrValue(internalRateOfReturn);

  let abilityToPay =
    c_debtToIncomeRatio + c_debtServiceCoverageRatio + c_internalRateOfReturn;

  // Ensure Ability to Pay is between 0 and 100
  abilityToPay = Math.max(0, Math.min(100, abilityToPay));

  return {
    prcnt_Debt_to_Income_Ratio: debtToIncomeRatio.toFixed(2),
    Debt_Service_Coverage_Ratio: debtServiceCoverageRatio.toFixed(2),
    prcnt_Internal_Rate_of_Return: internalRateOfReturn.toFixed(2),
    prcnt_Ability_to_Pay: abilityToPay,
    c_debtToIncomeRatio: c_debtToIncomeRatio,
    c_debtServiceCoverageRatio: c_debtServiceCoverageRatio,
    c_internalRateOfReturn: c_internalRateOfReturn,
    totalIncome: totalIncome,
    totalExpenses: totalExpenses,
  };
}
