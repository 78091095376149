import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import AgreementStyles from '../Styles/ReportStyle';
import ReportStyle from '../Styles/ReportStyle';
import HeaderHistory from './HeaderHistory';
import { useSelector } from 'react-redux';
import Tr from '../../Contracts/Reusables/Tr';

import Trc from '../../Contracts/Reusables/Trc';
import axiosInstance from '../../axiosInstance';

function RptHistoryDisburse() {
  const { serverIP } = useSelector((store) => store.allsettings);

  const [disbursements, setDisbursements] = useState([]);
  const [disburseSum, setDisburseSum] = useState(0);

  useEffect(() => {
    getDisbursementHistory();
  }, []);

  const getDisbursementHistory = () => {
    axiosInstance
      .get(`${serverIP}/disbursements/disbursements`)
      .then((res) => {
        console.log(res.data);
        setDisbursements(res.data);
        const sum_bisburses = res.data?.reduce((accum, obj) => {
          return accum + obj.amount;
        }, 0);

        setDisburseSum(sum_bisburses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getDateDifferenceInDays(plannedDate, appointmentDate) {
    // loan.plannedDate + " - " + loan.appointmentDate
    const Conv_plannedDate = new Date(plannedDate);
    const Conv_appointmentDate = new Date(appointmentDate);
    const differenceInTime = Conv_appointmentDate - Conv_plannedDate;
    const differenceInDays = Math.ceil(
      differenceInTime / (1000 * 60 * 60 * 24)
    );
    return differenceInDays;
  }

  // function getDateDifferenceInDays(dateString) {
  //   // Parse the given date string into a Date object
  //   const givenDate = new Date(dateString);
  //   // Get today's date
  //   const today = new Date();
  //   // Calculate the difference in time (milliseconds)
  //   const differenceInTime = givenDate - today;
  //   // Convert the time difference from milliseconds to days
  //   const differenceInDays = Math.ceil(
  //     differenceInTime / (1000 * 60 * 60 * 24)
  //   );
  //   return differenceInDays;
  // }

  return (
    <PDFViewer style={{ width: '100%', height: 1200 }}>
      <Document title='ያለቁ ብድሮች ሪፖርት'>
        <Page style={AgreementStyles.body} wrap>
          <HeaderHistory type='ያለቁ ብድሮች ሪፖርት' />
          <Trc borders='t0r0b0l0' thekey='ጠቅላላ ያለቁ ብድሮች ሪፖርት' />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: 'gray',
              marginTop: 5,
            }}
          >
            <Tr
              style={ReportStyle.h5b}
              thekey='ሙሉ ስም'
              width='22%'
              borders='t1r1b1l1'
            />
            <Tr
              style={ReportStyle.h5b}
              thekey='የብድር መጠን'
              width='19%'
              borders='t1r1b1l0'
            />
            <Tr
              style={ReportStyle.h5b}
              thekey='የተጠየቀበት ቀን  - የቀጠሮ ቀን'
              width='25%'
              borders='t1r1b1l0'
            />
            <Tr
              style={ReportStyle.h5b}
              thekey='የፈጀው ቀናት'
              width='12%'
              borders='t1r1b1l0'
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=' ስልክ '
              width='22%'
              borders='t1r1b1l0'
            />
          </View>
          {disbursements.length > 0 &&
            disbursements.map((loan) => {
              return (
                <View
                  key={loan.id}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.loan?.customer?.amDisplayName}
                    width='22%'
                    borders='t0r1b1l1'
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.amount?.toLocaleString()}
                    width='19%'
                    borders='t0r1b1l0'
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan.plannedDate + ' - ' + loan.appointmentDate}
                    width='25%'
                    borders='t0r1b1l0'
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={getDateDifferenceInDays(
                      loan.plannedDate,
                      loan.appointmentDate
                    )}
                    width='12%'
                    borders='t0r1b1l0'
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.loan?.customer?.mobileNo}
                    width='22%'
                    borders='t0r1b1l0'
                  />
                </View>
              );
            })}

          <View style={{ paddingTop: 10, paddingLeft: 5 }}>
            <Text style={ReportStyle.h5b}>
              ጠቅላላ የብድር መጠን: {disburseSum?.toLocaleString()}
            </Text>
            <Text style={ReportStyle.h5b}>
              የብድር ብዛት : {disbursements.length}{' '}
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default RptHistoryDisburse;
