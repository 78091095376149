import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

function TblAuditorEvaluation(props) {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        fontSize: 9,
        fontFamily: 'AmharicFont',
        backgroundColor: props.bgcolor,
      }}
    >
      <View
        style={{
          borderWidth: 1,
          borderTopWidth: 0,
          borderRightWidth: 0,
          width: '82%',
          paddingLeft: 2,
          paddingBottom: 1,
        }}
      >
        <Text style={{ textAlign: props.textalign }}>{props.zkey}</Text>
      </View>
      <View
        style={{
          borderWidth: 1,
          borderTopWidth: 0,
          width: '18%',
          paddingLeft: 2,
          paddingBottom: 1,
        }}
      >
        <Text>{props.zvalue}</Text>
      </View>
    </View>
  );
}

// #61cbf3 , #83e28e ,  #e8e8e8, #d9d9d9, #ff0000
TblAuditorEvaluation.propTypes = {
  bgcolor: PropTypes.string,
  textalign: PropTypes.string,
  zkey: PropTypes.string,
  zvalue: PropTypes.string,
};

export default TblAuditorEvaluation;
