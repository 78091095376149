import axiosInstance from '../axiosInstance';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { Alert, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { setTargetClient } from '../Common/redux/systemLookups';
import { FaEye, FaFileAlt } from 'react-icons/fa';

function MarriedFiles({ targetClient, successnotify, errornotify }) {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { targetLoan } = useSelector((store) => store.systemLookups);
  const { data } = useSelector((store) => store.customer);

  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);
  const [viewObj, setViewObj] = useState([]);
  const [ddlMarriedList, setDdlMarriedList] = useState([]);

  function handleUpload(fileid) {
    if (!file) {
      errornotify();
      return;
    }

    const fd = new FormData();
    fd.append('file', file);
    fd.append('parent', targetClient.id);
    fd.append('fileType', file.type);
    fd.append('fileUrl', file);
    fd.append('isUploaded', true);

    axiosInstance
      .patch(`${serverIP}/customer_married/custommermarried/${fileid}`, fd, {
        headers: {
          'Custom-Header': 'value',
        },
      })
      .then((res) => {
        successnotify();
        console.log(res.data);
        // getLoan();
        setFile(null);
        getCustomer();
      })
      .catch((err) => {
        errornotify();
        console.log(err);
      });
  }

  const handleDeleteFile = (fileid) => {
    axiosInstance
      .patch(`${serverIP}/customer_married/custommermarried/${fileid}`, {
        isUploaded: false,
      })
      .then(() => {
        successnotify();
        getCustomer();
      })
      .catch((err) => {
        errornotify();
        console.log(err);
      });
  };

  const reLoadData = async () => {
    axiosInstance
      .delete(
        `${serverIP}/customer_married/custommermarried/deletebyparent/${targetClient.id}/`
      )
      .then((res) => {
        console.log(res.data);
        getCustomer();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDdlMarried();
  }, []);

  const getDdlMarried = async () => {
    await axiosInstance
      .get(`${serverIP}/ddlmarried/ddlmarrieds`)
      .then((res) => {
        const transformedArray = res?.data?.map((item) => ({
          parent: targetClient.id,
          user: data.id,
          amDesc: item.amName,
          chkmarried: item.id,
          isMandatory: item.isMandatory,
          markValue: item.markValue,
        }));
        setDdlMarriedList(transformedArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadData = async () => {
    await getDdlMarried();
    await axiosInstance
      .post(
        `${serverIP}/customer_married/custommermarried/bulkinsert`,
        ddlMarriedList
      )
      .then((res) => {
        console.log(res.data);
        successnotify();
        getCustomer();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(console.log('married final'));
  };

  const getCustomer = async () => {
    try {
      const res = await axiosInstance.get(
        `${serverIP}/customer/customers/${targetClient.id}`
      );
      dispatch(setTargetClient({ client: res.data }));
    } catch (err) {
      console.error('Error fetching customer:', err);
    }
  };

  return (
    <div>
      {/* Modal Start  */}
      <Modal size='lg' show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={serverIP + viewObj?.fileUrl}
            style={{ width: '100%', height: '100%' }}
          />
        </Modal.Body>
      </Modal>
      {/* Modal End  */}
      {targetClient?.marriedgeneralfiles?.length > 0 ? (
        <>
          <Alert
            style={{ display: 'flex', justifyContent: 'space-between' }}
            className='mt-2 py-2 items-center'
          >
            <div>የተበዳሪ ፋይሎች ሳብ</div>
            <Button
              disabled={targetLoan?.loanstatus_id !== 1}
              className='btn btn-warning btn-sm'
              onClick={reLoadData}
            >
              የፋይል ዝርዝር አጥፋ
            </Button>
          </Alert>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th> የዶክመንት ስም </th>
                <th> እይ </th>
                <th> ፋይል </th>
                <th> አጥፋ </th>
              </tr>
            </thead>
            <tbody>
              {targetClient?.marriedgeneralfiles.map((upload) => {
                return (
                  <tr
                    className={upload.isUploaded ? `` : `table-warning`}
                    style={{ margin: 0 }}
                    key={upload.id}
                  >
                    <td>
                      <Badge>{upload.markValue}</Badge> {upload.amDesc}
                    </td>
                    {/* <td> {upload.checkListId?.enName} </td> */}
                    <td>
                      {upload.isUploaded ? (
                        <>
                          {upload.fileType === 'application/pdf' ? (
                            <a
                              target='_blank'
                              href={serverIP + upload.fileUrl}
                              rel='noopener noreferrer'
                            >
                              <FaFileAlt />
                            </a>
                          ) : (
                            <FaEye
                              onClick={() => {
                                setViewObj(upload);
                                setShow(true);
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </td>

                    <td style={{ width: '30%', padding: 0 }}>
                      {data.groups[0] === 'ROLE_OFFICER' ? (
                        <>
                          <InputGroup style={{ paddingTop: 0 }}>
                            <input
                              onChange={(e) => {
                                setFile(e.target.files[0]);
                                console.log(e.target.files[0]);
                              }}
                              type='file'
                              className='form-control'
                            />
                            <Button
                              disabled={targetLoan?.loanstatus_id !== 1}
                              variant='outline-primary'
                              onClick={() => handleUpload(upload.id)}
                            >
                              {!upload.isUploaded ? <>ጫን</> : <>አስተካክል</>}
                            </Button>
                          </InputGroup>
                        </>
                      ) : (
                        <>{upload.isUploaded ? 'ጫን' : 'አልተሰቀለም'}</>
                      )}
                    </td>
                    <td style={{ width: '10%', padding: 0 }}>
                      {upload.isUploaded && (
                        <Button
                          disabled={targetLoan?.loanstatus_id !== 1}
                          variant='outline-danger'
                          onClick={() => handleDeleteFile(upload.id)}
                        >
                          አጥፋ
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <Alert className='flex justify-between p-2'>
          <div>የተበዳሪ ፋይሎች ሳብ</div>
          <Button
            disabled={targetLoan?.loanstatus_id !== 1}
            className='btn-sm'
            onClick={loadData}
          >
            ሳብ
          </Button>
        </Alert>
      )}
    </div>
  );
}

MarriedFiles.propTypes = {
  targetClient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    marriedgeneralfiles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        markValue: PropTypes.string,
        amDesc: PropTypes.string,
        isUploaded: PropTypes.bool,
        fileType: PropTypes.string,
        fileUrl: PropTypes.string,
      })
    ),
  }).isRequired,
  successnotify: PropTypes.func.isRequired,
  errornotify: PropTypes.func.isRequired,
};

export default MarriedFiles;
// export default MarriedFiles
