import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import AgreementDoc from './Summary/AgreementDoc';
import HomeGuarantee from './Summary/HomeGuarantee';
import HomeProh from './Summary/HomeProh';

import CarGuarantee from './Summary/CarGuarantee';
import CarProh from './Summary/CarProh';

import SalaryGuarantee from './Summary/SalaryGuarantee';
import ShareGuarantee from './Summary/ShareGuarantee';
import ShareProh from './Summary/ShareProh';

import SummaryDecision from './Summary/SummaryDecision';
import SummaryEvaluation from './Summary/SummaryEvaluation';
import { useSelector } from 'react-redux';

function LoanSummaryTabs() {
  const [key, setKey] = useState('AgreementDoc');
  const { targetClient, targetLoan } = useSelector(
    (store) => store.systemLookups
  );

  return (
    <>
        <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
            <Tab eventKey='AgreementDoc' title="AgreementDoc" >
                <AgreementDoc customer={targetClient} loan={targetLoan}
             />
            </Tab>

            <Tab eventKey='HomeGuarantee' title="HomeGuarantee" >
                <HomeGuarantee />
            </Tab>
            <Tab eventKey='HomeProh' title="HomeProh" >
                <HomeProh />
            </Tab>

            <Tab eventKey='CarGuarantee' title="CarGuarantee" >
                <CarGuarantee />
            </Tab>
            <Tab eventKey='CarProh' title="CarProh" >
                <CarProh />
            </Tab>

            <Tab eventKey='SalaryGuarantee' title="SalaryGuarantee" >
                <SalaryGuarantee />
            </Tab>
            <Tab eventKey='ShareGuarantee' title="ShareGuarantee" >
                <ShareGuarantee />
            </Tab>
            <Tab eventKey='ShareProh' title="ShareProh" >
                <ShareProh />
            </Tab>

            <Tab eventKey='SummaryDecision' title="SummaryDecision" >
                <SummaryDecision />
            </Tab>
            <Tab eventKey='SummaryEvaluation' title="SummaryEvaluation" >
                <SummaryEvaluation />
            </Tab>
        </Tabs>
    </>
  )
}

export default LoanSummaryTabs

// 1129 