import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTargetClient, setTargetLoan } from '../Common/redux/systemLookups';
import axiosInstance from '../axiosInstance';
import { useParams } from 'react-router-dom';
import LoanSummaryTabs from './LoanSummaryTabs';

function LoanSummary() {
  const dispatch = useDispatch();
  const [t] = useTranslation('global');
  const { serverIP } = useSelector((store) => store.allsettings);
  const { targetLoan, targetClient } = useSelector(
    (store) => store.systemLookups
  );
  const { customerId, loanId } = useParams();

  useEffect(() => {
    getCustomer_Loan();
  }, []);

  const getCustomer_Loan = async () => {
    try {
      await getLoan();
      await getCustomer();
    } catch (error) {
      console.error('Error in redirecting:', error);
    }
  };

  const getLoan = async () => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error('Error fetching loan:', err);
    }
  };

  const getCustomer = async () => {
    try {
      const res = await axiosInstance.get(
        `${serverIP}/customer/customers/${customerId}`
      );
      dispatch(setTargetClient({ client: res.data }));
    } catch (err) {
      console.error('Error fetching customer:', err);
    }
  };

  return (
    <div>
        <div className='flex justify-between bg-slate-200 px-2 items-center'>
            <b className='text-orange-400'>{targetClient.amDisplayName}</b>
            <b>{targetClient.displayName}</b>
            <b className='text-orange-400'> {`External #: ${targetClient.entityExternalId}`} </b>
            <b> {`Account #: ${targetClient.entityAccountNo}`}</b>
        </div>
    <LoanSummaryTabs />
    </div>
  )
}

export default LoanSummary