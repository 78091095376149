import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import store from './Common/redux/Store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Login from './Login';
import NotFound from './Common/NotFound';
import ProtectedRoute from './Common/ProtectedRoute';
import Home from './Common/Home';
import PatchIP from './Common/PatchIP';
import MyLoans from './Role_Officer/MyLoans';
import LoanOnOfficer from './Role_Officer/LoanOnOfficer';

import AmContracts from './Contracts/Amharic/AmContracts';
import TgContracts from './Contracts/Tigrigna/TgContracts';
import AmProhibitions from './Prohibitions/Amharic/AmProhibitions';
import TgProhibitions from './Prohibitions/Tigrigna/TgProhibitions';

import HelpOfficer from './Role_Officer/HelpOfficer';
import MyFinishedLoans from './Role_Officer/MyFinishedLoans';
import NewForm from './Role_Officer/NewForm';

import LoanEvaluation from './LonaEvaluation/LoanEvaluation';
import LoanDecide from './LonaEvaluation/LoanDecide';
import Profile from './Common/Profile';
import ChangePass from './Common/ChangePass';

// Admin
import Regions from './Role_Admin/Address/Regions';
import Zones from './Role_Admin/Address/Zones';
import Woredas from './Role_Admin/Address/Woredas';

import Banks from './Role_Admin/DDL/Banks';
import Cars from './Role_Admin/DDL/Cars';

import CompanyType from './Role_Admin/DDL/CompanyType';
import Country from './Role_Admin/DDL/Country';
import Delegation from './Role_Admin/DDL/Delegation';
import House from './Role_Admin/DDL/House';
import LoanFinanceCategory from './Role_Admin/DDL/LoanFinanceCategory';
import LoanFinanceData from './Role_Admin/DDL/LoanFinanceData';

import Married from './Role_Admin/DDL/Married';
import MarriedSurety from './Role_Admin/DDL/MarriedSurety';
import Marriedgp from './Role_Admin/DDL/Marriedgp';
import Membership from './Role_Admin/DDL/Membership';
import ReferenceInAmigos from './Role_Admin/DDL/ReferenceInAmigos';
import Salary from './Role_Admin/DDL/Salary';
import SavingDecipline from './Role_Admin/DDL/SavingDecipline';

import Share from './Role_Admin/DDL/Share';
import Single from './Role_Admin/DDL/Single';
import SingleSurety from './Role_Admin/DDL/SingleSurety';
import Singlegp from './Role_Admin/DDL/Singlegp';

import CarManufactureYear from './Role_Admin/Lookups/CarManufactureYear';
import CarModel from './Role_Admin/Lookups/CarModel';
import Holidays from './Role_Admin/Lookups/Holidays';
import HomeType from './Role_Admin/Lookups/HomeType';
import LoanType from './Role_Admin/Lookups/LoanType';

import Employees from './Role_Admin/Employees';
import JobPosition from './Role_Admin/JobPosition';
import Loan from './Role_Admin/Loan';
import LoanCommittee from './Role_Admin/LoanCommittee';

// Home
import Homebuildingmaterial from './Role_Admin/Home/Homebuildingmaterial';
import Homebuildingtype from './Role_Admin/Home/Homebuildingtype';
import Homefloorlocation from './Role_Admin/Home/Homefloorlocation';
import Homefromasphalt from './Role_Admin/Home/Homefromasphalt';
import Homelocation from './Role_Admin/Home/Homelocation';
import Homeneighbour from './Role_Admin/Home/Homeneighbour';
import Homephysicalappearance from './Role_Admin/Home/Homephysicalappearance';
import Homesetback from './Role_Admin/Home/Homesetback';
import Homeuse from './Role_Admin/Home/Homeuse';

// Admin Reports
import RptHistoryLoan from './Role_Admin/Reports/RptHistoryLoan';
import RptHistoryDisburse from './Role_Admin/Reports/RptHistoryDisburse';
import RptHistoryWithdraw from './Role_Admin/Reports/RptHistoryWithdraw';
import RptPlanDisburse from './Role_Admin/Reports/RptPlanDisburse';
import RptPlanWithdraw from './Role_Admin/Reports/RptPlanWithdraw';
import RptPlansLoan from './Role_Admin/Reports/RptPlansLoan';
import LoanChecker from './Role_Admin/LoanChecker';

import Modifier from './Tabs/Modifier';
import InFreeAmContracts from './Contracts/InfreeAmharic/InFreeAmContracts';
import AuditorHelp from './Role_Auditor/AuditorHelp';
import FinishedLoans from './Role_Auditor/FinishedLoans';

import AuditorFinalComments from './Role_Auditor/AuditorFinalComments';
import AlpaUsers from './Selfservice/AlpaUsers';
import RegularUsers from './Selfservice/ReglarUsers';
import SalesInactiveTab from './Role_Sales/SalesInactiveTab';
import SalesActiveTab from './Role_Sales/SalesActiveTab';
import SalesMyLoans from './Role_Sales/SalesMyLoans';
import AlmedaUsers from './Selfservice/AlmedaUsers';
import LoanSummary from './Role_Admin/LoanSummary';

// self service
import AlpaUserDetail from './Selfservice/AlpaUserDetail';
import AdvancedSaving from './ProductDevelopment/AdvancedSaving';
import RegularUserDetail from './Selfservice/RegularUserDetail';
import CompulsarySaving from './ProductDevelopment/CompulsarySaving';
import ContractualSaving from './ProductDevelopment/ContractualSaving';

import SSReport from './Selfservice/SSReport';
import AuditLog from './Selfservice/AuditLog';
import ProductAndSavingLookups from './ProductDevelopment/ProductAndSavingLookups';
import Pgrade from './Role_Admin/Product/Pgrade';
import Psavingtype from './Role_Admin/Product/Psavingtype';
import Psourceofinfo from './Role_Admin/Product/Psourceofinfo';

import AddMember from './Role_Sales/AddMember';
import HelpTeamLeader from './Role_TeamLeader/HelpTeamLeader';

import OfficerHomeNew from './Role_Officer/OfficerHomeNew';

const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename={'/'}>
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route path='/login' element={<Login />} />
          <Route path='/patchip' element={<PatchIP />} />

          <Route path='/' element={<App />}>
            <Route
              path=''
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />

            {/* Officer  */}
            <Route path='/myloans' element={<MyLoans />} />
            <Route
              path='/loanonofficer/:customerId/:loanId'
              element={<LoanOnOfficer />}
            />
            <Route path='/newform/:customerId/:loanId' element={<NewForm />} />

            <Route path='/helpofficer' element={<HelpOfficer />} />
            <Route path='/myfinishedloans' element={<MyFinishedLoans />} />
            <Route path='/officerhomenew' element={<OfficerHomeNew />} />

            {/* Team Leader  */}
            <Route path='/helpteamleader' element={<HelpTeamLeader />} />
            <Route path='/finishedloans' element={<FinishedLoans />} />

            {/* Reports  */}
            <Route
              path='/infreeamcontracts/:customerId/:loanId'
              element={<InFreeAmContracts />}
            />

            <Route
              path='/amcontracts/:customerId/:loanId'
              element={<AmContracts />}
            />
            <Route
              path='/tgcontracts/:customerId/:loanId'
              element={<TgContracts />}
            />
            <Route
              path='/amprohibitions/:customerId/:loanId'
              element={<AmProhibitions />}
            />
            <Route
              path='/tgprohibitions/:customerId/:loanId'
              element={<TgProhibitions />}
            />

            {/* Loan evaluation  */}
            <Route
              path='/loanevaluation/:customerId/:loanId'
              element={<LoanEvaluation />}
            />
            <Route
              path='/loandecide/:customerId/:loanId'
              element={<LoanDecide />}
            />
            <Route
              path='/auditorfinalcomments/:customerId/:loanId'
              element={<AuditorFinalComments />}
            />

            {/* Common  */}
            <Route path='/profile' element={<Profile />} />
            <Route path='/changepass' element={<ChangePass />} />

            {/* Admin Lookup */}
            <Route
              path='/carmanufactureyear'
              element={<CarManufactureYear />}
            />
            <Route path='/carmodel' element={<CarModel />} />
            <Route path='/holidays' element={<Holidays />} />
            <Route path='/hometype' element={<HomeType />} />
            <Route path='/loantype' element={<LoanType />} />

            <Route path='/loancommittee' element={<LoanCommittee />} />
            <Route path='/jobposition' element={<JobPosition />} />
            <Route path='/employees' element={<Employees />} />
            <Route path='/loan' element={<Loan />} />
            <Route
              path='/loansummary/:customerId/:loanId'
              element={<LoanSummary />}
            />

            {/* Admin DDL */}

            <Route path='/regions' element={<Regions />} />
            <Route path='/zones' element={<Zones />} />
            <Route path='/woredas' element={<Woredas />} />

            <Route path='/banks' element={<Banks />} />
            <Route path='/cars' element={<Cars />} />
            <Route path='/delegation' element={<Delegation />} />
            <Route path='/companytype' element={<CompanyType />} />
            <Route path='/country' element={<Country />} />

            <Route path='/house' element={<House />} />
            <Route path='/membership' element={<Membership />} />
            <Route path='/salary' element={<Salary />} />
            <Route path='/share' element={<Share />} />

            <Route path='/single' element={<Single />} />
            <Route path='/singlegp' element={<Singlegp />} />
            <Route path='/singlesurety' element={<SingleSurety />} />
            <Route path='/married' element={<Married />} />
            <Route path='/marriedgp' element={<Marriedgp />} />
            <Route path='/marriedsurety' element={<MarriedSurety />} />

            <Route
              path='/loanfinancecategory'
              element={<LoanFinanceCategory />}
            />
            <Route path='/loanfinancedata' element={<LoanFinanceData />} />
            <Route path='/referenceinamigos' element={<ReferenceInAmigos />} />
            <Route path='/savingdecipline' element={<SavingDecipline />} />
            <Route path='/loanchecker' element={<LoanChecker />} />

            <Route
              path='/buildingmaterial'
              element={<Homebuildingmaterial />}
            />
            <Route path='/buildingtype' element={<Homebuildingtype />} />
            <Route path='/floorlocation' element={<Homefloorlocation />} />

            <Route path='/fromasphalt' element={<Homefromasphalt />} />
            <Route path='/location' element={<Homelocation />} />
            <Route path='/neighbour' element={<Homeneighbour />} />

            <Route
              path='/physicalappearance'
              element={<Homephysicalappearance />}
            />
            <Route path='/setback' element={<Homesetback />} />
            <Route path='/use' element={<Homeuse />} />

            {/* Reports Management */}
            <Route path='/rpthistoryloan' element={<RptHistoryLoan />} />
            <Route path='/rptplanloan' element={<RptPlansLoan />} />
            <Route
              path='/rpthistorywithdraw'
              element={<RptHistoryWithdraw />}
            />
            <Route path='/rptplanwithdraw' element={<RptPlanWithdraw />} />
            <Route
              path='/rpthistorydisburse'
              element={<RptHistoryDisburse />}
            />
            <Route path='/rptplandisburse' element={<RptPlanDisburse />} />

            {/* <Route path="/ratioscreditanalysis" element={<RatiosCreditAnalysis />} /> */}
            <Route path='/modifier' element={<Modifier />} />
            <Route path='/helpauditor' element={<AuditorHelp />} />
            <Route path='/afinishedloans' element={<FinishedLoans />} />

            {/* Registered Users  */}
            <Route path='/alpausers' element={<AlpaUsers />} />
            <Route path='/regularusers' element={<RegularUsers />} />
            <Route path='/almedausers' element={<AlmedaUsers />} />
            {/* Sales  */}
            <Route
              path='/salesactivetab/:customerId/:loanId'
              element={<SalesActiveTab />}
            />
            <Route
              path='/salesinactivetab/:customerId/:loanId'
              element={<SalesInactiveTab />}
            />
            <Route path='/salesmyloans' element={<SalesMyLoans />} />
            <Route path='/addmember' element={<AddMember />} />

            {/* Self Service  */}
            <Route
              path='/alpauserdetail/:userId'
              element={<AlpaUserDetail />}
            />
            <Route
              path='/regularuserdetail/:userId'
              element={<RegularUserDetail />}
            />

            <Route path='/advancedsaving' element={<AdvancedSaving />} />
            <Route path='/compulsarysaving' element={<CompulsarySaving />} />
            <Route path='/contractualsaving' element={<ContractualSaving />} />
            <Route path='/ssreport' element={<SSReport />} />
            <Route path='/auditlog' element={<AuditLog />} />

            <Route
              path='/productsavinglookups'
              element={<ProductAndSavingLookups />}
            />
            <Route path='/pgrade' element={<Pgrade />} />
            <Route path='/psavingtype' element={<Psavingtype />} />
            <Route path='/psourceofinfo' element={<Psourceofinfo />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
