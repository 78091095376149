import React from 'react';
import { Text, Font, View, StyleSheet } from '@react-pdf/renderer';
import AmharicFont from '../font/ebrima.ttf';
import { numberToWords } from '../Common/modules/EnNumToWord';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  container: {
    marginTop: -15,
    display: 'flex',
    flexDirection: 'col',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  dynamicText: {
    fontFamily: 'AmharicFont',
    fontSize: 9,
  },
});

Font.register({
  family: 'AmharicFont',
  src: AmharicFont,
});

function RptApprovedPrincipal({ loan }) {
  return (
    <View style={styles.container}>
      <Text>
        {(
          loan.approvedPrincipalDisbursed +
          loan.flatLifeInsurance +
          loan.flatServiceCharge
        )?.toLocaleString()}
      </Text>

      <Text style={styles.dynamicText}>
        ({' '}
        {numberToWords(
          loan.approvedPrincipalDisbursed +
            loan.flatLifeInsurance +
            loan.flatServiceCharge
        )}
        )
      </Text>
    </View>
  );
}

export default RptApprovedPrincipal;

RptApprovedPrincipal.propTypes = {
  loan: PropTypes.object.isRequired,
};
