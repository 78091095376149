import React from 'react';
import { Text, Font, View, StyleSheet } from '@react-pdf/renderer';
import AmharicFont from '../font/ebrima.ttf';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  container: {
    marginTop: -15,
    display: 'flex',
    flexDirection: 'col',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
});

Font.register({
  family: 'AmharicFont',
  src: AmharicFont,
});

function RptServiceCharge({ loan }) {
  return (
    <View style={styles.container}>
      <Text> {loan.prcntServiceCharge}</Text>
    </View>
  );
}

export default RptServiceCharge;

RptServiceCharge.propTypes = {
  loan: PropTypes.object.isRequired,
};
