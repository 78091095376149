import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeIP } from './redux/settingSlice';
import { Link } from 'react-router-dom';

import Lottie from 'lottie-react';
import Setting from './Lottie/Setting.json';

function PatchIP() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const dispatch = useDispatch();

  return (
    <div className='min-h-screen flex items-center justify-center bg-gray-100'>
      <form className='bg-white p-6 rounded-lg shadow-md w-full max-w-sm'>
        <div className='mb-4'>
          <Lottie animationData={Setting} />
        </div>
        <div className='mb-4'>
          <div> {serverIP} </div>
        </div>
        <div className='mb-6'>
          <button
            className='btn btn-outline-secondary btn-block w-full'
            onClick={() => {
              dispatch(
                changeIP({
                  serverIP: 'https://back.amigoslps.com',
                })
              );
            }}
          >
            https://back.amigoslps.com
          </button>
        </div>
        <div className='mb-4 flex items-center justify-center'>
          <button
            className='btn btn-outline-secondary w-full'
            onClick={() => {
              dispatch(
                changeIP({
                  serverIP: 'http://localhost:8000',
                })
              );
            }}
          >
            http://localhost:8000
          </button>
        </div>
        <div className=' flex items-center justify-center'>
          <Link
            to='/'
            className='font-medium text-blue-600 dark:text-blue-300 underline hover:underline text-xl'
          >
            Back to Home
          </Link>
        </div>
      </form>
    </div>
  );
}

export default PatchIP;
