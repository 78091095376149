import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

function TblAmCover(props) {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        fontSize: 9,
        fontFamily: 'AmharicFont',
        backgroundColor: props.bgcolor,
      }}
    >
      <View
        style={{
          borderWidth: 1,
          borderTopWidth: 0,
          borderRightWidth: 0,
          width: '60%',
          paddingLeft: 2,
          paddingBottom: 1,
        }}
      >
        <Text style={{ textAlign: props.textalign }}>{props.zkey}</Text>
      </View>
      <View
        style={{
          borderWidth: 1,
          borderTopWidth: 0,
          width: '40%',
          paddingLeft: 2,
          paddingBottom: 1,
        }}
      >
        <Text>{props.zvalue}</Text>
      </View>
    </View>
  );
}
TblAmCover.propTypes = {
  bgcolor: PropTypes.string,
  textalign: PropTypes.string,
  zkey: PropTypes.string,
  zvalue: PropTypes.string,
};

export default TblAmCover;
