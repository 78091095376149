import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

import SalesCustomer from './SalesCustomer';
import SalesRequestForm from './SalesRequestForm';

function SalesTabOrganizer() {
  const [t] = useTranslation('global');
  const { targetClient, targetLoan } = useSelector(
    (store) => store.systemLookups
  );
  const [key, setKey] = useState('SalesCustomer');

  const successnotify = (message) => {
    toast.success(message === '' ? 'ስራውን በተሳካ ሁኔታ ተከናዉኗል!' : message);
  };

  const errornotify = (message) => {
    toast.error(message === '' ? 'ስህተት አጋጥሟል!' : message);
  };

  return (
    <>
      <ToastContainer />
      <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
        <Tab
          eventKey='SalesCustomer'
          title={<span>{t('applicant.customerInfo')}</span>}
        >
          <SalesCustomer
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
            targetClient={targetClient}
          />
        </Tab>

        <Tab eventKey='SalesRequestForm' title='መጠየቅያ ፎርም'>
          <SalesRequestForm
            errornotify={errornotify}
            successnotify={successnotify}
            targetLoan={targetLoan}
            targetClient={targetClient}
          />
        </Tab>
      </Tabs>
      <div className='mt-20'></div>
    </>
  );
}

export default SalesTabOrganizer;
