import axiosInstance from '../../axiosInstance';
import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import AgreementStyles from '../Styles/ReportStyle';
import ReportStyle from '../Styles/ReportStyle';
import HeaderHistory from './HeaderHistory';
import { useSelector } from 'react-redux';
import Trc from '../../Contracts/Reusables/Trc';
import Tr from '../../Contracts/Reusables/Tr';

function RptPlanWithdraw() {
  const { serverIP } = useSelector((store) => store.allsettings);

  const [withdraws, setwithdraws] = useState([]);
  const [withdrawSum, setwithdrawSum] = useState(0);

  useEffect(() => {
    getwithdrawHistory();
  }, []);

  const getwithdrawHistory = () => {
    axiosInstance
      .get(`${serverIP}/withdrawrows/withdrawrow`)
      .then((res) => {
        console.log(res.data);
        setwithdraws(res.data);
        const sum_bisburses = res.data?.reduce((accum, obj) => {
          return accum + obj.withdraw_amount;
        }, 0);

        setwithdrawSum(sum_bisburses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getDateDifferenceInDays(plannedDate, appointmentDate) {
    // loan.plannedDate + " - " + loan.appointmentDate
    const Conv_plannedDate = new Date(plannedDate);
    const Conv_appointmentDate = new Date(appointmentDate);
    const differenceInTime = Conv_appointmentDate - Conv_plannedDate;
    const differenceInDays = Math.ceil(
      differenceInTime / (1000 * 60 * 60 * 24)
    );
    return differenceInDays;
  }

  return (
    <PDFViewer style={{ width: '100%', height: 1200 }}>
      <Document title='ያለቁ ብድሮች ሪፖርት'>
        <Page style={AgreementStyles.body} wrap>
          <HeaderHistory type='ያለቁ ብድሮች ሪፖርት' />
          <Trc borders='t0r0b0l0' thekey='ጠቅላላ የwithdraw plan ሪፖርት' />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: 'gray',
              marginTop: 5,
            }}
          >
            <Tr
              style={ReportStyle.h5b}
              thekey='ሙሉ ስም'
              width='22%'
              borders='t1r1b1l1'
            />
            <Tr
              style={ReportStyle.h5b}
              thekey='የብድር መጠን'
              width='19%'
              borders='t1r1b1l0'
            />
            <Tr
              style={ReportStyle.h5b}
              thekey='የተጠየቀበት ቀን  - የቀጠሮ ቀን'
              width='25%'
              borders='t1r1b1l0'
            />
            <Tr
              style={ReportStyle.h5b}
              thekey='የቀጠሮ ቀናት'
              width='12%'
              borders='t1r1b1l0'
            />
            <Tr
              style={ReportStyle.h5b}
              thekey=' ስልክ '
              width='22%'
              borders='t1r1b1l0'
            />
          </View>
          {withdraws.length > 0 &&
            withdraws.map((loan) => {
              return (
                <View
                  key={loan.id}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.parent?.amDisplayName}
                    width='22%'
                    borders='t0r1b1l1'
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.withdraw_amount?.toLocaleString()}
                    width='19%'
                    borders='t0r1b1l0'
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan.plannedDate + ' - ' + loan.appointmentDate}
                    width='25%'
                    borders='t0r1b1l0'
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={getDateDifferenceInDays(
                      loan.plannedDate,
                      loan.appointmentDate
                    )}
                    width='12%'
                    borders='t0r1b1l0'
                  />
                  <Tr
                    style={ReportStyle.h5b}
                    thekey={loan?.parent?.mobileNo}
                    width='22%'
                    borders='t0r1b1l0'
                  />
                </View>
              );
            })}

          <View style={{ paddingTop: 10, paddingLeft: 5 }}>
            <Text style={ReportStyle.h5b}>
              ጠቅላላ የብድር መጠን: {withdrawSum?.toLocaleString()}
            </Text>
            <Text style={ReportStyle.h5b}>የብድር ብዛት : {withdraws.length} </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default RptPlanWithdraw;
