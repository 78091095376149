import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet } from '@react-pdf/renderer';

function TextBold(props) {
  const Styles = StyleSheet.create({
    dynamicText: {
      fontFamily: 'ebrimabold',
      fontSize: 10,
      // color: props.color ? 'black' : 'green'
    },
  });

  return <Text style={Styles.dynamicText}>{props.text}</Text>;
}
TextBold.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextBold;
