import React, { useState } from 'react';
import './ChatBox.css'; // Import your CSS file for styling
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setTargetLoan } from './redux/systemLookups';
import { Button } from 'react-bootstrap';
import axiosInstance from '../axiosInstance';

const ChatBox = () => {
  const [t] = useTranslation('global');
  const { serverIP } = useSelector((store) => store.allsettings);
  const { data } = useSelector((store) => store.customer);
  const { targetLoan } = useSelector((store) => store.systemLookups);

  const [isOpen, setIsOpen] = useState(false);
  const [commentText, setCommentText] = useState('');
  const dispatch = useDispatch();

  const toggleChatBox = () => {
    setIsOpen(!isOpen);
  };

  const rejectWithComment = () => {
    axiosInstance
      .post(`${serverIP}/loan_comment/loancomments`, {
        comment: commentText,
        loan: targetLoan.id,
        commentedBy: data.id,
      })
      .then(() => {
        setCommentText('');
        getLoan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLoan = () => {
    axiosInstance
      .get(`${serverIP}/loan/loans/${targetLoan.id}`)
      .then((res) => {
        dispatch(
          setTargetLoan({
            targetLoan: res.data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={`chat-box-container ${isOpen ? 'open' : ''}`}>
      <div className='chat-box'>
        <div className='chat-header'>
          <button className='close-button' onClick={toggleChatBox}>
            {isOpen ? '-' : '+'}
          </button>
          <h6>{t('applicant.comment')}</h6>
        </div>
        <div
          className='chat-body'
          style={{ maxHeight: 350, overflowY: 'auto' }}
        >
          {isOpen && (
            <Form>
              {targetLoan.loancomment?.length > 0 &&
                targetLoan?.loancomment.map((cmnt) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#A9A9A9',
                        borderRadius: 8,
                        padding: 3,
                        marginBottom: 5,
                      }}
                      key={cmnt.id}
                    >
                      <div style={{ fontSize: '2vh' }}>{cmnt.comment}</div>
                      <div
                        style={{
                          color: 'white',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          paddingRight: 15,
                        }}
                      >
                        {/* {author.slice(0, 2)} */}
                        {/* {cmnt.commentedBy?.first_name.slice(0, 2)} */}
                        {cmnt.commentedBy?.first_name +
                          '.' +
                          cmnt.commentedBy?.last_name.slice(0, 2)}
                      </div>
                    </div>
                  );
                })}

              {data.groups[0] !== 'ROLE_OFFICER' ? (
                <>
                  <Form.Group className='mb-3' controlId='formGroupEmail'>
                    <Form.Control
                      as='textarea'
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                      placeholder={t('applicant.enterComment')}
                    />
                  </Form.Group>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      className='btn btn-sm btn-warning'
                      onClick={rejectWithComment}
                    >
                      save
                    </Button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
