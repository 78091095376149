import React from 'react';

function SSReport() {
  return (
    <div>
      <ul>
        <li> Registered </li>
        <li> Approved </li>
        <li> Requested Loans </li>
        <li> Approved Loans </li>
        <li> Payment Pending </li>
        <li> User Account Pending </li>
        <li> Visitor List </li>
      </ul>
    </div>
  );
}

export default SSReport;
