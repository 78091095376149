import axiosInstance from '../axiosInstance';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

function Loan() {
  const [t] = useTranslation('global');
  const { serverIP } = useSelector((store) => store.allsettings);
  const [allLoans, setAllLoans] = useState([]);
  const [targetObj, setTargetObj] = useState({});
  const [modalDelete, setModalDelete] = useState(false);
  const [filteredLoans, setFilteredLoans] = useState([]);

  const columns = [
    {
      name: 'ExternalID',
      selector: (row) => row.customer_entityExternalId,
      sortable: true,
    },
    {
      name: 'ሙሉ ስም',
      selector: (row) => row.customer_amDisplayName,
      sortable: true,
    },
    {
      name: 'የተፈቀደው የብድር መጠን',
      selector: (row) => row.r_approvedPrincipal?.toLocaleString(),
      sortable: true,
    },
    {
      name: 'ኦፊሰር',
      selector: (row) =>
        row?.submitedBy_name + '  ' + row?.submitedBy_fathername,
      sortable: true,
    },
    {
      name: 'ግምገማ',
      selector: (row) => (
        <Link
          to={`/loanevaluation/${row?.customer}/${row?.id}`}
          className='font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl'
        >
          ግምገማ
        </Link>
      ),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) =>
        row.auditorapproved ? (
          <>ያለቀ</>
        ) : (
          <button
            className='btn btn-danger btn-sm'
            onClick={() => {
              setTargetObj(row);
              setModalDelete(true);
            }}
          >
            አጥፋ
          </button>
        ),
    },
  ];

  useEffect(() => {
    getAllLoans();
  }, []);

  const getAllLoans = async () => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans`);
      console.log(res.data);
      setAllLoans(res.data);
      setFilteredLoans(res.data);
    } catch (err) {
      console.error('Error fetching loan:', err);
    }
  };

  const deleteLoan = () => {
    console.log('object deleted = ' + targetObj.id);
    axiosInstance
      .delete(`${serverIP}/loan/loans/${targetObj.id}`)
      .then((res) => {
        console.log(res.data);
        getAllLoans();
      })
      .catch((err) => {
        console.log(err);
      });
    setModalDelete(false);
  };

  return (
    <div>
      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: 'red' }} closeButton>
          <Modal.Title color='red'> {t('officer.delete')} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('admin.confirmDelete')} ?
          <div>
            <strong> {targetObj?.customer?.amDisplayName} </strong>
          </div>
          <div className='flex flex-col'>
            <strong>
              External ID : {targetObj?.customer_entityExternalId}
            </strong>
            <strong>
              የተበዳሪ ስም : {targetObj?.customer_amDisplayName}
            </strong>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => deleteLoan()}>
            {t('officer.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}

      <div className='flex justify-between p-1 bg-slate-300 rounded-md'>
        <input
          type='text'
          className='form-control'
          placeholder='search'
          onChange={(e) =>
            setFilteredLoans(
              allLoans.filter((item) =>
                item?.customer?.amDisplayName
                  .toLocaleLowerCase()
                  .includes(e.target.value.toLocaleLowerCase())
              )
            )
          }
        />
      </div>
      <div className='mt-2 p-1 bg-slate-200'>
        <DataTable columns={columns} data={filteredLoans} pagination>
          {' '}
        </DataTable>
      </div>
    </div>
  );
}

export default Loan;
