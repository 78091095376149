import React from 'react';
import { useSelector } from 'react-redux';
import ListGroup from 'react-bootstrap/ListGroup';
import { useTranslation } from 'react-i18next';

function Profile() {
  const { data } = useSelector((store) => store.customer);
  const [t] = useTranslation('global');

  return (
    <div>
      <div
        className='row'
        style={{
          height: '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className='col-sm-4'></div>
        <div className='col-sm-4'>
          <ListGroup as='ul'>
            <ListGroup.Item
              as='li'
              className='d-flex justify-content-between align-items-start'
            >
              <div className='ms-2 me-auto'>{t('auth.firstname')} </div>
              <div>{data?.first_name}</div>
            </ListGroup.Item>
            <ListGroup.Item
              as='li'
              className='d-flex justify-content-between align-items-start'
            >
              <div className='ms-2 me-auto'>{t('auth.middlename')}</div>
              <div>{data.last_name}</div>
            </ListGroup.Item>
            <ListGroup.Item
              as='li'
              className='d-flex justify-content-between align-items-start'
            >
              <div className='ms-2 me-auto'>{t('auth.email')}</div>
              <div>{data?.email}</div>
            </ListGroup.Item>
            <ListGroup.Item
              as='li'
              className='d-flex justify-content-between align-items-start'
            >
              <div className='ms-2 me-auto'>{t('auth.role')}</div>
              <div>{data?.groups[0]}</div>
            </ListGroup.Item>
          </ListGroup>
        </div>
        <div className='col-sm-4'></div>
      </div>
    </div>
  );
}

export default Profile;
