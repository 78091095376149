import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((store) => store.customer);

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  return (
    <React.Fragment>{isAuthenticated ? props.children : null}</React.Fragment>
  );
};
ProtectedRoute.propTypes = {
  children: PropTypes.node,
};

export default ProtectedRoute;
