import axiosInstance from '../../axiosInstance';
import React, { useState, useEffect } from 'react';
import { Accordion, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import InFreeAmAgreementDoc from './InFreeAmAgreementDoc';

import InFreeAmCarGuarantee from './InFreeAmCarGuarantee';
import InFreeAmHomeGuarantee from './InFreeAmHomeGuarantee';
import InFreeAmShareGuarantee from './InFreeAmShareGuarantee';
import InFreeAmSalaryGuarantee from './InFreeAmSalaryGuarantee';
import InFreeAmCover from './InFreeAmCover';
import { PulseLoader } from 'react-spinners';
// import AmLoanRequest from './AmLoanRequest';

// import LoanCover from './LoanCover';

function InFreeAmContracts() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { customerId, loanId } = useParams();
  const [customer, setCustomer] = useState([]);
  const [loan, setLoan] = useState([]);
  const [committee, setCommittee] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [collateralCar, setCollateralCar] = useState([]);
  const [collateralHome, setCollateralHome] = useState([]);
  const [collateralSallary, setCollateralSallary] = useState([]);
  const [collateralStock, setCollateralStock] = useState([]);

  useEffect(() => {
    getPrerequisites();
  }, []);

  if (isLoading) {
    return (
      <div className='h-fit flex items-center justify-center bg-gray-100'>
        <div className='flex flex-col gap-3 items-center justify-center'>
          <PulseLoader size={30} color='orange' loading='true' />
          <div style={{ color: '#FFA500' }}>Loading ...</div>
        </div>
      </div>
    );
  }

  const getPrerequisites = async () => {
    try {
      setIsLoading(true);
      const res_ln = await axiosInstance.get(
        `${serverIP}/loan/loans/${loanId}`
      );
      setLoan(res_ln.data);
      const res_cus = await axiosInstance.get(
        `${serverIP}/customer/customers/${customerId}`
      );
      setCustomer(res_cus.data);
      const res_com = await axiosInstance.get(
        `${serverIP}/loancommittee/active`
      );
      setCommittee(res_com.data);

      // collaterals by loan id
      const res_car = await axiosInstance.get(
        `${serverIP}/collateral_car/collateralcar_byloanid/${loanId}`
      );
      setCollateralCar(res_car.data);

      const res_home = await axiosInstance.get(
        `${serverIP}/collateral_home/collateralhomes_byloanid/${loanId}`
      );
      setCollateralHome(res_home.data);

      const res_salary = await axiosInstance.get(
        `${serverIP}/collateral_employee/employeesbyloanid/${loanId}`
      );
      setCollateralSallary(res_salary.data);

      const res_stock = await axiosInstance.get(
        `${serverIP}/collateral_stock/stockbyloanid/${loanId}`
      );
      setCollateralStock(res_stock.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className='row'>
        <div className='col-sm-12'>
          <Alert style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <b>{customer.amDisplayName}</b>
              {' - ' + customer.mobileNo}
            </div>
            <div>
              <Link
                to={`/tgcontracts/${customerId}/${loanId}`}
                className='font-medium text-orange-400 underline hover:underline text-xl'
              >
                ትግርኛ
              </Link>
              <Link
                to={`/tgcontracts/${customerId}/${loanId}`}
                className='font-medium text-orange-400 underline hover:underline text-xl ml-4'
              >
                Affan Oromo
              </Link>
            </div>
            <div className='flex gap-2'>
              <b>{customer.gender}</b>
              <b>{customer.isMarried ? ' - Married - ' : ' - Single - '}</b>
              <b> {customer.entityExternalId} </b>
            </div>
          </Alert>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12'>
          <Accordion defaultActiveKey='1'>
            {/* 
            <Accordion.Item eventKey="1">
              <Accordion.Header> ብድር መጠየቅያ ፎርም - 1 </Accordion.Header>
              <Accordion.Body>
                <AmLoanRequest customer={customer} loan={loan} />
              </Accordion.Body>
            </Accordion.Item> */}

            <Accordion.Item eventKey='2'>
              <Accordion.Header> ብድር መጠየቅያ ፎርም - 2 </Accordion.Header>
              <Accordion.Body>
                <InFreeAmCover
                  customer={customer}
                  loan={loan}
                  committee={committee}
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey='3'>
              <Accordion.Header> የብድር ውል ስምምነት </Accordion.Header>
              <Accordion.Body>
                <InFreeAmAgreementDoc customer={customer} loan={loan} />
              </Accordion.Body>
            </Accordion.Item>

            {collateralCar?.length > 0 &&
              collateralCar.map((car) => {
                return (
                  <>
                    <Accordion.Item eventKey='carg'>
                      <Accordion.Header>
                        <b style={{ color: 'green' }}> መኪና ዋስትና</b> &nbsp;
                        <i> {car.cargp?.fullname} </i>
                      </Accordion.Header>
                      <Accordion.Body>
                        <InFreeAmCarGuarantee loan={loan} car={car} />
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}

            {collateralHome?.length > 0 &&
              collateralHome.map((home) => {
                return (
                  <>
                    <Accordion.Item eventKey='homeg'>
                      <Accordion.Header>
                        የቤት - {home?.hometype?.amName}
                      </Accordion.Header>
                      <Accordion.Body>
                        <InFreeAmHomeGuarantee loan={loan} home={home} />
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}

            {collateralSallary?.length > 0 &&
              collateralSallary.map((salary) => {
                return (
                  <Accordion.Item key={salary.id} eventKey='employeeg'>
                    <Accordion.Header> ደመወዝ </Accordion.Header>
                    <Accordion.Body>
                      <InFreeAmSalaryGuarantee loan={loan} salary={salary} />
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}

            {collateralStock?.length > 0 &&
              collateralStock.map((stock) => {
                return (
                  <>
                    <Accordion.Item eventKey='stockg'>
                      <Accordion.Header> አክሲዮን </Accordion.Header>
                      <Accordion.Body>
                        <InFreeAmShareGuarantee loan={loan} stock={stock} />
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default InFreeAmContracts;
