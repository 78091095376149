import axiosInstance from '../axiosInstance';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  InputGroup,
  Modal,
  Form,
  Accordion,
  Table,
} from 'react-bootstrap';
import { FaEye, FaFileAlt } from 'react-icons/fa';

import { setTargetLoan } from '../Common/redux/systemLookups';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';

function Loan({ targetLoan, successnotify, errornotify }) {
  const { serverIP } = useSelector((store) => store.allsettings);
  const dispatch = useDispatch();

  const [multiplier, setMultiplier] = useState('');
  const [totalInterestPayment, settotalInterestPayment] = useState('');
  const [queueTime, setqueueTime] = useState(0);

  const [lastReturnDate, setlastReturnDate] = useState('');
  const [totalDueForPeriod, settotalDueForPeriod] = useState('');
  const [bankstateent, setbankstateent] = useState('');

  const [file, setFile] = useState(null);
  const [viewObj, setViewObj] = useState({});
  const [show, setShow] = useState(false);

  const [markValue, setmarkValue] = useState(0);
  const [thedatas, setthedatas] = useState([]);

  // character evaluation
  const [lstbusinessOwnership, setlstBusinessOwnership] = useState([]);
  const [lstsavingDecipline, setlstSavingDecipline] = useState([]);
  const [lstAmigosReference, setlstAmigosReference] = useState([]);
  const [ddlloangoal, setddlloangoal] = useState([]);

  const [previousBusinessTenure, setPreviousBusinessTenure] = useState(0);
  const [currentTenure, setCurrentTenure] = useState(0);
  const [loanPurpose, setloanPurpose] = useState(0);

  const [goodHistory, setGoodHistory] = useState('');

  const [businessOwnerships, setBusinessOwnerships] = useState('');
  const [savingDeciplines, setSavingDeciplines] = useState('');
  const [referenceInAmigoss, setReferenceInAmigoss] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMultiplier(targetLoan.multiplier);
    settotalInterestPayment(
      targetLoan.totalInterestPayment === 0
        ? 0
        : targetLoan.totalInterestPayment
    );
    setqueueTime(targetLoan.queueTime === 0 ? '' : targetLoan.queueTime);
    setlastReturnDate(targetLoan.expectedDisbursementDate);
    settotalDueForPeriod(targetLoan.totalDueForPeriod);
    setbankstateent(targetLoan.bankstateent);

    // character evaluation
    getLstbusinessOwnership();
    getLstsavingDecipline();
    getLstAmigosReference();
    getrequestloanpurpose();

    setPreviousBusinessTenure(targetLoan.previousBusinessTenureInMonths);
    setCurrentTenure(targetLoan.tenureInMonths);
    setGoodHistory(targetLoan.lastLoanGoodHistory);

    setBusinessOwnerships(targetLoan?.businessOwnership_id);
    setSavingDeciplines(targetLoan?.savingdecipline_id);
    setReferenceInAmigoss(targetLoan?.referenceinamigos_id);
  }, []);

  const handleDeleteFile = () => {};

  const getrequestloanpurpose = async () => {
    const goal_res = await axiosInstance.get(
      `${serverIP}/requestloanpurpose/requestloanpurpose`
    );
    setddlloangoal(goal_res.data);
  };

  const getLstbusinessOwnership = () => {
    axiosInstance
      .get(`${serverIP}/ddbusinesstype/ddlbusinesstype`)
      .then((res) => {
        setlstBusinessOwnership(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLstsavingDecipline = () => {
    axiosInstance
      .get(`${serverIP}/ddlsavingdecipline/savingdecipline`)
      .then((res) => {
        setlstSavingDecipline(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLstAmigosReference = () => {
    axiosInstance
      .get(`${serverIP}/ddlreferenceinamigos/referenceinamigos`)
      .then((res) => {
        setlstAmigosReference(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const categoryMap = new Map();
    targetLoan?.loan_fonance_items?.forEach((item) => {
      if (!categoryMap.has(item.category)) {
        categoryMap.set(item.category, []);
      }
      categoryMap.get(item.category).push(item);
    });

    const groupedData = Array.from(categoryMap, ([category, items]) => ({
      category,
      items,
    }));
    setthedatas(groupedData);
  }, [targetLoan]);

  const saveChanges = () => {
    axiosInstance
      .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
        multiplier,
        totalInterestPayment,
        queueTime,
        lastReturnDate,
        totalDueForPeriod,
        bankstateent,
        queueDate: addBusinessDays(queueTime),
        previousBusinessTenureInMonths: previousBusinessTenure,
        lastLoanGoodHistory: goodHistory,
        tenureInMonths: currentTenure,
        businessOwnership: businessOwnerships,
        savingdecipline: savingDeciplines,
        referenceinamigos: referenceInAmigoss,
        loanPurpose: loanPurpose,
      })
      .then(() => {
        successnotify('የብድር መረጃ ተስተካክሏል!');
        getLoan();
      })
      .catch((err) => {
        console.log(err);
        errornotify('የብድር መረጃ አልተስተካከለም');
      });
  };

  const getLoan = () => {
    axiosInstance
      .get(`${serverIP}/loan/loans/${targetLoan.id}`)
      .then((res) => {
        dispatch(
          setTargetLoan({
            targetLoan: res.data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function addBusinessDays(daysToAdd) {
    let currentDate = new Date();
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      currentDate.setDate(currentDate.getDate() + 1);
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        addedDays++;
      }
    }
    return currentDate.toISOString().split('T')[0];
  }

  function handleUpload(fileid) {
    console.log(file);
    if (!file) {
      errornotify('እባክዎ ፋይል ያያይዙ');
      return;
    }

    const fd = new FormData();
    fd.append('file', file);
    fd.append('fileType', file.type);
    fd.append('fileUrl', file);
    fd.append('isUploaded', true);

    axiosInstance
      .patch(`${serverIP}/loanfinanceitems/loanfinanceitemss/${fileid}`, fd, {
        headers: {
          'Custom-Header': 'value',
        },
      })
      .then(() => {
        successnotify('መረጃ ተሰቅሏል!!');
        getLoan();
      })
      .catch((err) => {
        errornotify('መረጃ በትክክል አልተሰቀለም!!');
        console.log(err);
      });
  }

  const saveAmountInBirr = (itemid, dataid) => {
    let initialInvestment = targetLoan?.loan_fonance_items[35].markValue;

    if (dataid == 36) {
      if (markValue >= 0) {
        toast.error('ዜሮ እና ከዜሮ በላይ አይፈቀድም');
      } else {
        validSave(itemid);
      }
    } else if (dataid == 37) {
      if (markValue <= 0) {
        return {
          isValid: false,
          message: 'Cash flow must be a positive number.',
        };
      }

      // Calculate IRR
      const irr = Math.pow(markValue / Math.abs(initialInvestment), 1) - 1;

      if (irr <= 0 || !isFinite(irr)) {
        // return { isValid: false, message: "Cash flow does not produce a valid percentage IRR." };
        toast.error('በተሰጠው Cash flow, IRR መቶኛ ማውጣት አይቻልም.');
      } else {
        validSave(itemid);
      }
    } else {
      validSave(itemid);
    }
  };

  const validSave = (itemid) => {
    axiosInstance
      .patch(`${serverIP}/loanfinanceitems/loanfinanceitemss/${itemid}`, {
        markValue: markValue,
      })
      .then(() => {
        successnotify('መረጃ ተስተካክሏል !!');
        getLoan();
      })
      .catch((err) => {
        errornotify('መረጃ አልተስተካከለም!!');
        console.log(err);
      });
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      // setTimeout(() => {
      //  console.log("one start app")
      // }, 30000);

      const fndatas = await axiosInstance.get(
        `${serverIP}/loanfinancedata/loanfinancedatas`
      );
      const transformedArray = fndatas?.data.map((item) => ({
        amDesc: item.amName,
        category: item.category,
        loan: targetLoan.id,
        dataid: item.id,
      }));

      await axiosInstance.post(
        `${serverIP}/loanfinanceitems/loanfinanceitemss/bulkinsert`,
        transformedArray
      );
    } catch (error) {
      console.log(error);
    } finally {
      getLoan();
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className='h-screen flex items-center justify-center bg-gray-100'>
        <div className='flex flex-col gap-3 items-center justify-center'>
          <PulseLoader size={30} color='orange' loading='true' />
          <div style={{ color: '#FFA500' }}>Loading ...</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* Modal Show Start  */}
      <Modal size='lg' show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={serverIP + viewObj.fileUrl}
            style={{ width: '100%', height: '100%' }}
          />
        </Modal.Body>
      </Modal>
      {/* Modal Show End  */}

      <div className='card'>
        <div className='card-body gap-2 w-full flex justify-evenly items-baseline'>
          {/* Select Input 1 */}
          <div className='flex flex-col'>
            <label htmlFor='select1' className='text-gray-700 mb-1'>
              Business Ownership
            </label>
            <select
              id='select1'
              className='border border-gray-300 rounded-md p-2'
              onChange={(e) => {
                setBusinessOwnerships(e.target.value);
              }}
            >
              <option value=''>ይምረጡ</option>

              {lstbusinessOwnership.map((ln) => {
                return (
                  <option
                    key={ln.id}
                    value={ln.id}
                    selected={ln.id === targetLoan?.businessOwnership_id}
                  >
                    {ln.amName}
                  </option>
                );
              })}
            </select>
          </div>
          {/* Select Input 2 */}
          <div className='flex flex-col'>
            <label htmlFor='select2' className='text-gray-700 mb-1'>
              Saving Decipline
            </label>
            <select
              id='select2'
              className='border border-gray-300 rounded-md p-2'
              onChange={(e) => setSavingDeciplines(e.target.value)}
            >
              <option value=''>ይምረጡ</option>
              {lstsavingDecipline.map((ln) => {
                return (
                  <option
                    key={ln.id}
                    value={ln.id}
                    selected={ln.id === targetLoan?.savingdecipline_id}
                  >
                    {ln.amName}
                  </option>
                );
              })}
            </select>
          </div>
          {/* Select Input 3 */}
          <div className='flex flex-col'>
            <label htmlFor='select3' className='text-gray-700 mb-1'>
              Reference In Amigos
            </label>
            <select
              id='select3'
              className='border border-gray-300 rounded-md p-2'
              onChange={(e) => setReferenceInAmigoss(e.target.value)}
            >
              <option value=''>ይምረጡ</option>

              {lstAmigosReference.map((ln) => {
                return (
                  <option
                    key={ln.id}
                    value={ln.id}
                    selected={ln.id === targetLoan?.referenceinamigos_id}
                  >
                    {ln.amName}
                  </option>
                );
              })}
            </select>
          </div>

          <div className='flex flex-col'>
            <label className='form-label'>የብድር አላማ </label>
            <select
              className='form-control'
              value={loanPurpose}
              onChange={(e) => setloanPurpose(e.target.value)}
            >
              <option value='0'> የብድር አላማ ይምረጡ </option>
              {ddlloangoal?.length > 0 &&
                ddlloangoal.map((goal) => {
                  return (
                    <option
                      selected={goal.id == 2}
                      key={goal.id}
                      value={goal.id}
                    >
                      {goal.amName}
                    </option>
                  );
                })}
            </select>
          </div>

          {/* Text Input */}
          <div className='flex flex-col'>
            <label htmlFor='textInput' className='text-gray-700 mb-1'>
              Previous Business Tenure
            </label>
            <input
              value={previousBusinessTenure}
              type='text'
              className='form-control'
              onChange={(e) => setPreviousBusinessTenure(e.target.value)}
            />
          </div>
          {/* Text Input */}
          <div className='flex flex-col'>
            <label htmlFor='textInput' className='text-gray-700 mb-1'>
              AA Residence in Months
            </label>
            <input
              value={currentTenure}
              type='text'
              className='form-control'
              onChange={(e) => setCurrentTenure(e.target.value)}
            />
          </div>
          {/* Checkbox Input */}
          <div className='flex flex-col '>
            <label className='text-gray-700 mb-1'>.</label>
            <div className='flex items-center'>
              <input
                type='checkbox'
                checked={goodHistory}
                onChange={(e) => {
                  setGoodHistory(e.target.checked);
                }}
                className='mr-2'
              />
              <label htmlFor='checkbox' className='text-gray-700'>
                Good History
              </label>
            </div>
          </div>
          <div className='mb-0'>
            <Button
              disabled={targetLoan?.loanstatus_id !== 1}
              className='btn-warning btn-sm mb-0'
              onClick={saveChanges}
            >
              አስተካክል
            </Button>
          </div>
        </div>
      </div>

      <div className='bg-gray-500 rounded-xl py-0 px-3 flex flex-row justify-between items-center text-white mt-1'>
        <div>{`Purpose: ${targetLoan.loanPurpose_txt}`}</div>
        <div className='flex justify-end p-1 '>
          {thedatas.length == 0 && (
            <Button
              className='btn-sm'
              disabled={isLoading}
              onClick={() => loadData()}
            >
              ሳብ
            </Button>
          )}
        </div>
      </div>

      <Accordion
        defaultActiveKey='newkey'
        className='border bg-blue-200 border-blue-500 rounded-md'
      >
        {thedatas.length > 0 &&
          thedatas.map((thedata) => {
            return (
              <Accordion.Item
                // eventKey={thedata.category}
                eventKey='newkey'
                key='newkey'
                // key={thedata.category}
                className='bg-blue-200'
              >
                <Accordion.Header>{thedata.category}</Accordion.Header>
                <Accordion.Body className='p-1'>
                  <Table striped bordered hover style={{ margin: 1 }}>
                    <thead>
                      <tr>
                        <th> የፋይል ስም </th>
                        <th> እይ </th>
                        <th> አስፈላጊነት </th>
                        <th> የብር መጠን </th>
                      </tr>
                    </thead>
                    <tbody>
                      {thedata?.items?.length > 0 &&
                        thedata?.items?.map((item) => (
                          <tr key={item.id}>
                            <td>{`${item.amDesc}`}</td>
                            <td>
                              {item.isUploaded ? (
                                <>
                                  {item.fileType === 'application/pdf' ? (
                                    <a
                                      target='_blank'
                                      href={serverIP + item.fileUrl}
                                      rel='noopener noreferrer'
                                    >
                                      <FaFileAlt />
                                    </a>
                                  ) : (
                                    <FaEye
                                      onClick={() => {
                                        setViewObj(item);
                                        setShow(true);
                                        console.log('set show clicked');
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td style={{ padding: 0 }}>
                              <div className='flex gap-2 p-1'>
                                <InputGroup>
                                  <input
                                    onChange={(e) => {
                                      setFile(e.target.files[0]);
                                      console.log(e.target.files[0]);
                                    }}
                                    type='file'
                                    className='form-control'
                                  />
                                  <Button
                                    disabled={targetLoan?.loanstatus_id !== 1}
                                    variant='outline-primary'
                                    onClick={() => handleUpload(item.id)}
                                  >
                                    {!item.isUploaded ? <> ጫን </> : <>አዘምን</>}
                                  </Button>
                                </InputGroup>
                                {item.isUploaded && (
                                  <Button
                                    disabled={targetLoan?.loanstatus_id !== 1}
                                    variant='outline-danger btn-sm'
                                    onClick={() => handleDeleteFile(item.id)}
                                  >
                                    አጥፋ
                                  </Button>
                                )}
                              </div>
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputGroup className='p-1'>
                                <InputGroup.Text>
                                  {item?.markValue?.toLocaleString()}
                                </InputGroup.Text>
                                <Form.Control
                                  type='number'
                                  onChange={(e) => setmarkValue(e.target.value)}
                                />
                                <Button
                                  disabled={targetLoan?.loanstatus_id !== 1}
                                  variant='outline-secondary'
                                  onClick={() =>
                                    saveAmountInBirr(item.id, item.dataid)
                                  }
                                >
                                  {' '}
                                  Update{' '}
                                </Button>
                              </InputGroup>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
      </Accordion>
    </div>
  );
}

Loan.propTypes = {
  successnotify: PropTypes.func.isRequired,
  errornotify: PropTypes.func.isRequired,
  targetLoan: PropTypes.func,
};

export default Loan;
